import React from 'react'

import PropTypes from 'prop-types'

import './card-branding.css'

const CardBranding = (props) => {
  return (
    <div className={`card-branding-inverted ${props.rootClassName} `}>
      <div className="card-branding-container2">
        <div className="card-branding-download">
          <div className="card-branding-download-button">
            <img
              alt="trayarrowdown7381"
              src="/external/trayarrowdown7381-pihc.svg"
              className="card-branding-trayarrowdown"
            />
          </div>
        </div>
        <img alt="image" src={props.imageSrc} className="card-branding-image" />
        <div className="card-branding-texts">
          <span className="card-branding-text1">PNG, SVG</span>
          <span className="card-branding-text2">{props.description}</span>
        </div>
      </div>
    </div>
  )
}

CardBranding.defaultProps = {
  rootClassName: '',
  description: 'COLOURFUL LOGO',
  imageSrc: '/external/gw-logo-200h.png',
}

CardBranding.propTypes = {
  rootClassName: PropTypes.string,
  description: PropTypes.string,
  imageSrc: PropTypes.string,
}

export default CardBranding
