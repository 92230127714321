import React from 'react'

import PropTypes from 'prop-types'

import './button-small-main.css'

const ButtonSmallMain = (props) => {
  return (
    <div
      id="button-small-main"
      className={`button-small-main-container1 ${props.rootClassName} `}
    >
      <div
        id={props.divID}
        className="button-small-main-container2 interactive-element"
      >
        <span className="button-small-main-text">{props.label}</span>
      </div>
    </div>
  )
}

ButtonSmallMain.defaultProps = {
  divID: '',
  rootClassName: '',
  label: 'Small main button',
}

ButtonSmallMain.propTypes = {
  divID: PropTypes.string,
  rootClassName: PropTypes.string,
  label: PropTypes.string,
}

export default ButtonSmallMain
