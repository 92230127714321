import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import Tag from '../components/tag'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ImageProductHero from '../components/image-product-hero'
import ContentTitleProduct from '../components/content-title-product'
import CardProductHighlight from '../components/card-product-highlight'
import ContentParagraph from '../components/content-paragraph'
import ContentSmallTitle from '../components/content-small-title'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import AnnouncementSmall from '../components/announcement-small'
import Testimonial from '../components/testimonial'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './web3-infrastructure-blueprint-digital-twins.css'

const Web3InfrastructureBlueprintDigitalTwins = (props) => {
  return (
    <div className="web3-infrastructure-blueprint-digital-twins-container1">
      <Helmet>
        <title>Gateway.fm | Web3 Infrastructure Blueprint: Digital Twins</title>
        <meta
          name="description"
          content="Explore Gateway.fm's blueprint for Web3 digital twins, utilizing blockchain for enhanced data integrity and interoperability in virtual representations of physical assets."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Web3 Infrastructure Blueprint: Digital Twins"
        />
        <meta
          property="og:description"
          content="Explore Gateway.fm's blueprint for Web3 digital twins, utilizing blockchain for enhanced data integrity and interoperability in virtual representations of physical assets."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/4efad827-b677-427d-a538-67da92849ba5?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name21"></Navigation>
      <div className="web3-infrastructure-blueprint-digital-twins-content">
        <div className="web3-infrastructure-blueprint-digital-twins-hero">
          <div className="web3-infrastructure-blueprint-digital-twins-description">
            <TitleProductHero text="DIGITAL TWINS"></TitleProductHero>
            <Tag rootClassName="tagroot-class-name8"></Tag>
            <SubtitleProductHero text="Create virtual replicas of physical assets, processes, or systems"></SubtitleProductHero>
            <USPCheckmark text="Enhance operational efficiency"></USPCheckmark>
            <USPCheckmark text="Enable predictive maintenance"></USPCheckmark>
            <USPCheckmark text="No code. Zero knowledge"></USPCheckmark>
            <Link
              to="/contact-form"
              className="web3-infrastructure-blueprint-digital-twins-navlink1"
            >
              <ButtonPrimarySubtitle className="web3-infrastructure-blueprint-digital-twins-component17"></ButtonPrimarySubtitle>
            </Link>
          </div>
          <ImageProductHero image="/external/blueprints/digitaltwin-500h.png"></ImageProductHero>
        </div>
        <div className="web3-infrastructure-blueprint-digital-twins-container2">
          <ContentTitleProduct></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-digital-twins-cards">
            <CardProductHighlight
              main="Addressing Digital Twin Challenges"
              supportive="create a decentralized and immutable record of all Digital Twin data and interactions"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Ensuring Data Integrity and Provenance"
              supportive="Stakeholders can trace the entire lifecycle of a Digital Twin, from its creation to its current state, accessing detailed information"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Enabling Interoperability and Collaboration"
              supportive="We enable seamless integration and data exchange between Digital Twins and other services"
            ></CardProductHighlight>
          </div>
          <ContentTitleProduct text="Summary"></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-digital-twins-container3">
            <span className="web3-infrastructure-blueprint-digital-twins-text">
              Our Web3 Digital Twins solution leverages blockchain technology to
              establish a secure, decentralized, and transparent ecosystem for
              creating and managing virtual representations of real-world
              entities. By combining smart contracts, cryptographic proofs, and
              distributed ledger technology, we enable the creation of
              tamper-proof, auditable, and interoperable Digital Twins that
              accurately mirror the state and behavior of their physical
              counterparts.
            </span>
          </div>
          <ContentTitleProduct text="Addressing Digital Twin Challenges"></ContentTitleProduct>
          <ContentParagraph text="Traditional Digital Twin implementations often suffer from centralized control, lack of transparency, and limited interoperability. Our Web3 solution addresses these challenges by harnessing the power of blockchain to create a decentralized and immutable record of all Digital Twin data and interactions. This eliminates the risks associated with single points of failure, data manipulation, and vendor lock-in, fostering trust and collaboration among stakeholders."></ContentParagraph>
          <ContentTitleProduct text="Ensuring Data Integrity and Provenance"></ContentTitleProduct>
          <ContentParagraph text="Data integrity and provenance are critical for Digital Twins to accurately represent real-world entities. Our Web3 solution ensures the immutability and traceability of Digital Twin data by recording all changes, updates, and interactions on a decentralized blockchain ledger. Stakeholders can trace the entire lifecycle of a Digital Twin, from its creation to its current state, accessing detailed information such as data sources, modifications, and audit trails. This level of transparency ensures the credibility and reliability of Digital Twins."></ContentParagraph>
          <ContentTitleProduct text="Enabling Interoperability and Collaboration"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 Digital Twins solution is designed to facilitate interoperability and collaboration among diverse stakeholders and systems. By leveraging open standards and decentralized architecture, we enable seamless integration and data exchange between Digital Twins, IoT devices, enterprise systems, and other third-party services. This interoperability fosters a collaborative ecosystem where stakeholders can share and access Digital Twin data, enabling cross-domain insights and optimizing decision-making processes."></ContentParagraph>
          <ContentTitleProduct text="Enabling Collaboration and Auditability"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 accounting ledger solution is designed to facilitate collaboration and auditability among stakeholders. By leveraging a shared, decentralized ledger, all authorized parties can access and verify the same set of financial data, eliminating the need for data reconciliation and ensuring consistent reporting. Additionally, the immutable nature of the blockchain enables efficient and transparent auditing processes, as auditors can independently verify the integrity of financial records without relying on intermediaries."></ContentParagraph>
          <ContentTitleProduct text="Scalable and Secure"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 solution is built with scalability and security in mind, capable of handling large volumes of Digital Twin data and interactions. By leveraging advanced blockchain technologies, such as sharding and layer-2 scaling solutions, we ensure efficient and cost-effective processing of Digital Twin transactions. Additionally, our solution incorporates robust identity management and access control mechanisms, ensuring that sensitive Digital Twin data is protected and accessible only to authorized parties."></ContentParagraph>
          <ContentTitleProduct text="Components"></ContentTitleProduct>
          <ContentSmallTitle
            text="Blockchain Network"
            rootClassName="content-small-titleroot-class-name56"
          ></ContentSmallTitle>
          <ContentParagraph text="A secure, decentralized ledger for recording Digital Twin data and interactions, leveraging advanced consensus mechanisms and cryptographic techniques."></ContentParagraph>
          <ContentSmallTitle
            text="Smart Contracts"
            rootClassName="content-small-titleroot-class-name57"
          ></ContentSmallTitle>
          <ContentParagraph text="Self-executing contracts that govern the creation, modification, and management of Digital Twins, ensuring accurate representation of real-world entities and enforcing predefined rules."></ContentParagraph>
          <ContentSmallTitle
            text="Oracles"
            rootClassName="content-small-titleroot-class-name58"
          ></ContentSmallTitle>
          <ContentParagraph text="Secure gateways that facilitate the integration of off-chain data from IoT devices, sensors, and other data sources, enabling the continuous synchronization of Digital Twins with their physical counterparts."></ContentParagraph>
          <ContentSmallTitle
            text="Digital Twin Tokens"
            rootClassName="content-small-titleroot-class-name59"
          ></ContentSmallTitle>
          <ContentParagraph text="Unique digital representations of real-world entities, issued and tracked on the blockchain, enabling secure ownership, provenance, and access control for Digital Twins."></ContentParagraph>
          <ContentSmallTitle
            text="Identity Management"
            rootClassName="content-small-titleroot-class-name60"
          ></ContentSmallTitle>
          <ContentParagraph text="A robust identity management system for securely onboarding and managing stakeholder identities, ensuring proper access controls and compliance with regulatory requirements."></ContentParagraph>
          <ContentSmallTitle
            text="Analytics and Reporting"
            rootClassName="content-small-titleroot-class-name61"
          ></ContentSmallTitle>
          <ContentParagraph text="Powerful analytics and visualization tools that leverage the immutable data stored on the blockchain, providing stakeholders with valuable insights, simulations, and decision-making support."></ContentParagraph>
          <ContentSmallTitle
            text="Integration Layer"
            rootClassName="content-small-titleroot-class-name62"
          ></ContentSmallTitle>
          <ContentParagraph text="A flexible integration layer that enables seamless connectivity with existing enterprise systems, IoT platforms, and other third-party services, ensuring interoperability and data exchange across diverse platforms."></ContentParagraph>
          <ContentParagraph text="By harnessing the power of Web3 technologies, our Digital Twins solution delivers unparalleled data integrity, transparency, interoperability, and scalability, enabling organizations to create and manage accurate and trustworthy virtual representations of real-world entities, fostering collaboration and driving data-driven decision-making."></ContentParagraph>
        </div>
        <div className="web3-infrastructure-blueprint-digital-twins-container4">
          <SubtitleProductPromo></SubtitleProductPromo>
          <AnnouncementSmall
            text="We enabled Wirex to elevate its zk-powered network"
            rootClassName="announcement-smallroot-class-name10"
            className="web3-infrastructure-blueprint-digital-twins-component51"
          ></AnnouncementSmall>
          <div className="web3-infrastructure-blueprint-digital-twins-testimonials">
            <Testimonial
              name="Wirex"
              text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
              position="@wirexapp"
              rootClassName="testimonialroot-class-name54"
              logopolygonSrc="/external/vector1317-f6xm.svg"
            ></Testimonial>
            <Testimonial
              name="GPT Protocol"
              text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
              position="@gpt_protocol"
              rootClassName="testimonialroot-class-name55"
              logopolygonSrc="/external/logos/logo-gpt-200w.png"
            ></Testimonial>
            <Testimonial
              name="Dora"
              text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
              position="@SearchOnDora"
              rootClassName="testimonialroot-class-name56"
              logopolygonSrc="/external/logos/logo-dora1-200h.png"
            ></Testimonial>
          </div>
        </div>
        <Link
          to="/contact-form"
          className="web3-infrastructure-blueprint-digital-twins-navlink2"
        >
          <Floating
            label="Digital Twins"
            buttonLabel="Get started"
            description="Create virtual replicas of physical assets, processes, or systems"
            buttonSubtext="Initiate this blueprint"
            rootClassName="floatingroot-class-name7"
            className="web3-infrastructure-blueprint-digital-twins-component55"
          ></Floating>
        </Link>
      </div>
      <Footer rootClassName="footerroot-class-name20"></Footer>
    </div>
  )
}

export default Web3InfrastructureBlueprintDigitalTwins
