import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import ContentTitleProduct from '../components/content-title-product'
import CardVideoBackground from '../components/card-video-background'
import CardScheme from '../components/card-scheme'
import Footer from '../components/footer'
import './oracle.css'

const Oracle = (props) => {
  return (
    <div className="oracle-container1">
      <Helmet>
        <title>Gateway.fm | Oracle Solutions for Blockchain Applications</title>
        <meta
          name="description"
          content="Explore Gateway.fm's Oracle system, delivering real-time, accurate data for blockchain applications. Benefit from speed, flexibility, and seamless integration for enhanced blockchain functionality."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Oracle Solutions for Blockchain Applications"
        />
        <meta
          property="og:description"
          content="Explore Gateway.fm's Oracle system, delivering real-time, accurate data for blockchain applications. Benefit from speed, flexibility, and seamless integration for enhanced blockchain functionality."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/85b10178-c45c-4ed1-847e-662583d03446?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name43"></Navigation>
      <div className="oracle-hero">
        <div className="oracle-frame1171275211">
          <span className="oracle-text1">
            <span className="oracle-text2">GATEWAY</span>
            <br></br>
            <span>ORACLE</span>
            <br></br>
          </span>
          <img
            alt="cubebitmin17156"
            src="/external/services/oracle-peak-min-1500w.png"
            className="oracle-cubebitmin1"
          />
          <div className="oracle-frame482057">
            <span className="oracle-text6">
              Our Oracle system delivers real-time, accurate data for blockchain
              applications with speed, flexibility, and ease of integration
            </span>
          </div>
        </div>
      </div>
      <div className="oracle-container2">
        <ContentTitleProduct text="Key features"></ContentTitleProduct>
        <div className="oracle-container3">
          <CardVideoBackground
            label="High-speed data collection"
            poster="/external/services/poster-speed-1500w.png"
            videoSrc="/external/services/back-1.mp4"
          ></CardVideoBackground>
          <CardVideoBackground
            text="Primarily calibrated for price data feeds but is adaptable for various data types, making it suitable for diverse applications"
            label="Versatile data handling"
            video="/external/services/curves-1.mp4"
            poster="/external/services/poster-curves-1500w.png"
            videoSrc="/external/services/curves-1.mp4"
            videoPoster="/external/services/poster-curves-1500w.png"
          ></CardVideoBackground>
        </div>
        <div className="oracle-container4">
          <CardVideoBackground
            text="Subscribes to the daemon’s data feeds and efficiently stores the required data in smart contracts for data accuracy and timeliness"
            label="Backend Oracle Service"
            video="/external/services/stripes-1.mp4"
            poster="/external/services/poster-stripes-1500w.png"
            videoSrc="/external/services/stripes-1.mp4"
            videoPoster="/external/services/poster-stripes-1500w.png"
          ></CardVideoBackground>
          <CardVideoBackground
            text="Features smart contracts with an easy-to-integrate ABI for seamless incorporation into various blockchain applications"
            label="Smart Contracts, Easy Integration"
            video="/external/services/waves-1.mp4"
            poster="/external/services/poster-waves-1500w.png"
            videoSrc="/external/services/waves-1.mp4"
            videoPoster="/external/services/poster-waves-1500w.png"
          ></CardVideoBackground>
        </div>
        <ContentTitleProduct text="How it works"></ContentTitleProduct>
        <div className="oracle-scheme">
          <CardScheme></CardScheme>
          <div className="oracle-frame11712752841">
            <img
              alt="Ellipse58627"
              src="/external/ellipse58627-bema-200w.png"
              className="oracle-ellipse51"
            />
            <img
              alt="Ellipse68627"
              src="/external/ellipse68627-fkfr-200w.png"
              className="oracle-ellipse61"
            />
          </div>
          <CardScheme
            tag1="Go"
            tag2="Large data volumes"
            text="Written in Go, known for its efficiency and scalability, making it ideal for handling large-scale data processing. Subscribes to data feeds from the daemon and stores necessary information in smart contracts, ensuring data accuracy and accessibility. Designed to efficiently manage large volumes of data, providing dependable data feeds essential for blockchain operations."
            label="Oracle service"
          ></CardScheme>
          <div className="oracle-frame11712752842">
            <img
              alt="Ellipse58627"
              src="/external/ellipse58627-bema-200w.png"
              className="oracle-ellipse52"
            />
            <img
              alt="Ellipse68627"
              src="/external/ellipse68627-fkfr-200w.png"
              className="oracle-ellipse62"
            />
          </div>
          <CardScheme
            tag1="Solidity"
            tag2="Hardhat"
            text="Developed using Solidity and leverages Hardhat for deployment and testing, ensuring robust and efficient smart contract creation. Equipped with an easy-to-integrate Application Binary Interface (ABI), facilitating seamless integration with various blockchain platforms. Specifically tailored to utilize data from the Oracle service, enabling smart contracts to respond effectively to real-world data inputs."
            label="Smart contracts"
          ></CardScheme>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name42"></Footer>
    </div>
  )
}

export default Oracle
