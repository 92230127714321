import React from 'react'

import PropTypes from 'prop-types'

import './input-field.css'

const InputField = (props) => {
  return (
    <div className="input-field-container">
      <span className="input-field-text">{props.label}</span>
      <input
        type={props.type}
        id={props.id}
        name={props.nameValue}
        placeholder={props.placeholder}
        className="input-field-textinput input"
      />
    </div>
  )
}

InputField.defaultProps = {
  type: 'type',
  label: 'Your name',
  id: '',
  nameValue: '',
  placeholder: 'placeholder',
}

InputField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  nameValue: PropTypes.string,
  placeholder: PropTypes.string,
}

export default InputField
