import React from 'react'

import PropTypes from 'prop-types'

import './subtext-product-promo.css'

const SubtextProductPromo = (props) => {
  return (
    <div className="subtext-product-promo-container">
      <span className="subtext-product-promo-text">{props.text}</span>
    </div>
  )
}

SubtextProductPromo.defaultProps = {
  text: 'Looking for something more complete? Choose one of a dozen pre-compiled blueprints',
}

SubtextProductPromo.propTypes = {
  text: PropTypes.string,
}

export default SubtextProductPromo
