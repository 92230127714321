import React from 'react'

import PropTypes from 'prop-types'

import './form-back.css'

const FormBack = (props) => {
  return (
    <div
      id="form-back"
      className={`form-back-container ${props.rootClassName} `}
    >
      <img
        alt={props.arrowrightAlt}
        src={props.arrowrightSrc}
        className="form-back-arrowright"
      />
    </div>
  )
}

FormBack.defaultProps = {
  arrowrightSrc: '/external/arrowright7643-l7sb.svg',
  arrowrightAlt: 'arrowright7643',
  rootClassName: '',
}

FormBack.propTypes = {
  arrowrightSrc: PropTypes.string,
  arrowrightAlt: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default FormBack
