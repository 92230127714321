import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import './blog-what-is-raa-s-rollup-as-a-service.css'

const BlogWhatIsRaaSRollupAsAService = (props) => {
  return (
    <div className="blog-what-is-raa-s-rollup-as-a-service-container1">
      <Helmet>
        <title>Gateway.fm | What is RaaS? Rollup-as-a-Service</title>
        <meta
          name="description"
          content="Rollup-as-a-Service (RaaS) offers a streamlined approach to deploying scalable solutions that address the limitations of Layer 1 blockchains like Ethereum."
        />
        <meta
          property="og:title"
          content="Gateway.fm | What is RaaS? Rollup-as-a-Service"
        />
        <meta
          property="og:description"
          content="Rollup-as-a-Service (RaaS) offers a streamlined approach to deploying scalable solutions that address the limitations of Layer 1 blockchains like Ethereum."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/58d773eb-69b8-452d-a51b-fa087db58b3b?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name41"></Navigation>
      <div className="blog-what-is-raa-s-rollup-as-a-service-container2">
        <div className="blog-what-is-raa-s-rollup-as-a-service-post">
          <img
            alt="Wat is RaaS, Rollup as a Service."
            src="/external/blog-banners/what-is-raas-rollup-as-a-service-1200w.jpg"
            className="blog-what-is-raa-s-rollup-as-a-service-gatewaywirexcasestudy1"
          />
          <div className="blog-what-is-raa-s-rollup-as-a-service-text100">
            <div className="blog-what-is-raa-s-rollup-as-a-service-container3">
              <div className="blog-what-is-raa-s-rollup-as-a-service-tags"></div>
              <span className="blog-what-is-raa-s-rollup-as-a-service-text101">
                What is RaaS? Rollup-as-a-Service
              </span>
              <span className="blog-what-is-raa-s-rollup-as-a-service-text102">
                19 of July 2024
              </span>
              <span className="blog-what-is-raa-s-rollup-as-a-service-text103">
                <span className="text">
                  Rollup-as-a-Service (RaaS) offers a streamlined approach to
                  deploying scalable solutions that address the limitations of
                  L1 (Layer 1) blockchains like Ethereum. Ethereum, one of the
                  most popular and widely used blockchain platforms, faces
                  scalability and transaction cost challenges. To address these
                  issues, the blockchain community has developed several L2
                  (Layer 2) scaling solutions designed to enhance the throughput
                  and efficiency of blockchain networks without compromising
                  security and decentralization.
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  Among solutions like state channels, side chains, plasma, and
                  validium, rollups have emerged as a key technology, enabling
                  the processing of transactions off the main blockchain (L1).
                  Building on the concept of rollups, Rollup-as-a-Service (RaaS)
                  takes this technology a step further by offering a
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="text">platform</span>
                <span className="text">
                  {' '}
                  that simplifies the deployment and management of rollup
                  solutions, providing developers and enterprises with the
                  necessary tools and infrastructure to leverage the benefits of
                  rollups without the complexities of setting up and maintaining
                  them.
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  Let’s dive deeper into exploring the mechanics of rollups,
                  understanding the benefits Rollups-as-a-service (Raas)
                  platforms offer, and how to get started creating your rollup
                  with
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="text">Presto</span>
                <span className="text">.</span>
                <br className="text"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text116"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text117"></br>
                <span className="h2">What are Blockchain Rollups?</span>
                <br className="Body"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text120"></br>
                <span className="text">
                  To understand Rollup-as-a-Service (Raas), it&apos;s essential
                  to grasp the mechanics of rollups. Rollups solve the
                  scalability issues plaguing many blockchain networks. They do
                  this by processing transactions off the main chain (L1) and
                  then bundling or rolling up these transactions into a single
                  batch. This batch is then committed to the main chain,
                  reducing the amount of data processed and stored on the L1
                  blockchain. Here’s a step-by-step breakdown of how rollups
                  work:
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">1. Batching Transactions</span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  Rollups bundle or roll up multiple transactions into a single
                  batch. Instead of processing each transaction individually on
                  the L1 chain, they are aggregated into a single transaction,
                  significantly reducing the amount of data that needs to be
                  stored on-chain.
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">2. Off-Chain Processing</span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  The actual computation and transaction processing occurs on
                  L2, where rollups handle the execution of smart contracts,
                  transaction verification, and other operations on a separate
                  layer. This offloads a significant amount of work from the
                  main chain.
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">3. State Root Commitment</span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  After processing, rollups submit a state root or a
                  cryptographic hash that represents the new state of the chain
                  after all the batched transactions have been executed. This
                  state root is submitted to the L1 blockchain, allowing it to
                  be securely referenced and verified.
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">4. Security and Data Availability</span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  Despite processing transactions off-chain, rollups ensure
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://gateway.fm/blog/choosing-a-da-solution-third-party-local-or-dacs"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blog-what-is-raa-s-rollup-as-a-service-link1"
                >
                  data availability
                </a>
                <span className="text">
                  {' '}
                  and security by posting transaction data or proofs back to the
                  L1 chain. There are two main types of rollups based on how
                  they handle this:
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  - Optimistic Rollups: They assume transactions are valid and
                  only run a fraud-proof if someone challenges the validity of a
                  transaction. This approach is called &quot;optimistic&quot;
                  because it optimistically assumes that transactions are
                  correct.
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  - ZK-Rollups (Zero-Knowledge Rollups): They generate a
                  cryptographic proof, known as a zero-knowledge proof, for
                  every batch of transactions. This proof is then verified by
                  the L1 blockchain, ensuring that all transactions are valid.
                  This method is more computationally intensive but provides
                  instant finality and stronger security guarantees.
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  5. Fraud Proofs and Validity Proofs
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  - Fraud Proofs (Optimistic Rollups): If someone detects an
                  invalid transaction in an optimistic rollup, they can submit a
                  fraud proof. The L1 chain then verifies the proof, and if it
                  finds the transaction to be indeed fraudulent, it rolls back
                  the invalid transaction.
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  - Validity Proofs (ZK-Rollups): These are generated for every
                  batch and ensure that all transactions in the batch are valid.
                  The L1 chain only needs to verify the proof, not each
                  individual transaction, which is computationally efficient and
                  secure.
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">6. Finality on the main chain</span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  Once the state root or proof is verified on the Layer 1 chain,
                  the batched transactions are considered final. This action
                  finalizes the state transitions and updates resulting from the
                  processed transactions, effectively incorporating them into
                  the immutable blockchain ledger.
                </span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text174"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text175"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text176"></br>
                <span className="h2">
                  Understanding Rollup-as-a-Service (RaaS) and the Benefits
                </span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text178"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text179"></br>
                <span className="text">
                  We&apos;ve established how rollups work to offer a solution
                  for scaling blockchains by processing transactions off-chain
                  and then settling them on the main chain. However, building a
                  custom rollup from scratch requires a deep understanding of
                  the underlying technology and significant development
                  resources. Rollup-as-a-Service (RaaS) platforms act as an
                  abstraction layer, hiding the complexities of rollup
                  development and allowing developers to focus on building their
                  dApps.
                </span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text181"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text182"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text183"></br>
                <span className="h2">Simplified Deployment</span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text185"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text186"></br>
                <span className="text">
                  One of the primary advantages of Rollup-as-a-Service (RaaS)
                  platforms is their ability to streamline the deployment and
                  management of rollups. Similar to software-as-a-service (SaaS)
                  models, RaaS platforms abstract the complexities of
                  infrastructure setup and maintenance, allowing developers to
                  focus more on application development and less on managing
                  nodes or maintaining code. By providing intuitive SDKs and
                  dashboards, RaaS empowers developers to launch
                  application-specific rollups efficiently without the need for
                  deep blockchain expertise.
                </span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text188"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text189"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text190"></br>
                <span className="h2">Customization</span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text192"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text193"></br>
                <span className="text">
                  Rollup-as-a-Service (RaaS) platforms empower developers with
                  flexibility and customization options to tailor rollup
                  solutions to specific application requirements. Whether
                  deploying public or private rollups, integrating block
                  explorers, wallets, analytics, and oracles, or incorporating
                  other third-party tooling, developers have the freedom to
                  configure and optimize their blockchain solutions without
                  being constrained by infrastructure limitations. Gateway’s
                  Rollup-as-a-Service (RaaS) platform offers over
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://gateway.fm/presto"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blog-what-is-raa-s-rollup-as-a-service-link2"
                >
                  35 customizations
                </a>
                <span className="text"> options to tailor your rollup.</span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text196"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text197"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text198"></br>
                <span className="h2">Faster Time-to-Market</span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text200"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text201"></br>
                <span className="text">
                  With Rollup-as-a-Service (RaaS), developers can deploy their
                  dApps on custom rollup chains with just a few clicks. This
                  streamlined deployment process reduces the time and resources
                  required compared to building rollups from scratch, enabling
                  developers to seize emerging market opportunities and
                  enhancing their competitive edge in the market.
                </span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text203"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text204"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text205"></br>
                <span className="h2">Cost Efficiency</span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text207"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text208"></br>
                <span className="text">
                  Building and maintaining rollup infrastructure can be
                  expensive. Rollup-as-a-Service (RaaS) providers handle the
                  infrastructure management, security maintenance, and ongoing
                  operational support. This allows developers to focus on
                  building their applications without worrying about the
                  underlying infrastructure.
                </span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text210"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text211"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text212"></br>
                <span className="h2">
                  Presto: Gateway.fm&apos;s Rollups as a Service (RaaS) Platform
                </span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text214"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text215"></br>
                <span className="text">
                  Gateway.fm’s Rollup-as-a-Service (RaaS) platform, Presto,
                  eliminates the complexities of launching custom rollup chains
                  by offering a low-code deployment environment compatible with
                  leading rollup frameworks, including
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://polygon.technology/polygon-cdk#"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text blog-what-is-raa-s-rollup-as-a-service-link3"
                >
                  Polygon CDK
                </a>
                <span className="text">
                  ,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://www.zksync.io/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text blog-what-is-raa-s-rollup-as-a-service-link4"
                >
                  zkSync
                </a>
                <span className="text">
                  ,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://arbitrum.io/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text blog-what-is-raa-s-rollup-as-a-service-link5"
                >
                  Arbitrum
                </a>
                <span className="text">
                  , and
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://www.optimism.io/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text blog-what-is-raa-s-rollup-as-a-service-link6"
                >
                  OP Stack
                </a>
                <span className="text">.</span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text221"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text222"></br>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text223"></br>
              </span>
              <img
                alt="image"
                src="/external/blog-img/gateway-fm-supported-stacks-800w.jpg"
                className="blog-what-is-raa-s-rollup-as-a-service-image1"
              />
              <span className="blog-what-is-raa-s-rollup-as-a-service-text224">
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="text">
                  By utilizing Presto, developers gain access to a wide range of
                  tools and functionalities that significantly streamline the
                  deployment process, including block explorers, faucets,
                  bridges, oracles, data indexers,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="text">data availability</span>
                <span className="text"> options</span>
                <span className="text">
                  ,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="text">RPCs</span>
                <span className="text">
                  , and over 35 additional integrations. Presto ensures the
                  highest levels of reliability and performance for your rollup
                  chains with real-time monitoring, guaranteeing 99.9% uptime
                  and excellent network performance, backed by enterprise-level
                  Service Level Agreements (SLAs).
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  Presto empowers organizations like
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <Link
                  to="/blog/gateway-wirex-case-study"
                  className="text blog-what-is-raa-s-rollup-as-a-service-navlink1"
                >
                  Wirex
                </Link>
                <span className="text">
                  , Lumia, Humanity Protocol,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <Link
                  to="/blog/gateway-witnesschain-mainnet-polygon-cdk-agglayer"
                  className="text blog-what-is-raa-s-rollup-as-a-service-navlink2"
                >
                  Witness Chain
                </Link>
                <span className="text">
                  , TAC,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <Link
                  to="/blog/how-is-gpt-protocol-building-a-decentralized-ai-network"
                  className="text blog-what-is-raa-s-rollup-as-a-service-navlink3"
                >
                  GPT Protocol
                </Link>
                <span className="text">
                  , and Palm Network with a cutting-edge platform to enhance
                  their blockchain solutions, ensuring scalability, security,
                  and efficiency with all the tools and support needed.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span className="h2">Deploying a Rollup with Presto</span>
                <br></br>
                <br className="text"></br>
                <span className="text">
                  Starting to create a rollup using Presto is straightforward
                  and efficient, empowering developers to harness the benefits
                  of enhanced scalability. Here’s how you can get your rollup up
                  and running:
                </span>
                <br className="text"></br>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">1. Sign in to Presto</span>
                <br className="text"></br>
                <br className="text"></br>
                <span className="text">
                  Access
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="text">presto.gateway.fm</span>
                <span className="text">
                  {' '}
                  using your Google account credentials. After signing in,
                  navigate to the upper right corner and click on &apos;+
                  Deploy&apos;.
                </span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/blog-img/how-to-create-a-rollup-800w.webp"
                className="blog-what-is-raa-s-rollup-as-a-service-image2"
              />
              <span className="blog-what-is-raa-s-rollup-as-a-service-text255">
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>2. Configure Deployment</span>
                <br></br>
                <br></br>
                <span>
                  Choose your deployment preferences to customize your rollup,
                  including selecting your preferred rollup stack, root chain,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <Link
                  to="/blog/choosing-a-da-solution-third-party-local-or-dacs"
                  className="blog-what-is-raa-s-rollup-as-a-service-navlink4"
                >
                  data availability options
                </Link>
                <span>
                  ,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <Link
                  to="/blog/gateway-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar"
                  className="blog-what-is-raa-s-rollup-as-a-service-navlink5"
                >
                  RPC
                </Link>
                <span>
                  , expected transactions, and over 35 additional integrations.
                </span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/blog-img/how-to-create-a-rollup-2-800w.webp"
                className="blog-what-is-raa-s-rollup-as-a-service-image3"
              />
              <span className="blog-what-is-raa-s-rollup-as-a-service-text264">
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>3. Monitor Deployment</span>
                <br></br>
                <br></br>
                <span>
                  Within a few minutes, monitor the status of your deployment
                  until it transitions to &apos;Active&apos;, indicating your
                  rollup is live.
                </span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/blog-img/how-to-create-a-rollup-3-800w.webp"
                className="blog-what-is-raa-s-rollup-as-a-service-image4"
              />
              <span className="blog-what-is-raa-s-rollup-as-a-service-text271">
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>4. Manage Your Rollup</span>
                <br></br>
                <br></br>
                <span>
                  Your rollup is now ready to enhance scalability and streamline
                  transactions on the chosen blockchain network. Use the menu to
                  access detailed settings and an overview of your deployed
                  rollup, empowering you with immediate control.
                </span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/blog-img/how-to-create-a-rollup-4-800w.webp"
                className="blog-what-is-raa-s-rollup-as-a-service-image5"
              />
              <span className="blog-what-is-raa-s-rollup-as-a-service-text278">
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br className="blog-what-is-raa-s-rollup-as-a-service-text280"></br>
                <span className="h2">Conclusion</span>
                <br className="h2"></br>
                <br></br>
                <span>
                  Launching your L2 or L3 rollup chain with Gateway.fm&apos;s
                  Rollup-as-a-Service (RaaS) platform,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://gateway.fm/presto"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Presto
                </a>
                <span>
                  , offers a strategic advantage in scaling your blockchain
                  applications. By simplifying deployment, enhancing
                  scalability, and ensuring cost efficiency, Presto empowers
                  developers to unlock the full potential of decentralized
                  technologies. Whether you&apos;re a startup innovating in the
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>blockchain space</span>
                <span>
                  {' '}
                  or an established enterprise seeking to optimize transaction
                  throughput, Presto provides the tools and support needed to
                  thrive in the decentralized future.
                </span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name40"></Footer>
    </div>
  )
}

export default BlogWhatIsRaaSRollupAsAService
