import React from 'react'

import PropTypes from 'prop-types'

import './card-video-background.css'

const CardVideoBackground = (props) => {
  return (
    <div className="card-video-background-container">
      <video
        src={props.video}
        loop="true"
        muted="true"
        poster={props.poster}
        autoPlay="true"
        playsInline="true"
        className="card-video-background-video"
      ></video>
      <div className="card-video-background-content">
        <div className="card-video-background-texts">
          <span className="card-video-background-text1">{props.label}</span>
          <span className="card-video-background-text2">{props.text}</span>
        </div>
      </div>
    </div>
  )
}

CardVideoBackground.defaultProps = {
  text: 'Developed in Rust, known for its performance and safety, the system features a robust WS API for super-fast data collection',
  video: '/external/services/back-1.mp4',
  label: 'High-Speed Data-Collection System',
  poster: 'https://play.teleporthq.io/static/svg/videoposter.svg',
}

CardVideoBackground.propTypes = {
  text: PropTypes.string,
  video: PropTypes.string,
  label: PropTypes.string,
  poster: PropTypes.string,
}

export default CardVideoBackground
