import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleSidePage from '../components/title-side-page'
import BlogPostFeatured from '../components/blog-post-featured'
import BlogPostRegular from '../components/blog-post-regular'
import Footer from '../components/footer'
import './blog.css'

const Blog = (props) => {
  return (
    <div className="blog-container1">
      <Helmet>
        <title>
          Gateway.fm Blog | Insights on Web3 and Blockchain Technology
        </title>
        <meta
          name="description"
          content="Explore Gateway.fm Blog for expert insights, updates, and in-depth articles on Web3 infrastructure and blockchain technology."
        />
        <meta
          property="og:title"
          content="Gateway.fm Blog | Insights on Web3 and Blockchain Technology"
        />
        <meta
          property="og:description"
          content="Explore Gateway.fm Blog for expert insights, updates, and in-depth articles on Web3 infrastructure and blockchain technology."
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name2"></Navigation>
      <div className="blog-container2">
        <div className="blog-container3">
          <TitleSidePage
            text="NEWS, UPDATES &amp; GUIDES"
            rootClassName="title-side-pageroot-class-name"
          ></TitleSidePage>
          <Link to="/blog/cdk-erigon-gatway" className="blog-navlink10">
            <BlogPostFeatured
              tag="Erigon"
              date="26 of August 2024"
              text={
                <Fragment>
                  <span className="blog-text10">
                    CDK Erigon | Pushing the boundaries of blockchain
                    infrastructure
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/cdk-erigon-gateway-1500w.png"
              header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
              rootClassName="blog-post-featuredroot-class-name9"
              className="blog-component12"
            ></BlogPostFeatured>
          </Link>
          <Link to="/blog/truthlink-oracle-gateway" className="blog-navlink11">
            <BlogPostFeatured
              tag="Indexer"
              date="19 of August 2024"
              text={
                <Fragment>
                  <span className="blog-text11">
                    <span className="blog-text12">
                      Truthlink— Oracle by Gateway.fm
                    </span>
                    <br></br>
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/truthlink-oracle-gateway-1500w.jpg"
              header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
              rootClassName="blog-post-featuredroot-class-name8"
              className="blog-component13"
            ></BlogPostFeatured>
          </Link>
          <Link to="/blog/gateway-indexer" className="blog-navlink12">
            <BlogPostFeatured
              tag="Indexer"
              date="15 of August 2024"
              text={
                <Fragment>
                  <span className="blog-text14">
                    <span className="blog-text15">Indexer by Gateway.fm</span>
                    <br></br>
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/indexer-1500w.jpg"
              header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
              rootClassName="blog-post-featuredroot-class-name7"
              className="blog-component14"
            ></BlogPostFeatured>
          </Link>
          <Link
            to="/blog/presto-rollup-as-a-service"
            className="blog-navlink13"
          >
            <BlogPostFeatured
              tag="Staking"
              date="13 of August 2024"
              text={
                <Fragment>
                  <span className="blog-text17">
                    <span className="blog-text18">
                      Presto | Rollup as a Service
                    </span>
                    <br></br>
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/gateway-presto-blockchain-rollup-raas-1500w.jpg"
              header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
              rootClassName="blog-post-featuredroot-class-name6"
              className="blog-component15"
            ></BlogPostFeatured>
          </Link>
          <Link to="/blog/stakeway-bitcoin-babylon" className="blog-navlink14">
            <BlogPostFeatured
              tag="Staking"
              date="8 of August 2024"
              text={
                <Fragment>
                  <span className="blog-text20">
                    <span className="blog-text21">
                      Staking Bitcoin With Babylon
                    </span>
                    <br></br>
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/stakeway-babylon-1500w.jpg"
              header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
              rootClassName="blog-post-featuredroot-class-name5"
              className="blog-component16"
            ></BlogPostFeatured>
          </Link>
          <Link
            to="/blog/haust-network-partners-with-gateway-fm-to-make-web3-more-user-friendly"
            className="blog-navlink15"
          >
            <BlogPostFeatured
              tag="Partnership"
              date="1 of August 2024"
              text={
                <Fragment>
                  <span className="blog-text23">
                    <span className="blog-text24">
                      Haust Network Partners with Gateway.fm To Make Web3 More
                      User Friendly
                    </span>
                    <br></br>
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/haust-network-partners-with-gateway-fm-to-make-web3-more-user-friendly-1500w.jpg"
              header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
              rootClassName="blog-post-featuredroot-class-name4"
              className="blog-component17"
            ></BlogPostFeatured>
          </Link>
          <Link
            to="/blog/polygon-agglayer-the-future-of-blockchain-interoperability"
            className="blog-navlink16"
          >
            <BlogPostFeatured
              tag="AggLayer"
              date="31 of July 2024"
              text={
                <Fragment>
                  <span className="blog-text26">
                    <span className="blog-text27">
                      Polygon AggLayer: The Future of Blockchain
                      Interoperability
                    </span>
                    <br></br>
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/agglayer-polygon-cdk-gateway-blockchain-1500w.png"
              header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
              rootClassName="blog-post-featuredroot-class-name3"
              className="blog-component18"
            ></BlogPostFeatured>
          </Link>
          <Link
            to="/blog/let-us-build-your-web3-infrastructure"
            className="blog-navlink17"
          >
            <BlogPostFeatured
              tag="Web3"
              date="29 of July 2024"
              text={
                <Fragment>
                  <span className="blog-text29">
                    Let Us Build Your Web3 Infrastructure
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/gateway-stakeway-presto-1500w.png"
              header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
              rootClassName="blog-post-featuredroot-class-name2"
              className="blog-component19"
            ></BlogPostFeatured>
          </Link>
          <Link
            to="/blog/powering-l2-blockchain-with-polygon-cdk-and-agglayer"
            className="blog-navlink18"
          >
            <BlogPostFeatured
              tag="AggLayer"
              date="22 of July 2024"
              text={
                <Fragment>
                  <span className="blog-text30">
                    Powering L2 BLockchain For TON Aplication Chain With Polygon
                    CDK &amp; AggLayer
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/powering-l2-blockchain-with-polygon-cdk-and-agglayer-1500w.jpg"
              header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
              rootClassName="blog-post-featuredroot-class-name"
              className="blog-component20"
            ></BlogPostFeatured>
          </Link>
          <Link
            to="/blog/what-is-raas-rollup-as-a-service"
            className="blog-navlink19"
          >
            <BlogPostFeatured
              tag="RaaS"
              date="19 of July 2024"
              text={
                <Fragment>
                  <span className="blog-text31">
                    <span className="blog-text32">
                      What is RaaS? Rollup-as-a-Service.
                    </span>
                    <br></br>
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/what-is-raas-rollup-as-a-service-1500w.jpg"
              header="Powering L2 BLockchain For TON Aplication Chain With Polygon CDK &amp; AggLayer"
              className="blog-component21"
            ></BlogPostFeatured>
          </Link>
          <Link
            to="/blog/gateway-witnesschain-mainnet-polygon-cdk-agglayer"
            className="blog-navlink20"
          >
            <BlogPostFeatured
              tag="Polygon CDK"
              date="2 of July 2024"
              text={
                <Fragment>
                  <span className="blog-text34">
                    A highlight is the integration of our mainnet with the
                    AGGLAYER.
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/gateway-witnesschain-mainnet-polygon-cdk-agglayer-1500w.png"
              header="A highlight is the integration of our mainnet with the AGGLAYER."
              className="blog-component22"
            ></BlogPostFeatured>
          </Link>
          <Link to="/blog/gateway-node-sales" className="blog-navlink21">
            <BlogPostFeatured
              tag="Node Sales"
              date="17 of June 2024"
              text={
                <Fragment>
                  <span className="blog-text35">
                    Node Sales Is A Way To Raise Funds With Tangible Assets
                  </span>
                </Fragment>
              }
              image="/external/blog-banners/gateway-node-sale-1500w.png"
              header="Node Sales Is A Way To Raise Funds With Tangible Assets"
              rootClassName="blog-post-featuredroot-class-name1"
              className="blog-component23"
            ></BlogPostFeatured>
          </Link>
          <div className="blog-row1">
            <Link
              to="/blog/gateway-wirex-case-study"
              className="blog-navlink22"
            >
              <BlogPostRegular
                date="7th of June 2024"
                image="/external/blog-banners/gateway-wirex-case-study-1500w.png"
                header="A Partnership That Transformed The Crypto-Financial Industry"
                rootClassName="blog-post-regularroot-class-name1"
                className="blog-component24"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/devnet-testnet-or-mainnet-deployment"
              className="blog-navlink23"
            >
              <BlogPostRegular
                date="15 of May 2024"
                image="/external/sandbox-1500w.png"
                header="Devnet, Testnet or Mainnet deployment?"
                rootClassName="blog-post-regularroot-class-name"
                className="blog-component25"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-container4">
            <Link
              to="/blog/choosing-a-da-solution-third-party-local-or-dacs"
              className="blog-navlink24"
            >
              <BlogPostRegular
                date="13 of May 2024"
                className="blog-component26"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/lukso-and-gateway-a-successful-partnership"
              className="blog-navlink25"
            >
              <BlogPostRegular
                date="7th of May 2024"
                image="/external/blog-banners/gatway-lukso-partnership-1500w.webp"
                header="LUKSO &amp; Gateway.fm: A Successful Partnership"
                className="blog-component27"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-container5">
            <Link
              to="/blog/gateway-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar"
              className="blog-navlink26"
            >
              <BlogPostRegular
                date="7th of May 2024"
                image="/external/blog-banners/gateway-stellar-partnership-1500w1.webp"
                header="Gateway.fm Announces Role in Building Public Soroban RPC Infrastructure for Stellar"
                className="blog-component28"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/how-is-gpt-protocol-building-a-decentralized-ai-network"
              className="blog-navlink27"
            >
              <BlogPostRegular
                date="3rd of May 2024"
                image="/external/blog-banners/gateway-gpt-protocol-partnership-1500w.webp"
                header="How is GPT Protocol Building a Decentralized AI Network?"
                className="blog-component29"
              ></BlogPostRegular>
            </Link>
          </div>
          <div className="blog-container6">
            <Link
              to="/blog/gateway-and-gpt-protocol-join-forces"
              className="blog-navlink28"
            >
              <BlogPostRegular
                date="11th of March 2024"
                image="/external/blog-banners/gateway-gpt-protocol-partnership-announcement-1500w.webp"
                header="Gateway.fm and GPT Protocol Join Forces: Redefining Decentralized AI and Blockchain Integration"
                className="blog-component30"
              ></BlogPostRegular>
            </Link>
            <Link
              to="/blog/gateway-and-near-foundation-team-up"
              className="blog-navlink29"
            >
              <BlogPostRegular
                date="7th of March 2024"
                image="/external/blog-banners/gateway-near-partnership-1500w1.webp"
                header="Gateway and NEAR Foundation Team Up for Next-Gen Data Availability"
                className="blog-component31"
              ></BlogPostRegular>
            </Link>
          </div>
          <Link to="/blog/gateway-wirex-partnership" className="blog-navlink30">
            <BlogPostRegular
              date="5th of February 2024"
              image="/external/blog-banners/gateway-wirex-partnership-announcement-1500w.png"
              header="Gateway Partnership with Wirex"
              className="blog-component32"
            ></BlogPostRegular>
          </Link>
          <div className="blog-row2"></div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Blog
