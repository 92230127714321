import React from 'react'

import PropTypes from 'prop-types'

import Tag from './tag'
import './career.css'

const Career = (props) => {
  return (
    <div className="career-career">
      <div className="career-container">
        <div className="career-texts">
          <div className="career-tags">
            <Tag text={props.tag1} rootClassName="tagroot-class-name12"></Tag>
            <Tag text={props.tag2} rootClassName="tagroot-class-name14"></Tag>
            <Tag text={props.tag3} rootClassName="tagroot-class-name13"></Tag>
          </div>
          <div className="career-title">
            <span className="career-text1">{props.label}</span>
          </div>
          <span className="career-text2">{props.subtext}</span>
        </div>
        <img
          alt="arrowright8113"
          src={props.arrowrightSrc}
          className="career-arrowright"
        />
      </div>
    </div>
  )
}

Career.defaultProps = {
  tag3: '',
  tag1: '',
  label: 'Business developer',
  subtext:
    'Seeking a mid-level Business Development professional in Web3/Web2 B2B infrastructure to drive sales, client relationships, and market growth for Presto, a zkEVM roll-up deployment platform',
  tag2: '',
  arrowrightSrc: '/external/arrowright8113-vc68.svg',
}

Career.propTypes = {
  tag3: PropTypes.string,
  tag1: PropTypes.string,
  label: PropTypes.string,
  subtext: PropTypes.string,
  tag2: PropTypes.string,
  arrowrightSrc: PropTypes.string,
}

export default Career
