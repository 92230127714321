import React from 'react'

import PropTypes from 'prop-types'

import './navigation-link.css'

const NavigationLink = (props) => {
  return (
    <div className={`navigation-link-container ${props.rootClassName} `}>
      <span className="navigation-link-text">{props.label}</span>
    </div>
  )
}

NavigationLink.defaultProps = {
  rootClassName: '',
  label: 'About',
}

NavigationLink.propTypes = {
  rootClassName: PropTypes.string,
  label: PropTypes.string,
}

export default NavigationLink
