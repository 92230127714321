import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import Tag from '../components/tag'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ImageProductHero from '../components/image-product-hero'
import ContentTitleProduct from '../components/content-title-product'
import CardProductHighlight from '../components/card-product-highlight'
import ContentParagraph from '../components/content-paragraph'
import ContentSmallTitle from '../components/content-small-title'
import AnnouncementSmall from '../components/announcement-small'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import Testimonial from '../components/testimonial'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './web3-infrastructure-blueprint-nft-platform.css'

const Web3InfrastructureBlueprintNFTPlatform = (props) => {
  return (
    <div className="web3-infrastructure-blueprint-nft-platform-container1">
      <Helmet>
        <title>Gateway.fm | Web3 Infrastructure Blueprint: NFT Platform</title>
        <meta
          name="description"
          content="Discover Gateway.fm's blueprint for a Web3 NFT platform, utilizing blockchain technology for secure, transparent, and efficient creation, trading, and management of digital assets."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Web3 Infrastructure Blueprint: NFT Platform"
        />
        <meta
          property="og:description"
          content="Discover Gateway.fm's blueprint for a Web3 NFT platform, utilizing blockchain technology for secure, transparent, and efficient creation, trading, and management of digital assets."
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name9"></Navigation>
      <div className="web3-infrastructure-blueprint-nft-platform-content">
        <div className="web3-infrastructure-blueprint-nft-platform-hero">
          <div className="web3-infrastructure-blueprint-nft-platform-description">
            <TitleProductHero text="NFT PLATFORM"></TitleProductHero>
            <Tag rootClassName="tagroot-class-name3"></Tag>
            <SubtitleProductHero text="We will have your own NFT platform ready for you"></SubtitleProductHero>
            <USPCheckmark text="Save $2M on own blockchain"></USPCheckmark>
            <USPCheckmark text="No code"></USPCheckmark>
            <USPCheckmark text="Zero knowledge"></USPCheckmark>
            <Link
              to="/contact-form"
              className="web3-infrastructure-blueprint-nft-platform-navlink1"
            >
              <ButtonPrimarySubtitle className="web3-infrastructure-blueprint-nft-platform-component17"></ButtonPrimarySubtitle>
            </Link>
          </div>
          <ImageProductHero image="/external/artobject17309-zntb-500h.png"></ImageProductHero>
        </div>
        <div className="web3-infrastructure-blueprint-nft-platform-container2">
          <ContentTitleProduct></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-nft-platform-cards">
            <CardProductHighlight
              main="Efficient minting"
              supportive="Reduce transaction costs and network congestion"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Cost-Effective Sales"
              supportive="Well-audited purchaser contract and a dynamic price oracle"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Metadata Generation"
              supportive="We offer both onchain and offchain metadata solutions"
            ></CardProductHighlight>
          </div>
          <ContentTitleProduct text="Summary"></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-nft-platform-container3">
            <span className="web3-infrastructure-blueprint-nft-platform-text1">
              <span>
                Our NFT Platform Blueprint is designed to provide a
                decentralized platform that offers seamless, secure and engaging
                experience for artists, collectors and enthusiasts alike.
                Experience intuitive NFT creation and management that ensures
                faster transformation from ideas to finished NFTs - which are
                ready for community and market engagement.
              </span>
              <br></br>
              <br></br>
              <span>
                With an intuitive user interface and robust backend systems, our
                platform supports a wide array of digital assets, from digital
                art and collectibles to virtual real estate, ticketing,
                certificates and beyond.
              </span>
              <br></br>
            </span>
          </div>
          <ContentTitleProduct text="Objectives"></ContentTitleProduct>
          <ContentParagraph text="The NFT platform overcomes the complexity of creating and managing by providing user-friendly tools and intuitive interfaces backed by robust smart contracts. These tools guide you through minting, transferring and managing NFTs without needing extensive technical knowledge."></ContentParagraph>
          <ContentTitleProduct text="Minting"></ContentTitleProduct>
          <ContentParagraph text="Our platform uses optimized smart contracts and L2 solutions to reduce transaction costs and network congestion during NFT minting. This dual-layer approach ensures scalable and efficient processing while maintaining low costs."></ContentParagraph>
          <ContentTitleProduct text="Cost-Effective Sales"></ContentTitleProduct>
          <ContentParagraph text="We address and solve the challenges of NFT sales by implementing a well-audited purchaser contract and a dynamic price oracle. This ensures secure transactions, prevents gas wars and supports various ERC20 tokens and fiat currencies for a broad audience."></ContentParagraph>
          <ContentTitleProduct text="Metadata Generation"></ContentTitleProduct>
          <ContentParagraph text="To ensure consistent and reliable metadata for NFTs, we offer both onchain and offchain metadata solutions. Onchain metadata is securely stored on the blockchain, while offchain metadata is managed via IPFS, supporting dynamic updates through an oracle system."></ContentParagraph>
          <ContentTitleProduct text="Community Building"></ContentTitleProduct>
          <ContentParagraph text="Our platform includes social features like profiles, activity feeds and social badges. Users can interact through likes, comments and sharing - thus promoting a vibrant community and higher user participation. It’s also possible to initiate incentivization with rewards or perks onchain for programs like invitations."></ContentParagraph>
          <ContentTitleProduct text="Allowlists For Private Sales Rounds"></ContentTitleProduct>
          <ContentParagraph text="Our allowlist module simplifies the management of presale participants, ensuring only approved users can access private sales. This system includes a backoffice dashboard and secure signature service for efficient and secure presale processes."></ContentParagraph>
          <ContentSmallTitle
            text="24/7 Art Gallery"
            rootClassName="content-small-titleroot-class-name25"
          ></ContentSmallTitle>
          <ContentParagraph text="Artists can showcase and sell digital art with guaranteed authenticity and ownership. Allowing for a 24/7 international market and audience. Our NFT Platform validates originality and ownership of the digital art."></ContentParagraph>
          <ContentSmallTitle
            text="Creativity That Never Stops"
            rootClassName="content-small-titleroot-class-name26"
          ></ContentSmallTitle>
          <ContentParagraph text="Content creators can transform their works into NFTs and directly engage with their audience, boosting fan interaction. Our platform ensures the authenticity of music and media NFTs, giving creators full control and ensuring they receive fair compensation. Users can also mint NFT collectibles such as trading cards, virtual animals and other rare items."></ContentParagraph>
          <ContentSmallTitle
            text="Real-World Assets &amp; IP"
            rootClassName="content-small-titleroot-class-name27"
          ></ContentSmallTitle>
          <ContentParagraph text="Real estate, luxury goods and other physical assets can be tokenized as NFTs. Enabling fractional ownership and simplifying the transfer of ownership rights, making investments more accessible. Our platform ensures the authenticity and accurate ownership of these assets through secure validation processes."></ContentParagraph>
          <ContentSmallTitle
            text="Academic Credentials"
            rootClassName="content-small-titleroot-class-name28"
          ></ContentSmallTitle>
          <ContentParagraph text="This applies to any other asset like documentation or credentials. Academic Credentials Institutions can issue tamper-proof NFT-based diplomas and certificates. Innovators can mint patents and intellectual property (IP) as NFTs for secure management and transfer of rights. Users can also manage blockchain-based domain names as NFTs for secure and easy transferability."></ContentParagraph>
          <ContentSmallTitle
            text="Web3 Gaming"
            rootClassName="content-small-titleroot-class-name29"
          ></ContentSmallTitle>
          <ContentParagraph text="Game developers can tokenize in-game items which opens up for players to own, sell and trade the items across different games and platforms."></ContentParagraph>
          <ContentSmallTitle
            text="Event Tickets"
            rootClassName="content-small-titleroot-class-name30"
          ></ContentSmallTitle>
          <ContentParagraph text="Organizers can issue secure, verifiable NFT-based tickets to prevent fraud."></ContentParagraph>
        </div>
        <AnnouncementSmall
          text="We enabled X to do Y"
          rootClassName="announcement-smallroot-class-name4"
        ></AnnouncementSmall>
        <div className="web3-infrastructure-blueprint-nft-platform-container4">
          <SubtitleProductPromo></SubtitleProductPromo>
          <AnnouncementSmall
            text="We enabled Wirex to elevate its zk-powered network"
            rootClassName="announcement-smallroot-class-name15"
            className="web3-infrastructure-blueprint-nft-platform-component50"
          ></AnnouncementSmall>
          <div className="web3-infrastructure-blueprint-nft-platform-testimonials">
            <Testimonial
              name="Wirex"
              text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
              position="@wirexapp"
              rootClassName="testimonialroot-class-name75"
              logopolygonSrc="/external/vector1317-f6xm.svg"
            ></Testimonial>
            <Testimonial
              name="GPT Protocol"
              text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
              position="@gpt_protocol"
              rootClassName="testimonialroot-class-name76"
              logopolygonSrc="/external/logos/logo-gpt-200w.png"
            ></Testimonial>
            <Testimonial
              name="Dora"
              text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
              position="@SearchOnDora"
              rootClassName="testimonialroot-class-name77"
              logopolygonSrc="/external/logos/logo-dora1-200h.png"
            ></Testimonial>
          </div>
        </div>
        <Link
          to="/contact-form"
          className="web3-infrastructure-blueprint-nft-platform-navlink2"
        >
          <Floating
            label="NFT Platform"
            buttonLabel="Get started"
            description="We will have your own NFT platform ready for you"
            buttonSubtext="Initiate this blueprint"
            rootClassName="floatingroot-class-name2"
            className="web3-infrastructure-blueprint-nft-platform-component54"
          ></Floating>
        </Link>
      </div>
      <Footer rootClassName="footerroot-class-name9"></Footer>
    </div>
  )
}

export default Web3InfrastructureBlueprintNFTPlatform
