import React from 'react'

import PropTypes from 'prop-types'

import './card-case-study.css'

const CardCaseStudy = (props) => {
  return (
    <div className="card-case-study-container1">
      <div className="card-case-study-container2">
        <div className="card-case-study-content">
          <img src={props.icon} className="card-case-study-gnosisgnognologo" />
          <div className="card-case-study-container3">
            <span className="card-case-study-text1">{props.label}</span>
            <span className="card-case-study-text2">{props.subtext}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

CardCaseStudy.defaultProps = {
  icon: '/external/gnosisgnognologo1317-jhv7-200h.png',
  label: 'Gnosis Pay',
  subtext:
    'Private roll up for Gnosis Pay: 3rd party services and smart contract deployments',
}

CardCaseStudy.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  subtext: PropTypes.string,
}

export default CardCaseStudy
