import React from 'react'

import PropTypes from 'prop-types'

import './content-small-title.css'

const ContentSmallTitle = (props) => {
  return (
    <div className={`content-small-title-container ${props.rootClassName} `}>
      <span className="content-small-title-text">{props.text}</span>
    </div>
  )
}

ContentSmallTitle.defaultProps = {
  rootClassName: '',
  text: 'SOLUTION',
}

ContentSmallTitle.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default ContentSmallTitle
