import React from 'react'

import PropTypes from 'prop-types'

import './button-small-secondary.css'

const ButtonSmallSecondary = (props) => {
  return (
    <div className="button-small-secondary-container1">
      <div className="button-small-secondary-container2">
        <span className="button-small-secondary-text">{props.label}</span>
      </div>
    </div>
  )
}

ButtonSmallSecondary.defaultProps = {
  label: 'Small main button',
}

ButtonSmallSecondary.propTypes = {
  label: PropTypes.string,
}

export default ButtonSmallSecondary
