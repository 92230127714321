import React from 'react'

import PropTypes from 'prop-types'

import './image-product-hero.css'

const ImageProductHero = (props) => {
  return (
    <div className="image-product-hero-container">
      <div className="image-product-hero-product-image">
        <img
          alt="hive26986"
          src={props.image}
          className="image-product-hero-hive2"
        />
        <div className="image-product-hero-play1">
          <img
            alt="play6986"
            src="/external/play6986-6p1s.svg"
            className="image-product-hero-play2"
          />
          <span className="image-product-hero-text1">
            <span>0:59</span>
          </span>
        </div>
      </div>
    </div>
  )
}

ImageProductHero.defaultProps = {
  image: '/external/hive26986-lpud-500h.png',
}

ImageProductHero.propTypes = {
  image: PropTypes.string,
}

export default ImageProductHero
