import React from 'react'

import PropTypes from 'prop-types'

import './button-secondary.css'

const ButtonSecondary = (props) => {
  return (
    <div className={`button-secondary-container ${props.rootClassName} `}>
      <div className="button-secondary-secondary-button">
        <div className="button-secondary-texts">
          <span className="button-secondary-text1">{props.label}</span>
          <span className="button-secondary-text2">{props.subtext}</span>
        </div>
      </div>
    </div>
  )
}

ButtonSecondary.defaultProps = {
  rootClassName: '',
  label: 'Select blueprint',
  subtext: 'Build DEX, NFT platform, Digital bank & more',
}

ButtonSecondary.propTypes = {
  rootClassName: PropTypes.string,
  label: PropTypes.string,
  subtext: PropTypes.string,
}

export default ButtonSecondary
