import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import Tag from '../components/tag'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ImageProductHero from '../components/image-product-hero'
import ContentTitleProduct from '../components/content-title-product'
import CardProductHighlight from '../components/card-product-highlight'
import ContentParagraph from '../components/content-paragraph'
import ContentSmallTitle from '../components/content-small-title'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import AnnouncementSmall from '../components/announcement-small'
import Testimonial from '../components/testimonial'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './web3-infrastructure-blueprint-nft-gallery.css'

const Web3InfrastructureBlueprintNFTGallery = (props) => {
  return (
    <div className="web3-infrastructure-blueprint-nft-gallery-container1">
      <Helmet>
        <title>Gateway.fm | Web3 Infrastructure Blueprint: NFT Gallery</title>
        <meta
          name="description"
          content="Explore Gateway.fm's blueprint for a Web3 NFT gallery, leveraging blockchain for secure and decentralized showcasing of digital art and collectibles."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Web3 Infrastructure Blueprint: NFT Gallery"
        />
        <meta
          property="og:description"
          content="Explore Gateway.fm's blueprint for a Web3 NFT gallery, leveraging blockchain for secure and decentralized showcasing of digital art and collectibles."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/fbff5702-ee07-4f97-9357-ac1bf56b3cdf?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name23"></Navigation>
      <div className="web3-infrastructure-blueprint-nft-gallery-content">
        <div className="web3-infrastructure-blueprint-nft-gallery-hero">
          <div className="web3-infrastructure-blueprint-nft-gallery-description">
            <TitleProductHero text="NFT GALLERY"></TitleProductHero>
            <Tag rootClassName="tagroot-class-name10"></Tag>
            <SubtitleProductHero text="Showcase and trade unique digital art and collectibles as non-fungible tokens"></SubtitleProductHero>
            <USPCheckmark text="Enable digital scarcity and provenance"></USPCheckmark>
            <USPCheckmark text="Foster new art ecosystems"></USPCheckmark>
            <USPCheckmark text="No code. Zero knowledge"></USPCheckmark>
            <Link
              to="/contact-form"
              className="web3-infrastructure-blueprint-nft-gallery-navlink1"
            >
              <ButtonPrimarySubtitle className="web3-infrastructure-blueprint-nft-gallery-component17"></ButtonPrimarySubtitle>
            </Link>
          </div>
          <ImageProductHero image="/external/blueprints/leaf-500h.png"></ImageProductHero>
        </div>
        <div className="web3-infrastructure-blueprint-nft-gallery-container2">
          <ContentTitleProduct></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-nft-gallery-cards">
            <CardProductHighlight
              main="Unlocking the Potential of Digital Scarcity"
              supportive="We enable the creation of truly unique and provably scarce digital assets"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Ensuring Authenticity and Provenance"
              supportive="Immutability and traceability of NFT ownership and metadata "
            ></CardProductHighlight>
            <CardProductHighlight
              main="Enabling Frictionless Trading and Liquidity"
              supportive="Enable seamless peer-to-peer trading of NFTs without the need for intermediaries"
            ></CardProductHighlight>
          </div>
          <ContentTitleProduct text="Summary"></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-nft-gallery-container3">
            <span className="web3-infrastructure-blueprint-nft-gallery-text">
              NFT Gallery leverages blockchain technology to establish a secure,
              transparent, and decentralized ecosystem for creating, trading,
              and managing non-fungible tokens (NFTs). By combining smart
              contracts, cryptographic proofs, and distributed ledger
              technology, we enable the minting, ownership, and provenance of
              unique digital assets, revolutionizing the way we perceive and
              interact with digital collectibles, art, and virtual experiences.
            </span>
          </div>
          <ContentTitleProduct text="Unlocking the Potential of Digital Scarcity"></ContentTitleProduct>
          <ContentParagraph text="Traditional digital assets have been plagued by the issue of infinite replicability, diminishing their perceived value and uniqueness. Our Web3 NFT Platform solution addresses this challenge by harnessing the power of blockchain to create verifiable digital scarcity. Through the use of NFTs, we enable the creation of truly unique and provably scarce digital assets, opening up new opportunities for artists, creators, and collectors alike."></ContentParagraph>
          <ContentTitleProduct text="Ensuring Authenticity and Provenance"></ContentTitleProduct>
          <ContentParagraph text="Authenticity and provenance are critical in the world of digital collectibles and art. Our Web3 solution ensures the immutability and traceability of NFT ownership and metadata by recording all transactions and asset details on a decentralized blockchain ledger. Stakeholders can trace the entire lifecycle of an NFT, from its minting to its current ownership, accessing detailed information such as creator details, ownership history, and authenticity verification. This level of transparency fosters trust and credibility in the NFT ecosystem."></ContentParagraph>
          <ContentTitleProduct text="Enabling Frictionless Trading and Liquidity"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 NFT Platform solution is designed to facilitate frictionless trading and liquidity for NFTs. By leveraging decentralized exchange (DEX) protocols and liquidity pools, we enable seamless peer-to-peer trading of NFTs without the need for intermediaries. This streamlined trading experience reduces barriers to entry, attracts a broader user base, and fosters a thriving ecosystem for digital collectibles and art."></ContentParagraph>
          <ContentTitleProduct text="Scalable and Interoperable"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 solution is built with scalability and interoperability in mind, capable of handling high volumes of NFT transactions and integrations with various blockchain networks and platforms. By leveraging advanced blockchain technologies, such as layer-2 scaling solutions and cross-chain bridges, we ensure efficient and cost-effective processing of NFT transactions while enabling seamless interoperability between different blockchain ecosystems."></ContentParagraph>
          <ContentTitleProduct text="Components"></ContentTitleProduct>
          <ContentSmallTitle
            text="Blockchain Network"
            rootClassName="content-small-titleroot-class-name70"
          ></ContentSmallTitle>
          <ContentParagraph text="A secure, decentralized ledger for recording NFT data, transactions, and metadata, leveraging advanced consensus mechanisms and cryptographic techniques."></ContentParagraph>
          <ContentSmallTitle
            text="Smart Contracts"
            rootClassName="content-small-titleroot-class-name71"
          ></ContentSmallTitle>
          <ContentParagraph text="Self-executing contracts that govern the minting, ownership transfer, and management of NFTs, ensuring the enforcement of predefined rules and the preservation of digital scarcity."></ContentParagraph>
          <ContentSmallTitle
            text="NFT Minting and Metadata"
            rootClassName="content-small-titleroot-class-name72"
          ></ContentSmallTitle>
          <ContentParagraph text="Robust tools and interfaces for creators to mint unique NFTs, associate metadata (e.g., artwork, media files, descriptions), and manage their digital assets on the platform."></ContentParagraph>
          <ContentSmallTitle
            text="Decentralized Exchange (DEX)"
            rootClassName="content-small-titleroot-class-name73"
          ></ContentSmallTitle>
          <ContentParagraph text="A built-in decentralized exchange that enables seamless peer-to-peer trading of NFTs, providing liquidity and ensuring a frictionless trading experience."></ContentParagraph>
          <ContentSmallTitle
            text="Identity Management"
            rootClassName="content-small-titleroot-class-name74"
          ></ContentSmallTitle>
          <ContentParagraph text="A robust identity management system for securely onboarding and managing user identities, ensuring proper access controls and compliance with regulatory requirements."></ContentParagraph>
          <ContentSmallTitle
            text="Analytics and Insights"
            rootClassName="content-small-titleroot-class-name75"
          ></ContentSmallTitle>
          <ContentParagraph text="Powerful analytics and visualization tools that leverage the transparent data stored on the blockchain, providing stakeholders with valuable insights, market trends, and decision-making support."></ContentParagraph>
          <ContentSmallTitle
            text="Interoperability Layer"
            rootClassName="content-small-titleroot-class-name76"
          ></ContentSmallTitle>
          <ContentParagraph text="A flexible interoperability layer that enables cross-chain bridging and integration with various blockchain networks, ensuring compatibility and data exchange across diverse platforms."></ContentParagraph>
          <ContentParagraph text="By harnessing the power of Web3 technologies, our NFT Gallery solution delivers unparalleled authenticity, provenance, liquidity, and scalability, enabling a thriving ecosystem for digital collectibles, art, and virtual experiences, fostering creativity, and empowering artists, creators, and collectors alike."></ContentParagraph>
        </div>
        <div className="web3-infrastructure-blueprint-nft-gallery-container4">
          <SubtitleProductPromo></SubtitleProductPromo>
          <AnnouncementSmall
            text="We enabled Wirex to elevate its zk-powered network"
            rootClassName="announcement-smallroot-class-name14"
            className="web3-infrastructure-blueprint-nft-gallery-component49"
          ></AnnouncementSmall>
          <div className="web3-infrastructure-blueprint-nft-gallery-testimonials">
            <Testimonial
              name="Wirex"
              text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
              position="@wirexapp"
              rootClassName="testimonialroot-class-name72"
              logopolygonSrc="/external/vector1317-f6xm.svg"
            ></Testimonial>
            <Testimonial
              name="GPT Protocol"
              text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
              position="@gpt_protocol"
              rootClassName="testimonialroot-class-name73"
              logopolygonSrc="/external/logos/logo-gpt-200w.png"
            ></Testimonial>
            <Testimonial
              name="Dora"
              text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
              position="@SearchOnDora"
              rootClassName="testimonialroot-class-name74"
              logopolygonSrc="/external/logos/logo-dora1-200h.png"
            ></Testimonial>
          </div>
        </div>
        <Link
          to="/contact-form"
          className="web3-infrastructure-blueprint-nft-gallery-navlink2"
        >
          <Floating
            label="NFT Gallery"
            buttonLabel="Get started"
            description="Showcase and trade unique digital art and collectibles as non-fungible tokens"
            buttonSubtext="Initiate this blueprint"
            rootClassName="floatingroot-class-name9"
            className="web3-infrastructure-blueprint-nft-gallery-component53"
          ></Floating>
        </Link>
      </div>
      <Footer rootClassName="footerroot-class-name22"></Footer>
    </div>
  )
}

export default Web3InfrastructureBlueprintNFTGallery
