import React from 'react'

import PropTypes from 'prop-types'

import './option.css'

const Option = (props) => {
  return (
    <div className="option-container1">
      <div
        data-step={props.dataStep}
        data-option={props.dataOption}
        className="option-container2 option"
      >
        <img
          id="checkbox-image"
          src={props.logo}
          className="option-logopolygon"
        />
        <div className="option-texts">
          <span className="option-text1">{props.label}</span>
          <span className="option-text2">{props.text}</span>
        </div>
      </div>
      <input
        type="radio"
        name={props.radioName}
        value={props.radioValue}
        className="option-radiobutton"
      />
    </div>
  )
}

Option.defaultProps = {
  label: 'Polygon',
  dataOption: '',
  logo: '/external/logopolygon1318-449p.svg',
  dataStep: '',
  text: 'Fast and cheap transactions with a longer text spanning out',
  radioValue: 'value',
  radioName: 'radio',
}

Option.propTypes = {
  label: PropTypes.string,
  dataOption: PropTypes.string,
  logo: PropTypes.string,
  dataStep: PropTypes.string,
  text: PropTypes.string,
  radioValue: PropTypes.string,
  radioName: PropTypes.string,
}

export default Option
