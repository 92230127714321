import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import ContentTitleProduct from '../components/content-title-product'
import CardVideoBackground from '../components/card-video-background'
import CardScheme from '../components/card-scheme'
import Footer from '../components/footer'
import './indexer.css'

const Indexer = (props) => {
  return (
    <div className="indexer-container1">
      <Helmet>
        <title>Gateway.fm | Blockchain Data Indexing Solutions</title>
        <meta
          name="description"
          content="Explore Gateway.fm's versatile, microservices-based Blockchain Indexer. Efficiently process and access real-time blockchain event data across diverse blockchain infrastructures with ease."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Blockchain Data Indexing Solutions"
        />
        <meta
          property="og:description"
          content="Explore Gateway.fm's versatile, microservices-based Blockchain Indexer. Efficiently process and access real-time blockchain event data across diverse blockchain infrastructures with ease."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/f23c9241-d35f-4a08-add9-917bda2f5f95?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name44"></Navigation>
      <div className="indexer-hero">
        <div className="indexer-frame1171275211">
          <span className="indexer-text1">
            <span className="indexer-text2">GATEWAY</span>
            <br></br>
            <span>INDEXER</span>
            <br></br>
          </span>
          <img
            alt="cubebitmin17156"
            src="/external/services/indexer-promo-1500w.png"
            className="indexer-cubebitmin1"
          />
          <div className="indexer-frame482057">
            <span className="indexer-text6">
              A versatile, microservices-based Blockchain Indexer that
              efficiently processes and provides real-time access to blockchain
              event data across various blockchain infrastructures.
            </span>
          </div>
        </div>
      </div>
      <div className="indexer-container2">
        <ContentTitleProduct text="Key features"></ContentTitleProduct>
        <div className="indexer-container3">
          <CardVideoBackground
            text="Highly specialized tool for EVM-compatible blockchain ecosystems"
            label="EVM Smart Contract Event Indexing"
            video="/external/services/indexer-1.mp4"
            poster="/external/services/poster-speed-1500w.png"
            videoSrc="/external/services/back-1.mp4"
          ></CardVideoBackground>
          <CardVideoBackground
            text="Various database configurations optimized for high-write or high-read scenarios, ensuring optimal performance and scalability"
            label="Database Flexibility"
            video="/external/services/indexer-2.mp4"
            poster="/external/services/poster-curves-1500w.png"
            videoSrc="/external/services/curves-1.mp4"
            videoPoster="/external/services/poster-curves-1500w.png"
          ></CardVideoBackground>
        </div>
        <div className="indexer-container4">
          <CardVideoBackground
            text="Subscribes to the daemon’s data feeds and efficiently stores the required data in smart contracts for data accuracy and timeliness"
            label="Swagger-Based API"
            video="/external/services/indexer-3.mp4"
            poster="/external/services/poster-stripes-1500w.png"
            videoSrc="/external/services/stripes-1.mp4"
            videoPoster="/external/services/poster-stripes-1500w.png"
          ></CardVideoBackground>
          <CardVideoBackground
            text="For real-time data streaming, ideal for applications that require live updates and interactions"
            label="WebSocket JSON-RPC API"
            video="/external/services/indexer-4.mp4"
            poster="/external/services/poster-waves-1500w.png"
            videoSrc="/external/services/waves-1.mp4"
            videoPoster="/external/services/poster-waves-1500w.png"
          ></CardVideoBackground>
        </div>
        <ContentTitleProduct text="How it works"></ContentTitleProduct>
        <div className="indexer-scheme">
          <CardScheme
            tag1="Database flexibility"
            tag2="Scalability"
            text="Supports various database configurations tailored for scenarios requiring high-volume writing or high-performance reading, accommodating the indexing and querying needs of EVM-compatible blockchain events. Designed to efficiently manage expanding data demands, the backend service ensures quick access and analysis of blockchain event data."
            label="Core Service with Database"
          ></CardScheme>
          <div className="indexer-frame11712752841">
            <img
              alt="Ellipse58627"
              src="/external/ellipse58627-bema-200w.png"
              className="indexer-ellipse51"
            />
            <img
              alt="Ellipse68627"
              src="/external/ellipse68627-fkfr-200w.png"
              className="indexer-ellipse61"
            />
          </div>
          <CardScheme
            tag1="Real-time monitoring"
            tag2="Filtering"
            text="Monitors blockchain transactions for specific smart contract events in real time, capturing them for indexing. It employs advanced filtering options to selectively index events based on predefined criteria, enhancing data relevance and collection efficiency."
            label="Events-Listener"
          ></CardScheme>
          <div className="indexer-frame11712752842">
            <img
              alt="Ellipse58627"
              src="/external/ellipse58627-bema-200w.png"
              className="indexer-ellipse52"
            />
            <img
              alt="Ellipse68627"
              src="/external/ellipse68627-fkfr-200w.png"
              className="indexer-ellipse62"
            />
          </div>
          <CardScheme
            tag1="Live updates"
            tag2="Streaming"
            text="Offers a WebSocket JSON-RPC API for real-time streaming of indexed event data to client applications, ideal for applications requiring live updates and interactions."
            label="WebSocket API"
          ></CardScheme>
          <div className="indexer-frame11712752843">
            <img
              alt="Ellipse58627"
              src="/external/ellipse58627-bema-200w.png"
              className="indexer-ellipse53"
            />
            <img
              alt="Ellipse68627"
              src="/external/ellipse68627-fkfr-200w.png"
              className="indexer-ellipse63"
            />
          </div>
          <CardScheme
            tag1="Easy access"
            tag2="Integration"
            text="Features an HTTP API, documented with Swagger for straightforward integration, allowing developers to easily query indexed events and retrieve relevant data."
            label="HTTP API"
          ></CardScheme>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name43"></Footer>
    </div>
  )
}

export default Indexer
