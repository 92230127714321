import React from 'react'

import PropTypes from 'prop-types'

import './card-promo.css'

const CardPromo = (props) => {
  return (
    <div className="card-promo-container1">
      <div className="card-promo-content">
        <span className="card-promo-text1">{props.label}</span>
        <div className="card-promo-container2">
          <span className="card-promo-text2">{props.description}</span>
          <img alt="image" src={props.imageSrc} className="card-promo-image" />
        </div>
      </div>
    </div>
  )
}

CardPromo.defaultProps = {
  imageSrc: '/external/chair8023-g2gh-1200w.png',
  description:
    'The Nodes Sale mechanic is designed to facilitate the structured purchase of nodes through a dedicated application.',
  label: 'Structured purchase of nodes',
}

CardPromo.propTypes = {
  imageSrc: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
}

export default CardPromo
