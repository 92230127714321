import React from 'react'

import PropTypes from 'prop-types'

import './content-paragraph.css'

const ContentParagraph = (props) => {
  return (
    <div className={`content-paragraph-container ${props.rootClassName} `}>
      <span className="content-paragraph-text">{props.text}</span>
    </div>
  )
}

ContentParagraph.defaultProps = {
  rootClassName: '',
  text: 'The DAO Blueprint is designed to streamline the creation of Decentralized Autonomous Organizations, offering a democratic, transparent, and efficient approach to collective governance. By leveraging smart contracts, it sets up a framework for shared decision-making, resource management, and community engagement, all within a secure blockchain framework. This blueprint simplifies the intricacies of DAO operations, making decentralized governance more accessible and practical for a wide range of projects and communities.',
}

ContentParagraph.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default ContentParagraph
