import React from 'react'

import PropTypes from 'prop-types'

import './card-product-highlight.css'

const CardProductHighlight = (props) => {
  return (
    <div className="card-product-highlight-container1">
      <div className="card-product-highlight-container2">
        <div className="card-product-highlight-texts">
          <span className="card-product-highlight-text1">{props.main}</span>
          <span className="card-product-highlight-text2">
            {props.supportive}
          </span>
        </div>
      </div>
    </div>
  )
}

CardProductHighlight.defaultProps = {
  main: 'Democratic governance',
  supportive: 'We enable a more democratic governance model',
}

CardProductHighlight.propTypes = {
  main: PropTypes.string,
  supportive: PropTypes.string,
}

export default CardProductHighlight
