import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import ContentTitleProduct from '../components/content-title-product'
import Footer from '../components/footer'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container1">
      <Helmet>
        <title>Gateway.fm | Pioneering Web3 Infrastructure</title>
        <meta
          name="description"
          content="Empowering builders and organizations, Gateway.fm pioneers Web3 infrastructure to harness the full potential of blockchain technology. Discover our innovative solutions today."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Pioneering Web3 Infrastructure"
        />
        <meta
          property="og:description"
          content="Empowering builders and organizations, Gateway.fm pioneers Web3 infrastructure to harness the full potential of blockchain technology. Discover our innovative solutions today."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/b79d92b0-1185-4098-8bbc-b54deef4e792?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name1"></Navigation>
      <div className="about-hero">
        <div className="about-frame1171275211">
          <span className="about-text10">BLOCKCHAIN. BACK TO BASICS</span>
          <img
            alt="cubebitmin17156"
            src="/external/cubebitmin17156-8on-1500w.png"
            className="about-cubebitmin1"
          />
          <div className="about-frame482057">
            <span className="about-text11">
              We pioneer Web3 infrastructure to empower builders and
              organisations alike to harvest paramount utilities of blockchain
              technology
            </span>
          </div>
        </div>
      </div>
      <div className="about-legs">
        <div className="about-row11">
          <animate-on-reveal animation="fadeIn">
            <div data-thq-animate-on-reveal="true" className="about-presto1">
              <div className="about-frame1171275204">
                <div className="about-group481543">
                  <div className="about-presto2">
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-at6.svg"
                      className="about-vector10"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-56r.svg"
                      className="about-vector11"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-dlq.svg"
                      className="about-vector12"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-0nd.svg"
                      className="about-vector13"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-2aeh.svg"
                      className="about-vector14"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-uos9.svg"
                      className="about-vector15"
                    />
                  </div>
                  <div className="about-poweredby-gatewayfm1">
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-g1cm.svg"
                      className="about-vector16"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-wir.svg"
                      className="about-vector17"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-l98.svg"
                      className="about-vector18"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-4bz.svg"
                      className="about-vector19"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-c7oj.svg"
                      className="about-vector20"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-or9q.svg"
                      className="about-vector21"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-65hi.svg"
                      className="about-vector22"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-itx8.svg"
                      className="about-vector23"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-q97s.svg"
                      className="about-vector24"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-d5jf.svg"
                      className="about-vector25"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-5uog.svg"
                      className="about-vector26"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-hmw8.svg"
                      className="about-vector27"
                    />
                  </div>
                  <div className="about-group11">
                    <img
                      alt="Rectangle327146"
                      src="/external/rectangle327146-1mrt.svg"
                      className="about-rectangle32"
                    />
                    <img
                      alt="Rectangle317146"
                      src="/external/rectangle317146-mgga.svg"
                      className="about-rectangle31"
                    />
                    <img
                      alt="Rectangle307146"
                      src="/external/rectangle307146-qjpn.svg"
                      className="about-rectangle30"
                    />
                  </div>
                </div>
              </div>
              <div className="about-frame11712752031">
                <span className="about-text12">
                  Deploy your blockchain in minutes or select a pre-compiled
                  blueprint
                </span>
                <span className="about-text13">PLATFORM</span>
              </div>
            </div>
          </animate-on-reveal>
          <animate-on-reveal animation="fadeIn">
            <div data-thq-animate-on-reveal="true" className="about-stakeway1">
              <div className="about-frame11712752051">
                <div className="about-group481545">
                  <img
                    alt="Vector1797146"
                    src="/external/vector1797146-9gef.svg"
                    className="about-vector179"
                  />
                  <img
                    alt="Vector7146"
                    src="/external/vector7146-5wy.svg"
                    className="about-vector28"
                  />
                  <img
                    alt="STAKEWAY7146"
                    src="/external/stakeway7146-gvdl.svg"
                    className="about-stakeway2"
                  />
                  <div className="about-poweredby-gatewayfm2">
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-7ms7.svg"
                      className="about-vector29"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-rhp.svg"
                      className="about-vector30"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-qos.svg"
                      className="about-vector31"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-t77.svg"
                      className="about-vector32"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-etb.svg"
                      className="about-vector33"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-tq8g.svg"
                      className="about-vector34"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-dbk.svg"
                      className="about-vector35"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-hf9m.svg"
                      className="about-vector36"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-609n.svg"
                      className="about-vector37"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-wtsc.svg"
                      className="about-vector38"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-0e9l.svg"
                      className="about-vector39"
                    />
                    <img
                      alt="Vector7146"
                      src="/external/vector7146-ler.svg"
                      className="about-vector40"
                    />
                  </div>
                </div>
              </div>
              <div className="about-frame11712752032">
                <span className="about-text14">
                  Secure, tailored, unslashable infrastructure for institutional
                  grade staking
                </span>
                <span className="about-text15">STAKING</span>
              </div>
            </div>
          </animate-on-reveal>
        </div>
        <div className="about-row21">
          <animate-on-reveal animation="fadeIn" duration="500ms">
            <div data-thq-animate-on-reveal="true" className="about-gateway">
              <div className="about-frame11712752052">
                <img
                  alt="gatewaylogo7146"
                  src="/external/gatewaylogo7146-eei7-200h.png"
                  className="about-gatewaylogo"
                />
              </div>
              <div className="about-frame11712752033">
                <span className="about-text16">
                  We build unique solutions that pave path for the rest of the
                  industry
                </span>
                <span className="about-text17">R&amp;D</span>
              </div>
            </div>
          </animate-on-reveal>
          <div className="about-description">
            <div className="about-frame11712752034">
              <span className="about-text18">
                We pioneer secure and scalable blockchain infrastructure,
                harnessing zero-knowledge cryptography and AI to unlock the
                potential of privacy-preserving decentralized applications
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="about-execs">
        <img
          alt="IMG657017146"
          src="/external/img657017146-61s8.png"
          className="about-img65701"
        />
        <div className="about-container2">
          <div className="about-ceo">
            <div className="about-name1">
              <span className="about-text19">
                Cuautemoc Weber
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <div className="about-links1">
                <a
                  href="https://x.com/oibot70"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link1"
                >
                  <img
                    alt="TwitterX7146"
                    src="/external/twitterx7146-1e5hs.svg"
                    className="about-twitter-x1"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/cuautemoc-weber/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link2"
                >
                  <img
                    alt="LinkedIn7146"
                    src="/external/linkedin7146-5l9.svg"
                    className="about-linked-in1"
                  />
                </a>
              </div>
            </div>
            <span className="about-text20">CEO // Co-Founder</span>
            <span className="about-text21">
              Seasoned tech executive with over 20 years of experience, leading
              teams across the globe. His responsibilities have spanned from
              deep telco infrastructure to browsers and mobile internet,
              eventually getting deep into Fintech across emerging markets and
              Crypto since 2017.
            </span>
          </div>
          <div className="about-cto">
            <div className="about-name2">
              <span className="about-text22">Igor Mandrigin</span>
              <div className="about-links2">
                <a
                  href="https://x.com/mandrigin"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link3"
                >
                  <img
                    alt="TwitterX7146"
                    src="/external/twitterx7146-2jb9.svg"
                    className="about-twitter-x2"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/mandrigin/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link4"
                >
                  <img
                    alt="LinkedIn7146"
                    src="/external/linkedin7146-ueul.svg"
                    className="about-linked-in2"
                  />
                </a>
              </div>
            </div>
            <span className="about-text23">CTO // Co-Founder</span>
            <span className="about-text24">
              Igor is an experienced builder with extensive experience in web2
              and web3. He has over 14 years of experience in tech, focusing on
              large-scale infrastructure and blockchain projects. He has led
              teams and done research for the Ethereum Foundation (Erigon),
              Status, StarkWare and other blockchain projects since 2017.
            </span>
          </div>
          <div className="about-cco">
            <div className="about-name3">
              <span className="about-text25">Pradeep Singh</span>
              <div className="about-links3">
                <a
                  href="https://x.com/pradeep_rvsingh"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link5"
                >
                  <img
                    alt="TwitterX7146"
                    src="/external/twitterx7146-fpu6.svg"
                    className="about-twitter-x3"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/pradeep-singh-4b5b7510/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link6"
                >
                  <img
                    alt="LinkedIn7146"
                    src="/external/linkedin7146-zyt.svg"
                    className="about-linked-in3"
                  />
                </a>
              </div>
            </div>
            <span className="about-text26">CCO // Co-Founder</span>
            <span className="about-text27">
              Pradeep brings over 20 years of experience spanning blockchain
              infrastructure development, institutional trading, and digital
              asset management. He combines technical prowess with financial
              acumen. His unique cross-domain expertise perfectly positions him
              to drive our Web3 initiatives forward.
            </span>
          </div>
        </div>
      </div>
      <div className="about-values">
        <ContentTitleProduct text="Our values"></ContentTitleProduct>
        <div className="about-row12">
          <div className="about-value1">
            <span className="about-text28">EXCELLENCE</span>
            <span className="about-text29">
              We have a 100% commitment to making great things
            </span>
          </div>
          <div className="about-value2">
            <span className="about-text30">INTEGRITY</span>
            <span className="about-text31">
              We keep our commitments to ourselves, our customers and community
            </span>
          </div>
        </div>
        <div className="about-row22">
          <div className="about-value3">
            <span className="about-text32">HUMILITY</span>
            <span className="about-text33">
              We are always ready to listen, learn and improve
            </span>
          </div>
          <div className="about-value4">
            <span className="about-text34">INNOVATION</span>
            <span className="about-text35">
              We grind through the most actual problems in the industry to pave
              the path for others
            </span>
          </div>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name1"></Footer>
    </div>
  )
}

export default About
