import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import SubtitleProductHero from '../components/subtitle-product-hero'
import Footer from '../components/footer'
import './page.css'

const Page = (props) => {
  return (
    <div className="page-container">
      <Helmet>
        <title>
          Page - Gateway.fm :: Blockchain infrastructure for your Web3 project
        </title>
        <meta
          property="og:title"
          content="Page - Gateway.fm :: Blockchain infrastructure for your Web3 project"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name31"></Navigation>
      <div className="page-content">
        <div className="page-hero">
          <div className="page-description">
            <TitleProductHero text="Nothing could be found here"></TitleProductHero>
            <SubtitleProductHero text="You encountered a 404 page"></SubtitleProductHero>
          </div>
          <img
            alt="image"
            src="/external/404-1200w.png"
            className="page-image"
          />
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name30"></Footer>
    </div>
  )
}

export default Page
