import React from 'react'

import PropTypes from 'prop-types'

import './content-number.css'

const ContentNumber = (props) => {
  return (
    <div className="content-number-container1">
      <div className="content-number-container2">
        <span className="content-number-text1">{props.number}</span>
        <div className="content-number-subtext">
          <span className="content-number-text2">{props.text}</span>
        </div>
      </div>
    </div>
  )
}

ContentNumber.defaultProps = {
  number: '2’432',
  text: 'Rollups deployed',
}

ContentNumber.propTypes = {
  number: PropTypes.string,
  text: PropTypes.string,
}

export default ContentNumber
