import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import USPCheckmark from '../components/usp-checkmark'
import SubtitleProductHero from '../components/subtitle-product-hero'
import Footer from '../components/footer'
import './thank-you.css'

const ThankYou = (props) => {
  return (
    <div className="thank-you-container1">
      <Helmet>
        <title>
          Thank-You - Gateway.fm :: Blockchain infrastructure for your Web3
          project
        </title>
        <meta
          property="og:title"
          content="Thank-You - Gateway.fm :: Blockchain infrastructure for your Web3 project"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name30"></Navigation>
      <div className="thank-you-content">
        <div className="thank-you-hero">
          <div className="thank-you-description">
            <div className="thank-you-container2">
              <span className="thank-you-text1">THANK YOU!</span>
            </div>
            <div className="thank-you-container3">
              <USPCheckmark text="No obligations"></USPCheckmark>
              <USPCheckmark text="We help filling the blanks"></USPCheckmark>
              <USPCheckmark text="Courteous experts"></USPCheckmark>
            </div>
            <SubtitleProductHero text="We've received your request"></SubtitleProductHero>
          </div>
          <form
            id="multiStepForm"
            action="https://getform.io/f/lbjknova"
            method="POST"
            className="thank-you-form"
          >
            <div className="thank-you-step5">
              <div className="thank-you-frame482128">
                <div className="thank-you-frame482129">
                  <img
                    alt="Ellipse41319"
                    src="/external/ellipse41319-y33s-200w.png"
                    className="thank-you-ellipse4"
                  />
                  <img
                    alt="SVG24iconcheckmark1319"
                    src="/external/svg24iconcheckmark1319-kxo.svg"
                    className="thank-you-svg24iconcheckmark"
                  />
                </div>
                <span className="thank-you-text2">
                  <span>You’re on the verge</span>
                  <br></br>
                  <span>of something big</span>
                </span>
                <span className="thank-you-text6">
                  Our team of best-in-class engineers will reach you out in 2-3
                  business days
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name29"></Footer>
    </div>
  )
}

export default ThankYou
