import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import Tag from '../components/tag'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ImageProductHero from '../components/image-product-hero'
import ContentTitleProduct from '../components/content-title-product'
import CardProductHighlight from '../components/card-product-highlight'
import ContentParagraph from '../components/content-paragraph'
import ContentHighlight from '../components/content-highlight'
import ContentSmallTitle from '../components/content-small-title'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import AnnouncementSmall from '../components/announcement-small'
import Testimonial from '../components/testimonial'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './web3-infrastructure-blueprint-digital-banking.css'

const Web3InfrastructureBlueprintDigitalBanking = (props) => {
  return (
    <div className="web3-infrastructure-blueprint-digital-banking-container1">
      <Helmet>
        <title>
          Gateway.fm | Web3 Infrastructure Blueprint: Digital Banking
        </title>
        <meta
          name="description"
          content="Discover Gateway.fm's blueprint for Web3 digital banking, leveraging blockchain technology for secure, transparent, and efficient financial services."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Web3 Infrastructure Blueprint: Digital Banking"
        />
        <meta
          property="og:description"
          content="Discover Gateway.fm's blueprint for Web3 digital banking, leveraging blockchain technology for secure, transparent, and efficient financial services."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/43feee0c-f65b-4398-9dce-ea7d10223110?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name8"></Navigation>
      <div className="web3-infrastructure-blueprint-digital-banking-content">
        <div className="web3-infrastructure-blueprint-digital-banking-hero">
          <div className="web3-infrastructure-blueprint-digital-banking-description">
            <TitleProductHero text="DIGITAL BANKING"></TitleProductHero>
            <Tag rootClassName="tagroot-class-name2"></Tag>
            <SubtitleProductHero text="Launch a payment system and forget infrastructure hurdles"></SubtitleProductHero>
            <USPCheckmark text="Lightning fast"></USPCheckmark>
            <USPCheckmark text="Cheap transactions"></USPCheckmark>
            <USPCheckmark text="PwC audited. KYC/KYB"></USPCheckmark>
            <Link
              to="/contact-form"
              className="web3-infrastructure-blueprint-digital-banking-navlink1"
            >
              <ButtonPrimarySubtitle className="web3-infrastructure-blueprint-digital-banking-component17"></ButtonPrimarySubtitle>
            </Link>
          </div>
          <ImageProductHero image="/external/digital-banking-500h.png"></ImageProductHero>
        </div>
        <div className="web3-infrastructure-blueprint-digital-banking-container2">
          <ContentTitleProduct></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-digital-banking-cards">
            <CardProductHighlight
              main="Reducing Costs"
              supportive="Gasless L2 rollups make high L1 fees a thing of the past"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Safer Transactions"
              supportive="Extensively audited open-source bridge for pre-vetted assets"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Secure funds"
              supportive="Account abstraction to transition towards non-custodial wallets"
            ></CardProductHighlight>
          </div>
          <ContentTitleProduct text="Summary"></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-digital-banking-container3">
            <span className="web3-infrastructure-blueprint-digital-banking-text10">
              Our innovative payment solution provides a fast, secure and
              low-fee decentralized payment system. By integrating advanced card
              management capabilities through account abstraction technology, we
              bridge funds from L1 networks, enabling regular payments via
              specially designed cards. This system simplifies the user
              experience, allowing direct interaction with DeFi applications and
              on-chain payment services without the typical blockchain
              complexities.
            </span>
          </div>
          <ContentTitleProduct text="What it Solves"></ContentTitleProduct>
          <ContentParagraph text="Current blockchain transactions suffer from slow processing times due to L1 network capacity constraints, affecting user experience and scalability. By utilizing L2 rollups, we can process transactions much faster on L2 and then aggregate these transactions into batches. Only the final state is recorded on L1 through a single proof for the entire batch, significantly enhancing the speed and efficiency of on-chain transactions."></ContentParagraph>
          <ContentTitleProduct text="Reducing costs"></ContentTitleProduct>
          <ContentHighlight text="High fees on L1 networks are a significant barrier to the adoption of blockchain technology for regular and microtransactions."></ContentHighlight>
          <ContentSmallTitle rootClassName="content-small-titleroot-class-name6"></ContentSmallTitle>
          <ContentParagraph text="By deploying gasless L2 rollups, transactions on L2 will be free for users, with the system absorbing costs for batched L1 proofs. This approach minimizes transaction fees by spreading the cost of L1 submissions over numerous transactions, thereby reducing the financial barrier for users."></ContentParagraph>
          <ContentTitleProduct text="Safer Transactions"></ContentTitleProduct>
          <ContentHighlight text="Bridges between L1 and L2 networks are critical yet vulnerable points in blockchain ecosystems. A security breach in these bridges can lead to substantial asset losses, severely undermining trust and integrity."></ContentHighlight>
          <ContentSmallTitle rootClassName="content-small-titleroot-class-name7"></ContentSmallTitle>
          <ContentParagraph text="Our strategy focuses on safeguarding fund transfers by selectively permitting only pre-vetted assets for bridging and employing an extensively audited, open-source bridge. This minimizes exposure to vulnerabilities and ensures any potential weaknesses are identified and addressed, enhancing overall security and reliability."></ContentParagraph>
          <ContentTitleProduct text="Secure Funds"></ContentTitleProduct>
          <ContentHighlight text="Centralized asset control in custodial wallets poses significant security risks and detracts from the decentralized, user-empowered ethos of blockchain technology."></ContentHighlight>
          <ContentSmallTitle rootClassName="content-small-titleroot-class-name8"></ContentSmallTitle>
          <ContentParagraph text="Our solution implements account abstraction to transition towards non-custodial wallets, placing control of assets firmly in the hands of users. Account abstraction streamlines interaction with blockchain networks, allowing users to customize security settings and control transactions directly, enhancing user autonomy and security."></ContentParagraph>
          <ContentTitleProduct text="Easy onboarding"></ContentTitleProduct>
          <ContentParagraph text="Blockchain technology, while powerful, often presents a steep learning curve due to its complexity and the unfamiliarity of concepts such as public and private keys to start with. This complexity can deter easy mainstream adoption, as potential users may find the technology intimidating and difficult to use for everyday transactions."></ContentParagraph>
          <ContentParagraph text="We address this issue by integrating account abstraction with a high-level wrapper designed to streamline user interactions. This combination simplifies the user interface, making blockchain transactions feel as familiar and straightforward as traditional online banking."></ContentParagraph>
          <ContentParagraph text="Account abstraction serves to hide the underlying technical complexities from the user, while our high-level wrapper provides a seamless and intuitive interface for managing funds, executing transactions and using blockchain-based cards."></ContentParagraph>
          <ContentTitleProduct text="Scalable"></ContentTitleProduct>
          <ContentParagraph text="Blockchain networks face challenges in scaling to meet high demand, leading to congestion and slow transaction times."></ContentParagraph>
          <ContentParagraph text="Our solution deploys a top-level transaction balancer that directs transactions across several secure, isolated L2 chains. This load balancing ensures fast and reliable transaction processing even during peak periods, addressing scalability issues effectively."></ContentParagraph>
          <ContentTitleProduct text="Components"></ContentTitleProduct>
          <ContentParagraph text="Our solution deploys a top-level transaction balancer that directs transactions across several secure, isolated L2 chains. This load balancing ensures fast and reliable transaction processing even during peak periods, addressing scalability issues effectively."></ContentParagraph>
          <ContentSmallTitle
            text="GASLESS L2 ROLLUPS"
            rootClassName="content-small-titleroot-class-name10"
          ></ContentSmallTitle>
          <ContentParagraph text="At the core of our architecture is a gasless L2 rollup network designed to optimize transaction processing. By grouping and batching transactions for L1 proofs, it significantly enhances transaction speed and reduces costs."></ContentParagraph>
          <div className="web3-infrastructure-blueprint-digital-banking-container4">
            <span className="web3-infrastructure-blueprint-digital-banking-text11">
              <span>Options:</span>
              <br></br>
              <span>
                1. Polygon-CDK Rollup: A scalable solution from Polygon.
              </span>
              <br></br>
              <span>
                2. Optimism Rollup: Focuses on optimistic rollups for scaling.
              </span>
              <br></br>
            </span>
          </div>
          <ContentSmallTitle
            text="ORACLES"
            rootClassName="content-small-titleroot-class-name11"
          ></ContentSmallTitle>
          <ContentParagraph text="Oracles are vital in the Gasless L2 Rollup architecture, providing external data to the system. Oracle Services (OB) interface with external data sources and integrate this data into the Gasless L2 Rollup. They connect to both Management Wrapper Contracts (MW) and Oracle Contracts (OC), enabling the system to make informed decisions and operate based on updated real-world information."></ContentParagraph>
          <ContentSmallTitle
            text="ORACLE CONTRACTS (OC)"
            rootClassName="content-small-titleroot-class-name12"
          ></ContentSmallTitle>
          <ContentParagraph text="The primary role of the Oracle Contracts is to act as a bridge between the Gasless L2 Rollup system and external data sources. They connect directly to Management Wrapper Contracts (MW). They fetch, verify and supply external data - ensuring the Gasless L2 Rollup has accurate and updated information. This connection supports informed decision-making, transaction validation and overall system functionality."></ContentParagraph>
          <ContentSmallTitle
            text="THE MANAGEMENT WRAPPER CONTRACTS (MW)"
            rootClassName="content-small-titleroot-class-name13"
          ></ContentSmallTitle>
          <ContentParagraph text="The primary role of the Management Wrapper Contracts is to provide a layer of abstraction and management for the Gasless L2 Rollup system. Management Wrapper Contracts connect Oracle Contracts (OC) and Account Abstractions (AA). They play a crucial role in ensuring the smooth operation and efficiency of the Gasless L2 Rollup system by managing the flow of information and transactions between various parts of the architecture. This ensures the Gasless L2 Rollup system operates smoothly and efficiently."></ContentParagraph>
          <ContentSmallTitle
            text="ACCOUNT ABSTRACTION (AA)"
            rootClassName="content-small-titleroot-class-name9"
          ></ContentSmallTitle>
          <ContentParagraph text="The primary role of the Account Abstractions is to abstract the details of user accounts and transactions - providing a simplified interface for interacting with the Gasless L2 Rollup system. Account Abstractions connect to Management Wrapper Contracts (MW) and the Application (Fr). They facilitate the seamless integration of user accounts and transactions with other components of the Gasless L2 Rollup architecture. They help in maintaining the integrity and security of user data and transactions while ensuring smooth communication between different parts of the system."></ContentParagraph>
          <div className="web3-infrastructure-blueprint-digital-banking-container5">
            <span className="web3-infrastructure-blueprint-digital-banking-text18">
              <span>Options:</span>
              <br></br>
              <span>
                1. Gnosis Safe: Creates secure, smart contract-based wallets;
              </span>
              <br></br>
              <span>2. Presto-AA: Tailored for the Presto ecosystem;</span>
              <br></br>
              <span>
                3. Other Solutions: Evaluated for isolated fund management and
                user-friendly transactions.
              </span>
              <br></br>
            </span>
          </div>
          <ContentSmallTitle
            text="L1 Network"
            rootClassName="content-small-titleroot-class-name14"
          ></ContentSmallTitle>
          <ContentParagraph text="The L1 network selection is foundational, directly influencing transaction costs, ecosystem richness and overall project scalability."></ContentParagraph>
          <div className="web3-infrastructure-blueprint-digital-banking-container6">
            <span className="web3-infrastructure-blueprint-digital-banking-text27">
              <span>Options:</span>
              <br></br>
              <span>
                1. Ethereum: Offers a vast ecosystem with extensive development
                tools, ideal for leveraging network effects and ensuring robust
                security. Its broader adoption comes with higher transaction
                fees.
              </span>
              <br></br>
              <span>
                2. Gnosis: Stands out for its lower transaction fees, making it
                a cost-effective choice for projects aiming to minimize
                operational costs.
              </span>
              <br></br>
            </span>
          </div>
          <ContentSmallTitle
            text="Bridge"
            rootClassName="content-small-titleroot-class-name16"
          ></ContentSmallTitle>
          <ContentParagraph text="The Bridge component is a crucial part of our architecture, enabling the transfer of funds from L1 to L2 networks. It's specifically designed to harness the benefits of L2 scalability while maintaining the foundational security of L1. A key feature of this component is its allowlist, which restricts transfers to approved tokens only, ensuring the ecosystem's integrity and security."></ContentParagraph>
          <ContentSmallTitle
            text="Block Explorer"
            rootClassName="content-small-titleroot-class-name15"
          ></ContentSmallTitle>
          <ContentParagraph text="Block Explorers are essential tools in our architecture, enabling users to access detailed information about transactions, smart contracts and blockchain states in real-time."></ContentParagraph>
          <div className="web3-infrastructure-blueprint-digital-banking-container7">
            <span className="web3-infrastructure-blueprint-digital-banking-text34">
              <span>Options:</span>
              <br></br>
              <span>
                1. Blockscout: An open-source explorer designed for detailed
                viewing of blockchain activities across various networks.
              </span>
              <br></br>
              <span>
                2. Dora:** A versatile blockchain explorer that provides robust
                data analysis tools, transaction tracking and smart contract
                interaction insights.
              </span>
            </span>
          </div>
          <ContentSmallTitle
            text="Price Oracles"
            rootClassName="content-small-titleroot-class-name17"
          ></ContentSmallTitle>
          <ContentParagraph text="Price Oracles play a critical role in blockchain architectures by providing reliable, real-time price data from external markets to the blockchain."></ContentParagraph>
          <span className="web3-infrastructure-blueprint-digital-banking-text40">
            <span>Options:</span>
            <br></br>
            <span>
              1. API3: Offers decentralized APIs by directly connecting smart
              contracts with real-world data sources, reducing reliance on
              intermediary services and enhancing data reliability.
            </span>
            <br></br>
            <span>
              2. Redstone:** Provides highly customizable and efficient data
              feeds, focusing on speed and scalability. With features that allow
              for specific data tailoring for blockchain applications.
            </span>
            <br></br>
            <span>
              3. Presto-Oracle:** A specialized Oracle developed for the Presto
              ecosystem, it&apos;s optimized for integration within the system,
              ensuring seamless data flow and application performance.
            </span>
          </span>
          <ContentSmallTitle
            text="Indexers &amp; API"
            rootClassName="content-small-titleroot-class-name18"
          ></ContentSmallTitle>
          <ContentParagraph text="Indexers and APIs are essential for querying and accessing blockchain data efficiently. They play a pivotal role in enhancing the performance and user experience of blockchain applications by providing structured and quick access to on-chain information."></ContentParagraph>
          <div className="web3-infrastructure-blueprint-digital-banking-container8">
            <span className="web3-infrastructure-blueprint-digital-banking-text48">
              <span>Options:</span>
              <br></br>
              <span>
                1. Presto-Indexer: Tailored specifically for the Presto
                ecosystem, this indexer is optimized for speed and accuracy,
                ensuring that applications can quickly access the data they
                need.
              </span>
              <br></br>
              <span>
                2. The Graph: A decentralized protocol for indexing and querying
                data from blockchains, The Graph supports a wide range of
                blockchains and is known for its robustness and flexibility.
              </span>
              <br></br>
              <span>
                3. Goldsky: Specializes in providing dynamic and scalable
                indexing solutions. Goldsky offers tailored data services that
                cater to the specific needs of diverse blockchain applications.
              </span>
            </span>
          </div>
          <ContentSmallTitle
            text="Cards Integrator"
            rootClassName="content-small-titleroot-class-name19"
          ></ContentSmallTitle>
          <ContentParagraph text="The Cards Integrator component serves as a bridge between blockchain-based transactions and traditional payment systems, enabling users to utilize blockchain assets through familiar payment infrastructures like Visa or Mastercard."></ContentParagraph>
          <span className="web3-infrastructure-blueprint-digital-banking-text56">
            <span>Options:</span>
            <br></br>
            <span>
              Wirex: An example of a Cards Integrator, offers a platform that
              converts cryptocurrencies to traditional fiat currencies, bridging
              the gap between digital and traditional finance.
            </span>
          </span>
          <ContentSmallTitle
            text="KYC service"
            rootClassName="content-small-titleroot-class-name20"
          ></ContentSmallTitle>
          <ContentParagraph text="The KYC Service is a crucial component for ensuring regulatory compliance and enhancing the security of blockchain platforms. It verifies the identity of users, playing a vital role in preventing fraud, money laundering and other illicit activities."></ContentParagraph>
          <ContentSmallTitle
            text="Application"
            rootClassName="content-small-titleroot-class-name21"
          ></ContentSmallTitle>
          <ContentParagraph text="The Application component is the user-facing part of the blockchain architecture, providing the interface through which users interact with the blockchain system."></ContentParagraph>
          <span className="web3-infrastructure-blueprint-digital-banking-text60">
            <span>Options:</span>
            <br></br>
            <span>
              1. SPA Web App: A Single Page Application for web users, designed
              to offer a responsive and cohesive experience without the need for
              page reloads, making it ideal for dynamic interactions with the
              blockchain.
            </span>
            <br></br>
            <span>
              2. Mobile Application:** A dedicated app developed for mobile
              devices, available for both iOS and Android. It allows users to
              access blockchain functionalities on-the-go, providing convenience
              and accessibility.
            </span>
          </span>
        </div>
        <div className="web3-infrastructure-blueprint-digital-banking-container9">
          <SubtitleProductPromo></SubtitleProductPromo>
          <AnnouncementSmall
            text="We enabled Wirex to elevate its zk-powered network"
            rootClassName="announcement-smallroot-class-name9"
            className="web3-infrastructure-blueprint-digital-banking-component74"
          ></AnnouncementSmall>
          <div className="web3-infrastructure-blueprint-digital-banking-testimonials">
            <Testimonial
              name="Wirex"
              text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
              position="@wirexapp"
              rootClassName="testimonialroot-class-name66"
              logopolygonSrc="/external/vector1317-f6xm.svg"
            ></Testimonial>
            <Testimonial
              name="GPT Protocol"
              text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
              position="@gpt_protocol"
              rootClassName="testimonialroot-class-name67"
              logopolygonSrc="/external/logos/logo-gpt-200w.png"
            ></Testimonial>
            <Testimonial
              name="Dora"
              text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
              position="@SearchOnDora"
              rootClassName="testimonialroot-class-name68"
              logopolygonSrc="/external/logos/logo-dora1-200h.png"
            ></Testimonial>
          </div>
        </div>
        <Link
          to="/contact-form"
          className="web3-infrastructure-blueprint-digital-banking-navlink2"
        >
          <Floating
            label="Digital Banking"
            buttonLabel="Get started"
            description="Launch a payment system and forget infrastructure hurdles"
            buttonSubtext="Initiate this blueprint"
            rootClassName="floatingroot-class-name1"
            className="web3-infrastructure-blueprint-digital-banking-component78"
          ></Floating>
        </Link>
      </div>
      <Footer rootClassName="footerroot-class-name8"></Footer>
    </div>
  )
}

export default Web3InfrastructureBlueprintDigitalBanking
