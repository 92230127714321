import React from 'react'

import PropTypes from 'prop-types'

import './hero-career-description.css'

const HeroCareerDescription = (props) => {
  return (
    <div
      className={`hero-career-description-description ${props.rootClassName} `}
    >
      <div className="hero-career-description-container">
        <span className="hero-career-description-text1">{props.header}</span>
        <div className="hero-career-description-parameter1">
          <span className="hero-career-description-text2">Location</span>
          <span className="hero-career-description-text3">
            {props.location}
          </span>
        </div>
        <div className="hero-career-description-parameter2">
          <span className="hero-career-description-text4">Compensation</span>
          <span className="hero-career-description-text5">
            {props.compensation}
          </span>
        </div>
        <div className="hero-career-description-parameter3">
          <span className="hero-career-description-text6">Starting date</span>
          <span className="hero-career-description-text7">{props.text6}</span>
        </div>
      </div>
    </div>
  )
}

HeroCareerDescription.defaultProps = {
  header: 'Business DeveloPMENT MARKETING MANAGER',
  text6: 'As soon as you’re ready',
  compensation: 'Competitive',
  rootClassName: '',
  location: 'Remote',
}

HeroCareerDescription.propTypes = {
  header: PropTypes.string,
  text6: PropTypes.string,
  compensation: PropTypes.string,
  rootClassName: PropTypes.string,
  location: PropTypes.string,
}

export default HeroCareerDescription
