import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import HeroCareerDescription from '../../components/hero-career-description'
import FormUpload from '../../components/form-upload'
import Footer from '../../components/footer'
import './career-business-developer.css'

const CareerBusinessDeveloper = (props) => {
  return (
    <div className="career-business-developer-container1">
      <Helmet>
        <title>Gateway.fm Careers</title>
        <meta
          name="description"
          content="Explore career opportunities at Gateway.fm. Join our team and contribute to shaping the future of blockchain technology."
        />
        <meta property="og:title" content="Gateway.fm Careers" />
        <meta
          property="og:description"
          content="Explore career opportunities at Gateway.fm. Join our team and contribute to shaping the future of blockchain technology."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/4e096e98-a657-4bae-84e6-f514d14fa4fe?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name37"></Navigation>
      <div className="career-business-developer-container2">
        <div className="career-business-developer-career-hero">
          <HeroCareerDescription
            header="Business developer"
            rootClassName="hero-career-descriptionroot-class-name"
          ></HeroCareerDescription>
          <FormUpload rootClassName="form-uploadroot-class-name"></FormUpload>
        </div>
        <div className="career-business-developer-post">
          <div className="career-business-developer-text10">
            <div className="career-business-developer-container3">
              <span className="career-business-developer-text11">
                About Gateway.FM
              </span>
              <span className="career-business-developer-text12">
                <span>
                  Gateway.fm is a forward-thinking and dynamic team comprised of
                  seasoned professionals committed to pioneering the development
                  of the next generation of web3 infrastructure and tooling
                  products. With a strategic blend of expertise in blockchain,
                  fintech, information security, and telecommunications,
                  Gateway.fm stands at the forefront of innovation within the
                  rapidly evolving web3 space. The company&apos;s dedication to
                  excellence is evident in its relentless pursuit of
                  groundbreaking solutions. By leveraging their in-depth
                  knowledge across multiple domains, Gateway.fm aims to create
                  and deliver innovative products that not only meet the demands
                  of the current digital landscape but also position the company
                  as a leader within the dynamic and transformative web3
                  ecosystem.
                </span>
                <br></br>
                <br></br>
                <span>
                  At the core of Gateway.fm mission is a commitment to staying
                  ahead of industry trends and pushing the boundaries of
                  what&apos;s possible in web3 technology. This vision is
                  underpinned by a collaborative and driven team, whose
                  collective skills and passion drive the company towards
                  shaping the future of web3 infrastructure and tooling. In a
                  landscape characterized by rapid change, Gateway.fm remains
                  agile, adaptive, and poised to make significant contributions
                  to the ongoing evolution of the digital landscape.
                </span>
                <br></br>
              </span>
              <span className="career-business-developer-text18">
                Announcement
              </span>
              <span className="career-business-developer-text19">
                <span>
                  We are excited to announce an opportunity for Business
                  Development (Mid-Level). We&apos;re the team behind
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <Link to="/presto">Presto</Link>
                <span>
                  , a robust platform that empowers users to deploy private
                  zkEVM roll-ups in just a few minutes. If you&apos;re
                  passionate about blockchain technology and eager to contribute
                  to a dynamic, innovative team, we&apos;d love to hear from
                  you.
                </span>
              </span>
              <span className="career-business-developer-text22">
                Key responsibilities
              </span>
              <span className="career-business-developer-text23">
                <span>
                  •​​ Deal Closure: Take over deals from the outreach team and
                  drive them to closure;
                </span>
                <br></br>
                <span>
                  •​​ Client Engagement: Engage with potential clients,
                  understand their needs, and effectively communicate how Presto
                  (Platfrom to deploy L2 roll ups) can address their challenges;
                </span>
                <br></br>
                <span>
                  •​​ Proposal Development: Develop and present proposals
                  tailored to client requirements.
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Relationship Building: Build and maintain strong, long-lasting
                  client relationships, travel and attend events;
                </span>
                <br></br>
                <span>
                  •​​ Sales Strategy: Collaborate with the outreach and
                  marketing/bizdev teams to refine sales strategies and improve
                  conversion rates;
                </span>
                <br></br>
                <span>
                  •​​ Market Analysis: Conduct market research to identify new
                  business opportunities and understand industry trends;
                </span>
                <br></br>
                <span>•​​ </span>
                <span>
                  CRM Management: Maintain accurate records of all sales
                  activities and client interactions in the CRM system;
                </span>
                <br></br>
                <span>•​​ </span>
                <span>
                  Performance Tracking: Track and report on sales metrics and
                  KPIs to the management team.
                </span>
                <br></br>
              </span>
              <span className="career-business-developer-text43">
                Qualifications
              </span>
              <span className="career-business-developer-text44">
                <span>
                  •​​ Experience: 1-3 years of experience in business
                  development, with a proven track record in Web3 and/or Web2
                  B2B infra/cloud products;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Industry Knowledge: Strong understanding of infrastructure,
                  blockchain, ZK, and cloud services;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Sales Skills: Exceptional negotiation, communication, and
                  presentation skills (good english);
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Client Focused: Ability to understand client needs and
                  translate them into actionable solutions;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Technical Acumen: Familiarity with blockchain technologies and
                  Web3 ecosystems is a plus;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Self-Motivated: Highly self-motivated and able to work
                  independently as well as part of a team (very important).
                </span>
                <br></br>
              </span>
              <span className="career-business-developer-text62">
                Preferred skills
              </span>
              <span className="career-business-developer-text63">
                <span>
                  •​​ Networking: Existing network within the Web3 and cloud
                  infrastructure communities;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>Analytical Skills: Strong analytical abilities;</span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Adaptability: Ability to thrive in a fast-paced, innovative
                  environment.
                </span>
                <br></br>
                <br></br>
                <span>
                  Gateway.fm is committed to fostering a diverse and inclusive
                  environment. We encourage applicants of all backgrounds,
                  cultures, genders, experiences, abilities, and perspectives to
                  apply.
                </span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name36"></Footer>
    </div>
  )
}

export default CareerBusinessDeveloper
