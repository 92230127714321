import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import Tag from '../components/tag'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ImageProductHero from '../components/image-product-hero'
import ContentTitleProduct from '../components/content-title-product'
import CardProductHighlight from '../components/card-product-highlight'
import ContentParagraph from '../components/content-paragraph'
import ContentHighlight from '../components/content-highlight'
import ContentSmallTitle from '../components/content-small-title'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import AnnouncementSmall from '../components/announcement-small'
import Testimonial from '../components/testimonial'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './web3-infrastructure-blueprint-dao.css'

const Web3InfrastructureBlueprintDAO = (props) => {
  return (
    <div className="web3-infrastructure-blueprint-dao-container1">
      <Helmet>
        <title>Gateway.fm | Web3 Infrastructure Blueprint: DAO</title>
        <meta
          name="description"
          content="Discover Gateway.fm's Web3 blueprint for DAOs, leveraging blockchain for transparent and autonomous organizational governance."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Web3 Infrastructure Blueprint: DAO"
        />
        <meta
          property="og:description"
          content="Discover Gateway.fm's Web3 blueprint for DAOs, leveraging blockchain for transparent and autonomous organizational governance."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/6d46d178-2838-4654-bc3e-66978123ffc0?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name7"></Navigation>
      <div className="web3-infrastructure-blueprint-dao-content">
        <div className="web3-infrastructure-blueprint-dao-hero">
          <div className="web3-infrastructure-blueprint-dao-description">
            <TitleProductHero></TitleProductHero>
            <Tag></Tag>
            <SubtitleProductHero></SubtitleProductHero>
            <USPCheckmark></USPCheckmark>
            <USPCheckmark text="Optimise treasury management"></USPCheckmark>
            <USPCheckmark text="Enhance community engagement"></USPCheckmark>
            <Link
              to="/contact-form"
              className="web3-infrastructure-blueprint-dao-navlink1"
            >
              <ButtonPrimarySubtitle className="web3-infrastructure-blueprint-dao-component17"></ButtonPrimarySubtitle>
            </Link>
          </div>
          <ImageProductHero></ImageProductHero>
        </div>
        <div className="web3-infrastructure-blueprint-dao-container2">
          <ContentTitleProduct></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-dao-cards">
            <CardProductHighlight></CardProductHighlight>
            <CardProductHighlight
              main="Treasury management"
              supportive="DAO made efficient and cost-effective. At last"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Community engagement"
              supportive="DAO made efficient and cost-effective. At last"
            ></CardProductHighlight>
          </div>
          <ContentTitleProduct text="Summary"></ContentTitleProduct>
          <ContentParagraph></ContentParagraph>
          <ContentTitleProduct text="Democratise governance"></ContentTitleProduct>
          <ContentHighlight text="Traditional governance structures often concentrate decision-making power, hindering wide community involvement and transparency."></ContentHighlight>
          <ContentSmallTitle rootClassName="content-small-titleroot-class-name"></ContentSmallTitle>
          <ContentParagraph text="By adopting L2 rollup technology, we enable a more democratic governance model within DAOs. This approach facilitates secure, transparent, and cost-effective voting processes, ensuring that every community member can participate in governance decisions without technical or financial barriers."></ContentParagraph>
          <ContentTitleProduct text="Optimize Treasury Management"></ContentTitleProduct>
          <ContentHighlight text="The high cost and inefficiency of managing DAO treasuries on Layer 1 networks hinder swift and economical resource allocation."></ContentHighlight>
          <ContentSmallTitle rootClassName="content-small-titleroot-class-name1"></ContentSmallTitle>
          <ContentParagraph text="Leveraging gasless Layer 2 rollups for treasury management eradicates individual transaction fees, with only minimal costs incurred for batch proofs submitted to Layer 1. This approach vastly improves the efficiency and cost-effectiveness of treasury operations, enabling quick and economical management of DAO funds."></ContentParagraph>
          <ContentTitleProduct text="Enhance Community Engagement"></ContentTitleProduct>
          <ContentHighlight text="Achieving sustained community engagement in DAOs is often hampered by complex fund bridging processes and the difficulty of allocating voting power across blockchain layers."></ContentHighlight>
          <ContentSmallTitle rootClassName="content-small-titleroot-class-name2"></ContentSmallTitle>
          <ContentParagraph text="By simplifying the process of securely bridging funds from Layer 1 networks to Layer 2 and safely allocating voting powers, we ensure that participation in DAO governance is both accessible and meaningful."></ContentParagraph>
          <ContentParagraph text="In community-driven projects or open-source endeavors, a DAO can serve as a platform for managing project trajectories and distributing funds in a democratic manner. Members have the ability to propose, vote on and finance new features or modifications. Ensuring the project progresses in alignment with the community consensus."></ContentParagraph>
          <ContentTitleProduct text="Decentralized Investment Funds"></ContentTitleProduct>
          <ContentParagraph text="DAOs can operate as decentralized venture capital funds, enabling members to collectively determine investment strategies. This model democratizes asset management and potentially boosts returns by diversifying decision-making across contributors."></ContentParagraph>
          <ContentTitleProduct text="Nonprofit Sector"></ContentTitleProduct>
          <ContentParagraph text="Nonprofits can utilize DAOs to augment transparency in operations and decision-making, particularly concerning fundraising and resource allocation. This framework enables donors and stakeholders to actively participate in guiding the organization's initiatives."></ContentParagraph>
          <ContentTitleProduct text="Corporate Decision-Making"></ContentTitleProduct>
          <ContentParagraph text="Conventional businesses can integrate DAO frameworks to engage stakeholders in the governance process, enabling employees, customers, and investors to contribute to corporate decisions such as sustainability initiatives and strategic shifts."></ContentParagraph>
          <ContentTitleProduct text="Community Governance"></ContentTitleProduct>
          <ContentParagraph text="Residential neighborhoods or online communities can implement DAOs to democratically manage finances, coordinate events, and establish community guidelines. This fosters increased participation and ensures that decisions accurately reflect the community's preferences."></ContentParagraph>
        </div>
        <SubtitleProductPromo></SubtitleProductPromo>
        <AnnouncementSmall
          text="We enabled X to do Y"
          rootClassName="announcement-smallroot-class-name"
        ></AnnouncementSmall>
        <div className="web3-infrastructure-blueprint-dao-testimonials">
          <Testimonial rootClassName="testimonialroot-class-name"></Testimonial>
          <Testimonial
            name="Gnosis"
            text="... affordable and highly reliable RPC hosting services ... during the Chiado testnet merge, processing the first post-merge block. We look forward to this continued"
            position="Stefan George, CTO"
            rootClassName="testimonialroot-class-name1"
            logopolygonSrc="/external/image1inch1inchlogo26986-bbaj-200h.png"
          ></Testimonial>
          <Testimonial
            name="Goldsky"
            text="...we're used to seeing nodes latency increase overtime ... Gateway's node handled the load incredibly well, to the point we thought we did something wrong :)"
            position="@jefftyling"
            rootClassName="testimonialroot-class-name2"
            logopolygonSrc="/external/logo-goldsky-200h.png"
          ></Testimonial>
        </div>
        <Link
          to="/contact-form"
          className="web3-infrastructure-blueprint-dao-navlink2"
        >
          <Floating
            buttonLabel="Get started"
            buttonSubtext="Initiate this blueprint"
            rootClassName="floatingroot-class-name"
            className="web3-infrastructure-blueprint-dao-component51"
          ></Floating>
        </Link>
      </div>
      <Footer rootClassName="footerroot-class-name"></Footer>
    </div>
  )
}

export default Web3InfrastructureBlueprintDAO
