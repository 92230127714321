import React from 'react'

import PropTypes from 'prop-types'

import './title-side-page.css'

const TitleSidePage = (props) => {
  return (
    <div className={`title-side-page-container ${props.rootClassName} `}>
      <span className="title-side-page-text">{props.text}</span>
    </div>
  )
}

TitleSidePage.defaultProps = {
  text: 'NEWS, UPDATES & GUIDES',
  rootClassName: '',
}

TitleSidePage.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default TitleSidePage
