import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleSidePage from '../components/title-side-page'
import ContentTitleProduct from '../components/content-title-product'
import CardBranding from '../components/card-branding'
import CardRule from '../components/card-rule'
import Footer from '../components/footer'
import './brand-guidelines.css'

const BrandGuidelines = (props) => {
  return (
    <div className="brand-guidelines-container10">
      <Helmet>
        <title>Gateway.fm Brand Guidelines</title>
        <meta
          name="description"
          content="Explore the Gateway.fm brand guidelines for insights into our visual identity, voice, and messaging. Learn how to represent our brand accurately and effectively."
        />
        <meta property="og:title" content="Gateway.fm Brand Guidelines" />
        <meta
          property="og:description"
          content="Explore the Gateway.fm brand guidelines for insights into our visual identity, voice, and messaging. Learn how to represent our brand accurately and effectively."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/0193dfe8-909c-4914-8bc5-32fe34f7bbb7?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name4"></Navigation>
      <div className="brand-guidelines-container11">
        <TitleSidePage
          text="BRAND GUIDELINES"
          rootClassName="title-side-pageroot-class-name1"
        ></TitleSidePage>
        <div className="brand-guidelines-container12"></div>
        <ContentTitleProduct text="Logo"></ContentTitleProduct>
        <div className="brand-guidelines-container13">
          <CardBranding rootClassName="card-brandingroot-class-name3"></CardBranding>
          <CardBranding
            imageSrc="/external/brand-gw-bw-logo-1500w.png"
            description="B&amp;W LOGO"
            rootClassName="card-brandingroot-class-name4"
          ></CardBranding>
          <div className="brand-guidelines-container14">
            <div className="brand-guidelines-container15">
              <div className="brand-guidelines-download">
                <div className="brand-guidelines-download-button">
                  <img
                    alt="trayarrowdown7381"
                    src="/external/trayarrowdown7381-pihc.svg"
                    className="brand-guidelines-trayarrowdown"
                  />
                </div>
              </div>
              <img
                alt="image"
                src="/external/brand-gw-inverted-logo-200h.png"
                className="brand-guidelines-image"
              />
              <div className="brand-guidelines-texts1">
                <span className="brand-guidelines-text10">PNG, SVG</span>
                <span className="brand-guidelines-text11">INVERTED LOGO</span>
              </div>
            </div>
          </div>
        </div>
        <div className="brand-guidelines-container16">
          <CardBranding
            imageSrc="/external/brand-gw-marks-200h.png"
            description="LOGO MARK"
            rootClassName="card-brandingroot-class-name6"
          ></CardBranding>
          <CardBranding
            imageSrc="/external/brand-mascot-1500w.png"
            description="MASCOT"
            rootClassName="card-brandingroot-class-name7"
          ></CardBranding>
          <CardBranding
            imageSrc="/external/brand-presto-logo-powered-1500w.png"
            description="PRESTO LOGO"
            rootClassName="card-brandingroot-class-name8"
          ></CardBranding>
        </div>
        <ContentTitleProduct text="Usage &amp; Rules"></ContentTitleProduct>
        <div className="brand-guidelines-frame1171275240">
          <CardRule></CardRule>
          <CardRule
            icon="/external/close7391-cwzo.svg"
            text="Don’t modify or use two colors within the lockup"
            header="MODIFICATION"
          ></CardRule>
          <CardRule
            icon="/external/close7391-cwzo.svg"
            text="Don’t change position or scale of the logo within the lockup"
            header="POSITION"
          ></CardRule>
        </div>
        <ContentTitleProduct text="Colors"></ContentTitleProduct>
        <div className="brand-guidelines-container17">
          <div className="brand-guidelines-container18">
            <div className="brand-guidelines-container19">
              <div className="brand-guidelines-colour1"></div>
              <div className="brand-guidelines-texts2">
                <span className="brand-guidelines-text12">
                  Main interactive
                </span>
                <span className="brand-guidelines-text13">#8950FA</span>
              </div>
            </div>
          </div>
          <div className="brand-guidelines-container20">
            <div className="brand-guidelines-container21">
              <div className="brand-guidelines-colour2"></div>
              <div className="brand-guidelines-texts3">
                <span className="brand-guidelines-text14">Main text</span>
                <span className="brand-guidelines-text15">#1A1A1A</span>
              </div>
            </div>
          </div>
          <div className="brand-guidelines-container22">
            <div className="brand-guidelines-container23">
              <div className="brand-guidelines-colour3"></div>
              <div className="brand-guidelines-texts4">
                <span className="brand-guidelines-text16">Green</span>
                <span className="brand-guidelines-text17">#7EE100</span>
              </div>
            </div>
          </div>
          <div className="brand-guidelines-container24">
            <div className="brand-guidelines-container25">
              <div className="brand-guidelines-colour4"></div>
              <div className="brand-guidelines-texts5">
                <span className="brand-guidelines-text18">Pink</span>
                <span className="brand-guidelines-text19">#FA50C1</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default BrandGuidelines
