import React from 'react'
import { Link } from 'react-router-dom'

import { Player } from '@lottiefiles/react-lottie-player'
import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import USPCheckmark from '../components/usp-checkmark'
import SubtitleProductHero from '../components/subtitle-product-hero'
import ProgressBar from '../components/progress-bar'
import InputField from '../components/input-field'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ButtonSecondary from '../components/button-secondary'
import FormBack from '../components/form-back'
import OptionNoIcon from '../components/option-no-icon'
import Option from '../components/option'
import Footer from '../components/footer'
import './contact-form.css'

const ContactForm = (props) => {
  return (
    <div className="contact-form-container1">
      <Helmet>
        <title>Gateway.fm | Contact Us</title>
        <meta
          name="description"
          content="Reach out to Gateway.fm for inquiries, collaborations, or partnerships. Contact us today to explore how we can work together in the blockchain ecosystem."
        />
        <meta property="og:title" content="Gateway.fm | Contact Us" />
        <meta
          property="og:description"
          content="Reach out to Gateway.fm for inquiries, collaborations, or partnerships. Contact us today to explore how we can work together in the blockchain ecosystem."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/d419e369-5485-4a3f-9c71-235a84199e25?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name16"></Navigation>
      <div className="contact-form-content">
        <div className="contact-form-hero">
          <div className="contact-form-description">
            <div className="contact-form-container2">
              <span className="contact-form-text1">LET&apos;S SET IT UP!</span>
            </div>
            <div className="contact-form-container3">
              <USPCheckmark text="No obligations"></USPCheckmark>
              <USPCheckmark text="We help filling the blanks"></USPCheckmark>
              <USPCheckmark text="Courteous experts"></USPCheckmark>
            </div>
            <SubtitleProductHero text="Please provide project description the best you can. We will help to fill-in the blanks"></SubtitleProductHero>
          </div>
          <form
            id="multiStepForm"
            action="javascript:void(0)"
            method="POST"
            className="contact-form-form"
          >
            <ProgressBar></ProgressBar>
            <div id="step1" className="step">
              <InputField
                type="text"
                nameValue="name"
                placeholder="Søren Kierkegaard"
              ></InputField>
              <InputField
                id="contact"
                type="text"
                label="Your Telegram or email"
                nameValue="contact"
                placeholder="@gateway_eth"
              ></InputField>
              <ButtonPrimarySubtitle
                main="Start questionnaire"
                divID="startButton"
                onclick="nextStep(2)"
                supportive="5 quick questions"
              ></ButtonPrimarySubtitle>
              <Link to="/book-a-call">
                <ButtonSecondary
                  label="Book a call instead"
                  subtext="Skip questionnaire"
                  className="contact-form-component19"
                ></ButtonSecondary>
              </Link>
            </div>
            <div id="step2" className="contact-form-step2 step">
              <div className="contact-form-container4">
                <FormBack rootClassName="form-backroot-class-name"></FormBack>
                <span className="contact-form-text2">Type of project</span>
              </div>
              <OptionNoIcon
                text="Payment provider, bank or other financial institution"
                label="Digital Bank"
                dataStep="step2"
                radioName="step2Option"
                dataOption="Digital Bank"
                radioValue="Digital Bank"
              ></OptionNoIcon>
              <OptionNoIcon
                text="Logistic solutions"
                label="Supply chain"
                dataStep="step2"
                radioName="step2Option"
                dataOption="Supply chain"
                radioValue="Supply chain"
              ></OptionNoIcon>
              <OptionNoIcon
                text="Green tokens"
                label="Carbon credits"
                dataStep="step2"
                radioName="step2Option"
                dataOption="Carbon credits"
                radioValue="Carbon credits"
              ></OptionNoIcon>
              <OptionNoIcon
                text="Trade unique items"
                label="NFT platform"
                dataStep="step2"
                radioName="step2Option"
                dataOption="NFT platform"
                radioValue="NFT platform"
              ></OptionNoIcon>
              <OptionNoIcon
                text="Another or a new blueprint"
                label="Other"
                dataStep="step2"
                radioName="step2Option"
                dataOption="Other"
                radioValue="Other"
              ></OptionNoIcon>
              <Link to="/book-a-call">
                <ButtonSecondary
                  label="Book a call instead"
                  subtext="Skip questionnaire"
                  className="contact-form-component26"
                ></ButtonSecondary>
              </Link>
            </div>
            <div id="step3" className="contact-form-step3 step">
              <div className="contact-form-container5">
                <FormBack></FormBack>
                <span className="contact-form-text3">
                  What stack would you prefer?
                </span>
              </div>
              <Option
                logo="/external/logos/logo-polygon-cdk-200h.png"
                text="Scalable, Ethereum-compatible, full environment"
                label="Polygon"
                dataName="Stack"
                dataStep="step3"
                dataValue="Polygon"
                radioName="step3Option"
                dataOption="Polygon"
                radioValue="Polygon"
              ></Option>
              <Option
                logo="/external/logos/logo-optimism1-200h.png"
                text="Trustless, EVM-equivalent, low fees"
                label="Optimism"
                dataName="Stack"
                dataStep="step3"
                dataValue="Optimism"
                radioName="step3Option"
                dataOption="Optimism"
                radioValue="Optimism"
              ></Option>
              <Option
                logo="/external/logos/logo-arbitrum-200h.png"
                text="Secure, fully Ethereum-compliant rollup"
                label="Arbitrum"
                dataName="Stack"
                dataStep="step3"
                dataValue="Arbitrum"
                radioName="step3Option"
                dataOption="Arbitrum"
                radioValue="Arbitrum"
              ></Option>
              <Option
                logo="/external/logos/logo-zk-sync-200h.png"
                text="Validity proofs, low gas costs"
                label="zkSync"
                dataName="Stack"
                dataStep="step3"
                dataValue="zkSync"
                radioName="step3Option"
                dataOption="zkSync"
                radioValue="zkSync"
              ></Option>
              <Option
                logo="/external/logos/logo-immutable-200h.png"
                text="Zero-knowledge, massively scalable STARK"
                label="Immutable"
                dataName="Stack"
                dataStep="step3"
                dataValue="Immutable"
                radioName="step3Option"
                dataOption="Immutable"
                radioValue="Immutable"
              ></Option>
              <OptionNoIcon
                text="Another stack of your choice"
                label="Other"
                dataStep="step3"
                radioName="step3Option"
                dataOption="Other"
                radioValue="Other"
              ></OptionNoIcon>
              <Link to="/book-a-call">
                <ButtonSecondary
                  label="Book a call instead"
                  subtext="Skip questionnaire"
                  className="contact-form-component34"
                ></ButtonSecondary>
              </Link>
            </div>
            <div id="step4" className="contact-form-step4 step">
              <div className="contact-form-container6">
                <FormBack></FormBack>
                <span className="contact-form-text4">
                  Expected transactions per day
                </span>
              </div>
              <OptionNoIcon
                text="Up to 20'000 transactions daily"
                label="0 - 20K"
                dataStep="step4"
                radioName="step4Option"
                dataOption="20K"
                radioValue="20K"
              ></OptionNoIcon>
              <OptionNoIcon
                text="Between 20'000 and 100'000 transactions daily"
                label="20 - 100K"
                dataStep="step4"
                radioName="step4Option"
                dataOption="100K"
                radioValue="100K"
              ></OptionNoIcon>
              <OptionNoIcon
                text="Between 100'000 and 500'000 transactions daily"
                label="100 - 500K"
                dataStep="step4"
                radioName="step4Option"
                dataOption="100K"
                radioValue="100K"
              ></OptionNoIcon>
              <OptionNoIcon
                text="Between 100'000 and 500'000 transactions daily"
                label="100 - 500K"
                dataStep="step4"
                radioName="step4Option"
                dataOption="100K"
                radioValue="100K"
              ></OptionNoIcon>
              <OptionNoIcon
                text="Between 500'000 and 1'000'000 transactions daily"
                label="500 - 1'000K"
                dataStep="step4"
                radioName="step4Option"
                dataOption="1M"
                radioValue="1M"
              ></OptionNoIcon>
              <OptionNoIcon
                text="Over 1'000'000 transactions daily"
                label="1M+"
                dataStep="step4"
                radioName="step4Option"
                dataOption="1M+"
                radioValue="1M+"
              ></OptionNoIcon>
              <Link to="/book-a-call">
                <ButtonSecondary
                  label="Book a call instead"
                  subtext="Skip questionnaire"
                  className="contact-form-component42"
                ></ButtonSecondary>
              </Link>
            </div>
            <div id="step5" className="contact-form-step5 step">
              <Player
                src="/external/lotties/loading.json"
                loop="true"
                speed="1"
                autoplay="true"
                background="transparent"
                className="contact-form-lottie-node"
              ></Player>
            </div>
          </form>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name15"></Footer>
    </div>
  )
}

export default ContactForm
