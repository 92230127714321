import React from 'react'

import PropTypes from 'prop-types'

import './option-no-icon.css'

const OptionNoIcon = (props) => {
  return (
    <div className="option-no-icon-container1">
      <div
        data-step={props.dataStep}
        data-option={props.dataOption}
        className="option-no-icon-container2 option"
      >
        <div className="option-no-icon-texts">
          <span className="option-no-icon-text1">{props.label}</span>
          <span className="option-no-icon-text2">{props.text}</span>
        </div>
      </div>
      <input
        type="radio"
        name={props.radioName}
        value={props.radioValue}
        className="option-no-icon-radiobutton"
      />
    </div>
  )
}

OptionNoIcon.defaultProps = {
  text: 'Fast and cheap transactions with a longer text spanning out',
  radioName: 'radio',
  radioValue: 'value',
  dataOption: '',
  dataStep: '',
  label: 'Polygon',
}

OptionNoIcon.propTypes = {
  text: PropTypes.string,
  radioName: PropTypes.string,
  radioValue: PropTypes.string,
  dataOption: PropTypes.string,
  dataStep: PropTypes.string,
  label: PropTypes.string,
}

export default OptionNoIcon
