import React from 'react'

import PropTypes from 'prop-types'

import ButtonSmallMain from './button-small-main'
import './card-plan-rpc-copy.css'

const CardPlanRPCCopy = (props) => {
  return (
    <div className="card-plan-rpc-copy-container1">
      <div id={props.cardID} className="card-plan-rpc-copy-container2">
        <div className="card-plan-rpc-copy-texts">
          <span className="card-plan-rpc-copy-text1">{props.text}</span>
          <div className="card-plan-rpc-copy-flags">
            <img
              alt={props.singaporeAlt}
              src={props.singaporeSrc}
              className="card-plan-rpc-copy-singapore"
            />
            <img
              alt={props.switzerlandAlt}
              src={props.switzerlandSrc}
              className="card-plan-rpc-copy-switzerland"
            />
            <img
              alt={props.swedenAlt}
              src={props.swedenSrc}
              className="card-plan-rpc-copy-sweden"
            />
            <img
              alt={props.germanyAlt}
              src={props.germanySrc}
              className="card-plan-rpc-copy-germany"
            />
          </div>
          <div className="card-plan-rpc-copy-us-ps">
            <div className="card-plan-rpc-copy-container3">
              <div className="card-plan-rpc-copy-content1">
                <img
                  alt="check6986"
                  src="/external/check6986-go3q.svg"
                  className="card-plan-rpc-copy-check1"
                />
                <span
                  id={props.requestsID}
                  className="card-plan-rpc-copy-text2"
                >
                  {props.text3}
                </span>
              </div>
            </div>
            <div className="card-plan-rpc-copy-container4">
              <div className="card-plan-rpc-copy-content2">
                <img
                  alt="check6986"
                  src="/external/check6986-go3q.svg"
                  className="card-plan-rpc-copy-check2"
                />
                <span className="card-plan-rpc-copy-text3">{props.text2}</span>
              </div>
            </div>
            <div className="card-plan-rpc-copy-container5">
              <div className="card-plan-rpc-copy-content3">
                <img
                  alt="check6986"
                  src="/external/check6986-go3q.svg"
                  className="card-plan-rpc-copy-check3"
                />
                <span className="card-plan-rpc-copy-text4">{props.text1}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-plan-rpc-copy-link">
          <span id="rpcLink" className="card-plan-rpc-copy-text5">
            https://rpc.gnosis.gateway.fm
          </span>
          <div className="card-plan-rpc-copy-container6">
            <span id="rpcCopied" className="card-plan-rpc-copy-text6">
              copied!
            </span>
            <ButtonSmallMain
              divID="rpcCopyLink"
              label="copy"
              rootClassName="button-small-mainroot-class-name3"
            ></ButtonSmallMain>
          </div>
        </div>
      </div>
    </div>
  )
}

CardPlanRPCCopy.defaultProps = {
  text3: '60 req/s (155M req/mon)',
  rootClassName1: '',
  swedenAlt: 'Sweden7238',
  germanySrc: '/external/germany7238-juwt.svg',
  rootClassName: '',
  switzerlandSrc: '/external/switzerland7238-2rie.svg',
  text: 'FREE ACCESS',
  singaporeAlt: 'Singapore7238',
  singaporeSrc: '/external/singapore7238-qi-200h.png',
  requestsID: '',
  rootClassName2: '',
  germanyAlt: 'Germany7238',
  cardID: '',
  text1: 'WebSockets support',
  text2: 'Discord community',
  switzerlandAlt: 'Switzerland7238',
  swedenSrc: '/external/sweden7238-tk56.svg',
}

CardPlanRPCCopy.propTypes = {
  text3: PropTypes.string,
  rootClassName1: PropTypes.string,
  swedenAlt: PropTypes.string,
  germanySrc: PropTypes.string,
  rootClassName: PropTypes.string,
  switzerlandSrc: PropTypes.string,
  text: PropTypes.string,
  singaporeAlt: PropTypes.string,
  singaporeSrc: PropTypes.string,
  requestsID: PropTypes.string,
  rootClassName2: PropTypes.string,
  germanyAlt: PropTypes.string,
  cardID: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  switzerlandAlt: PropTypes.string,
  swedenSrc: PropTypes.string,
}

export default CardPlanRPCCopy
