import React from 'react'

import PropTypes from 'prop-types'

import './subtitle-product-hero.css'

const SubtitleProductHero = (props) => {
  return (
    <div className="subtitle-product-hero-container">
      <span className="subtitle-product-hero-text">{props.text}</span>
    </div>
  )
}

SubtitleProductHero.defaultProps = {
  text: 'Manage organisations, grants, investments by your hive-mind',
}

SubtitleProductHero.propTypes = {
  text: PropTypes.string,
}

export default SubtitleProductHero
