import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import ContentParagraph from '../components/content-paragraph'
import ContentTitleProduct from '../components/content-title-product'
import Footer from '../components/footer'
import './privacy-policy.css'

const PrivacyPolicy = (props) => {
  return (
    <div className="privacy-policy-container1">
      <Helmet>
        <title>Gateway.fm | Privacy Policy</title>
        <meta
          name="description"
          content="Read Gateway.fm's privacy policy to understand how we handle your data with utmost confidentiality and security."
        />
        <meta property="og:title" content="Gateway.fm | Privacy Policy" />
        <meta
          property="og:description"
          content="Read Gateway.fm's privacy policy to understand how we handle your data with utmost confidentiality and security."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/9e8d639a-bb10-4d02-bfe1-7dd7ec8e9e09?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name10"></Navigation>
      <div className="privacy-policy-content">
        <div className="privacy-policy-hero">
          <div className="privacy-policy-description">
            <TitleProductHero text="PRIVACY POLICY"></TitleProductHero>
          </div>
          <div className="privacy-policy-container2">
            <span className="privacy-policy-text10">
              Effective Date: April 1, 2022. Version 2.1
            </span>
          </div>
        </div>
        <div className="privacy-policy-container3">
          <ContentParagraph text="This Privacy Notice describes how we (GATEWAY.FM, the data controller) deal with personal information concerning your use of our services and websites. We encourage you to read this notice thoroughly to understand how we handle and protect personal information and which choices you have regarding your data."></ContentParagraph>
          <ContentTitleProduct text="What do we mean by “Personal Information”?"></ContentTitleProduct>
          <ContentParagraph text="Personal information identifies you (for example, name and postal address) or can be reasonably linked to you as an individual (for example, login identifier and IP address). Any other data that we process is “non-personal information.”"></ContentParagraph>
          <ContentTitleProduct text="What do we mean by “Personal Information”?"></ContentTitleProduct>
          <ContentParagraph text="When you sign-up for our services, we’ll ask permission to fetch your name, email address, language preference, and profile picture from your Google account. We use this information to set up your account at GATEWAY.FM, enable authentication and login recovery and send critical notifications regarding the service. We will store this data as long as your account is active."></ContentParagraph>
          <ContentParagraph text="When you configure a preferred payment method to charge, we’ll ask for your billing address and employ our technology partner to handle the payments. We will store this data as long as your account is active."></ContentParagraph>
          <ContentParagraph text="When you sign-up for our services, subscribe to a newsletter or join a waitlist, we’ll ask for a valid email address. Then, we will use it to send you marketing information occasionally. You can adjust your communication preferences or unsubscribe anytime later."></ContentParagraph>
          <ContentTitleProduct text="What information do we collect about you?"></ContentTitleProduct>
          <ContentParagraph text="When you use our services or visit our websites, we may log your device’s IP address for debugging and security reasons. We may retain this information for up to twelve months."></ContentParagraph>
          <ContentTitleProduct text="Which cookies do we use?"></ContentTitleProduct>
          <ContentParagraph text="We may set cookies to make our websites work. For example, we use mandatory cookies to authenticate users and protect our websites from denial-of-service attacks. In addition, recommended cookies can make your browsing experience smoother by saving preferences locally. We also ask for your consent to set analytics cookies (Google Analytics, Heap Analytics) to collect statistics about online visitors, understand the audience better and measure our advertising campaigns."></ContentParagraph>
          <ContentTitleProduct text="How do we protect your personal information?"></ContentTitleProduct>
          <ContentParagraph text="We use data centers in the European Economic Area and may transfer data outside the EEA if our data processors provide adequate safeguards. The data is encrypted and routinely backed up. In addition, we maintain strict access control procedures and review security logs regularly."></ContentParagraph>
          <ContentParagraph text="We Use The Following Technology Providers To Process Your Data:"></ContentParagraph>
          <a
            href="https://hubspot.com/"
            target="_blank"
            rel="noreferrer noopener"
            className="privacy-policy-link"
          >
            <div className="privacy-policy-container4">
              <span className="privacy-policy-text11">HUBSPOT</span>
            </div>
          </a>
          <ContentTitleProduct text="When do we share your information with others?"></ContentTitleProduct>
          <div className="privacy-policy-container5">
            <span className="privacy-policy-text12">
              <span>In some cases, we may share your data with others:</span>
              <br></br>
              <span>
                - When we asked you about it and obtained your consent;
              </span>
              <br></br>
              <span>
                - When we delegate data processing to our technology partners
                (for example, cloud service providers);
              </span>
              <br></br>
              <span>
                - When the law requires it (for example, to handle police or
                court inquiries): or
              </span>
              <br></br>
              <span>
                - If our company situation changes (for example, in case of a
                merger or acquisition), we may pass your information to a
                successor or affiliate.
              </span>
            </span>
          </div>
          <ContentTitleProduct text="What are your privacy rights?"></ContentTitleProduct>
          <ContentParagraph text="According to the law, you can request correction or deletion of your personal information, withdraw your consent or restrict data processing, and receive a copy of the data we store on you. If you believe that processing your personal information is unlawful, you have the right to send a complaint to Datatilsynet, Norway’s data protection authority."></ContentParagraph>
          <ContentTitleProduct text="How to contact us?"></ContentTitleProduct>
          <ContentParagraph text="If you have questions about this notice or want to exercise your privacy rights, please email our Data Privacy Officer at privacy@gateway.fm or send a snail mail to GATEWAY.FM AS, Skagenkaien 35, 4006 Stavanger, Norway."></ContentParagraph>
        </div>
        <div className="privacy-policy-testimonials"></div>
      </div>
      <Footer rootClassName="footerroot-class-name5"></Footer>
    </div>
  )
}

export default PrivacyPolicy
