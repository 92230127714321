import React from 'react'

import PropTypes from 'prop-types'

import './selector-chain.css'

const SelectorChain = (props) => {
  return (
    <div className="selector-chain-container">
      <div
        id={props.chainSelectorId}
        className="selector-chain-unselected interactive-element"
      >
        <img
          alt="gnosisgnognologo7238"
          src={props.logo}
          className="selector-chain-gnosisgnognologo"
        />
        <span className="selector-chain-text">{props.label}</span>
      </div>
    </div>
  )
}

SelectorChain.defaultProps = {
  label: 'Gnosis',
  logo: '/external/gnosisgnognologo7238-0z35-200h.png',
  chainSelectorId: '',
}

SelectorChain.propTypes = {
  label: PropTypes.string,
  logo: PropTypes.string,
  chainSelectorId: PropTypes.string,
}

export default SelectorChain
