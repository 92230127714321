import React from 'react'

import PropTypes from 'prop-types'

import './content-title-product.css'

const ContentTitleProduct = (props) => {
  return (
    <div className={`content-title-product-container ${props.rootClassName} `}>
      <span className="content-title-product-text">{props.text}</span>
    </div>
  )
}

ContentTitleProduct.defaultProps = {
  rootClassName: '',
  text: 'What for?',
}

ContentTitleProduct.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default ContentTitleProduct
