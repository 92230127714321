import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import Tag from '../components/tag'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ImageProductHero from '../components/image-product-hero'
import ContentTitleProduct from '../components/content-title-product'
import CardProductHighlight from '../components/card-product-highlight'
import ContentParagraph from '../components/content-paragraph'
import ContentSmallTitle from '../components/content-small-title'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import AnnouncementSmall from '../components/announcement-small'
import Testimonial from '../components/testimonial'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './web3-infrastructure-blueprint-decentralised-exchange.css'

const Web3InfrastructureBlueprintDecentralisedExchange = (props) => {
  return (
    <div className="web3-infrastructure-blueprint-decentralised-exchange-container1">
      <Helmet>
        <title>
          Gateway.fm | Web3 Infrastructure Blueprint: Decentralized Exchange
        </title>
        <meta
          name="description"
          content="Explore Gateway.fm's blueprint for a Web3 decentralized exchange, leveraging blockchain for secure and efficient peer-to-peer trading."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Web3 Infrastructure Blueprint: Decentralized Exchange"
        />
        <meta
          property="og:description"
          content="Explore Gateway.fm's blueprint for a Web3 decentralized exchange, leveraging blockchain for secure and efficient peer-to-peer trading."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/b036fc00-cd72-4fa9-9917-45caff8ea900?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name19"></Navigation>
      <div className="web3-infrastructure-blueprint-decentralised-exchange-content">
        <div className="web3-infrastructure-blueprint-decentralised-exchange-hero">
          <div className="web3-infrastructure-blueprint-decentralised-exchange-description">
            <TitleProductHero text="DECENTRALISED EXCHANGE"></TitleProductHero>
            <Tag rootClassName="tagroot-class-name6"></Tag>
            <SubtitleProductHero text="Facilitate peer-to-peer trading of digital assets without intermediaries"></SubtitleProductHero>
            <USPCheckmark text="Enable trustless trading"></USPCheckmark>
            <USPCheckmark text="Maintain user control and custody"></USPCheckmark>
            <USPCheckmark text="No code. Zero knowledge"></USPCheckmark>
            <Link
              to="/contact-form"
              className="web3-infrastructure-blueprint-decentralised-exchange-navlink1"
            >
              <ButtonPrimarySubtitle className="web3-infrastructure-blueprint-decentralised-exchange-component17"></ButtonPrimarySubtitle>
            </Link>
          </div>
          <ImageProductHero image="/external/blueprints/dex-500h.png"></ImageProductHero>
        </div>
        <div className="web3-infrastructure-blueprint-decentralised-exchange-container2">
          <ContentTitleProduct></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-decentralised-exchange-cards">
            <CardProductHighlight
              main="Addressing Centralization Risks"
              supportive="It's eliminating the need for a central authority or custodian"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Fostering Transparency and Fairness"
              supportive="Users can independently verify the integrity of the exchange, ensuring that trades are executed fairly "
            ></CardProductHighlight>
            <CardProductHighlight
              main="Enabling Secure and Efficient Trading"
              supportive="We're leveraging advanced smart contract protocols and decentralized liquidity pools"
            ></CardProductHighlight>
          </div>
          <ContentTitleProduct text="Summary"></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-decentralised-exchange-container3">
            <span className="web3-infrastructure-blueprint-decentralised-exchange-text">
              Our innovative Web3 decentralized exchange solution leverages
              blockchain technology to establish a secure, trustless, and
              permissionless trading platform for digital assets. By harnessing
              the power of smart contracts, cryptographic proofs, and a
              decentralized network, we create a transparent and efficient
              marketplace for trading cryptocurrencies, tokens, and other
              digital assets, free from the limitations and risks associated
              with centralized exchanges.
            </span>
          </div>
          <ContentTitleProduct text="Addressing Centralization Risks"></ContentTitleProduct>
          <ContentParagraph text="Traditional centralized exchanges often suffer from single points of failure, lack of transparency, and vulnerability to hacking, theft, or regulatory crackdowns. Our Web3 decentralized exchange solution addresses these challenges by eliminating the need for a central authority or custodian. Instead, it leverages a peer-to-peer network and self-executing smart contracts, ensuring that trades are executed in a trustless, immutable, and censorship-resistant manner."></ContentParagraph>
          <ContentTitleProduct text="Fostering Transparency and Fairness"></ContentTitleProduct>
          <ContentParagraph text="Transparency and fairness are at the core of our Web3 decentralized exchange solution. By recording all trades and order book data on a public, immutable blockchain, we eliminate the potential for manipulation or unfair practices. Users can independently verify the integrity of the exchange, ensuring that trades are executed fairly and in accordance with predefined rules. This level of transparency fosters trust among participants and promotes a fair and equitable trading environment."></ContentParagraph>
          <ContentTitleProduct text="Enabling Secure and Efficient Trading"></ContentTitleProduct>
          <ContentParagraph text="Traditional centralized exchanges often suffer from liquidity fragmentation, inefficient order matching, and high trading fees. Our Web3 decentralized exchange solution addresses these challenges by leveraging advanced smart contract protocols and decentralized liquidity pools. These protocols enable secure and efficient on-chain trading, with automated order matching, atomic swaps, and low transaction fees. This streamlined trading experience enhances liquidity, reduces slippage, and optimizes the overall trading experience for users."></ContentParagraph>
          <ContentTitleProduct text="Promoting Financial Inclusion"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 decentralized exchange solution is designed to be globally accessible and censorship-resistant, enabling users from around the world to participate in the trading of digital assets. By leveraging a decentralized architecture and open standards, our solution eliminates geographical barriers and promotes financial inclusion, empowering individuals and organizations to access global markets and participate in the growing digital asset economy."></ContentParagraph>
          <ContentTitleProduct text="Scalable and Interoperable"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 solution is built with scalability and interoperability in mind, capable of integrating with various blockchain networks, wallets, and third-party services. By leveraging modular architecture and open standards, our solution can seamlessly connect with multiple blockchain ecosystems, enabling cross-chain trading and bridging of digital assets, ensuring compatibility across diverse technologies and platforms."></ContentParagraph>
          <ContentTitleProduct text="Components"></ContentTitleProduct>
          <ContentSmallTitle
            text="Decentralized Network"
            rootClassName="content-small-titleroot-class-name42"
          ></ContentSmallTitle>
          <ContentParagraph text="A peer-to-peer network of nodes that collectively maintain and validate the exchange's order book and transaction history, leveraging advanced consensus mechanisms and cryptographic techniques."></ContentParagraph>
          <ContentSmallTitle
            text="Smart Contracts"
            rootClassName="content-small-titleroot-class-name43"
          ></ContentSmallTitle>
          <ContentParagraph text="Self-executing contracts that govern the trading logic, order matching, liquidity provision, and settlement of trades, ensuring trustless and transparent execution of transactions."></ContentParagraph>
          <ContentSmallTitle
            text="Automated Market Makers (AMMs)"
            rootClassName="content-small-titleroot-class-name44"
          ></ContentSmallTitle>
          <ContentParagraph text="Decentralized liquidity pools that facilitate efficient and secure on-chain trading, enabling automated order matching, atomic swaps, and low slippage."></ContentParagraph>
          <ContentSmallTitle
            text="Oracles"
            rootClassName="content-small-titleroot-class-name45"
          ></ContentSmallTitle>
          <ContentParagraph text="Secure gateways that facilitate the integration of off-chain data, such as real-time asset prices, into the decentralized exchange, enabling accurate pricing and trade execution."></ContentParagraph>
          <ContentSmallTitle
            text="Non-Custodial Wallets"
            rootClassName="content-small-titleroot-class-name46"
          ></ContentSmallTitle>
          <ContentParagraph text="Secure and user-controlled wallets that enable users to manage their digital assets and interact with the decentralized exchange without relinquishing control or custody of their funds."></ContentParagraph>
          <ContentSmallTitle
            text="Analytics and Reporting"
            rootClassName="content-small-titleroot-class-name47"
          ></ContentSmallTitle>
          <ContentParagraph text="Advanced analytics and reporting tools that leverage the transparent and immutable data stored on the blockchain, providing users with valuable insights, market trends, and trading analytics."></ContentParagraph>
          <ContentSmallTitle
            text="Cross-Chain Interoperability"
            rootClassName="content-small-titleroot-class-name48"
          ></ContentSmallTitle>
          <ContentParagraph text="A flexible cross-chain infrastructure that enables seamless connectivity and asset bridging between multiple blockchain networks, unlocking cross-chain trading opportunities and enhancing liquidity."></ContentParagraph>
          <ContentParagraph text="By harnessing the power of Web3 technologies, our decentralized exchange solution delivers unparalleled security, transparency, and efficiency, empowering users to trade digital assets in a trustless, censorship-resistant, and globally accessible environment, free from the limitations and risks associated with centralized exchanges."></ContentParagraph>
        </div>
        <div className="web3-infrastructure-blueprint-decentralised-exchange-container4">
          <SubtitleProductPromo></SubtitleProductPromo>
          <AnnouncementSmall
            text="We enabled Wirex to elevate its zk-powered network"
            rootClassName="announcement-smallroot-class-name2"
            className="web3-infrastructure-blueprint-decentralised-exchange-component51"
          ></AnnouncementSmall>
          <div className="web3-infrastructure-blueprint-decentralised-exchange-testimonials">
            <Testimonial
              name="Wirex"
              text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
              position="@wirexapp"
              rootClassName="testimonialroot-class-name63"
              logopolygonSrc="/external/vector1317-f6xm.svg"
            ></Testimonial>
            <Testimonial
              name="GPT Protocol"
              text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
              position="@gpt_protocol"
              rootClassName="testimonialroot-class-name64"
              logopolygonSrc="/external/logos/logo-gpt-200w.png"
            ></Testimonial>
            <Testimonial
              name="Dora"
              text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
              position="@SearchOnDora"
              rootClassName="testimonialroot-class-name65"
              logopolygonSrc="/external/logos/logo-dora1-200h.png"
            ></Testimonial>
          </div>
        </div>
        <Link
          to="/contact-form"
          className="web3-infrastructure-blueprint-decentralised-exchange-navlink2"
        >
          <Floating
            label="Decentralised Exchange"
            buttonLabel="Get started"
            description="Facilitate peer-to-peer trading of digital assets without intermediaries"
            buttonSubtext="Initiate this blueprint"
            rootClassName="floatingroot-class-name5"
            className="web3-infrastructure-blueprint-decentralised-exchange-component55"
          ></Floating>
        </Link>
      </div>
      <Footer rootClassName="footerroot-class-name18"></Footer>
    </div>
  )
}

export default Web3InfrastructureBlueprintDecentralisedExchange
