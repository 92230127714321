import React from 'react'

import PropTypes from 'prop-types'

import './card-number.css'

const CardNumber = (props) => {
  return (
    <div className="card-number-container1">
      <div className="card-number-container2">
        <span className="card-number-text1">{props.text}</span>
        <span className="card-number-text2">{props.text1}</span>
      </div>
    </div>
  )
}

CardNumber.defaultProps = {
  text1: 'Requests per second supported',
  text: '10K',
}

CardNumber.propTypes = {
  text1: PropTypes.string,
  text: PropTypes.string,
}

export default CardNumber
