import React from 'react'

import PropTypes from 'prop-types'

import './usp-checkmark.css'

const USPCheckmark = (props) => {
  return (
    <div className={`usp-checkmark-container ${props.rootClassName} `}>
      <div className="usp-checkmark-content">
        <img
          alt="check6986"
          src="/external/check6986-go3q.svg"
          className="usp-checkmark-check"
        />
        <span className="usp-checkmark-text">{props.text}</span>
      </div>
    </div>
  )
}

USPCheckmark.defaultProps = {
  rootClassName: '',
  text: 'Democratise governance',
}

USPCheckmark.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default USPCheckmark
