import React from 'react'

import PropTypes from 'prop-types'

import './testimonial.css'

const Testimonial = (props) => {
  return (
    <div className={`testimonial-container1 ${props.rootClassName} `}>
      <div className="testimonial-container2">
        <div className="testimonial-author1">
          <img
            alt="Logo"
            src={props.logopolygonSrc}
            className="testimonial-logopolygon"
          />
          <div className="testimonial-author2">
            <span className="testimonial-text1">{props.name}</span>
            <span className="testimonial-text2">{props.position}</span>
          </div>
          <img
            alt="TwitterX6986"
            src="/external/twitterx6986-gtm.svg"
            className="testimonial-twitter-x"
          />
        </div>
        <span className="testimonial-content">{props.text}</span>
      </div>
    </div>
  )
}

Testimonial.defaultProps = {
  name: 'Polygon',
  position: '@0xPolygon',
  logopolygonSrc: '/external/logopolygon6986-7mvm.svg',
  text: 'Copy-pasting your code — yes it’s that easy. Scaling is smoother than teflon with EVM-equivalence. @gateway_eth was able to port their public RPC in six minutes',
  rootClassName: '',
}

Testimonial.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  logopolygonSrc: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Testimonial
