import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Tag from '../../components/tag'
import Footer from '../../components/footer'
import './blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar.css'

const BlogGatewayfmAnnouncesRoleInBuildingPublicSorobanRPCInfrastructureForStellar =
  (props) => {
    return (
      <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-container1">
        <Helmet>
          <title>
            Gateway.fm | Building Public Soroban RPC Infrastructure for Stellar
          </title>
          <meta
            name="description"
            content="Explore how Gateway.fm is developing the public Soroban RPC infrastructure for Stellar blockchain, enhancing accessibility and functionality for developers and users alike."
          />
          <meta
            property="og:title"
            content="Gateway.fm | Building Public Soroban RPC Infrastructure for Stellar"
          />
          <meta
            property="og:description"
            content="Explore how Gateway.fm is developing the public Soroban RPC infrastructure for Stellar blockchain, enhancing accessibility and functionality for developers and users alike."
          />
          <meta
            property="og:image"
            content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/343f88fc-5e77-45a7-8c77-bb5e839fb1de?org_if_sml=1&amp;q=80&amp;force_format=original"
          />
        </Helmet>
        <Navigation rootClassName="navigationroot-class-name26"></Navigation>
        <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-container2">
          <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-post">
            <img
              alt="Gateway.fm and Stellar."
              src="/external/blog-banners/gateway-stellar-partnership-1200w.webp"
              className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-image"
            />
            <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text10">
              <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-container3">
                <div className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-tags">
                  <Tag text="latest"></Tag>
                  <Tag text="guide"></Tag>
                </div>
                <span className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text11">
                  Gateway.fm Announces Role in Building Public Soroban RPC
                  Infrastructure for Stellar
                </span>
                <span className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text12">
                  7 of May 2024
                </span>
                <span className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text13">
                  <span>
                    We&apos;re proud to highlight the successful partnership
                    between
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <a
                    href="https://lukso.network/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    LUKSO
                  </a>
                  <span>
                    {' '}
                    and Gateway.fm. LUKSO is a next generation EVM blockchain
                    company based on Casper PoS. They provide a platform
                    catering to simplify interactions in the New Creative
                    Economies - meaning economies that encompass fashion, art,
                    music, entertainment, social media and beyond.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    LUKSO was founded by former Ethereum Developer Fabian
                    Vogelsteller known for his instrumental role in early
                    Ethereum development, ERC-20 among other projects. And brand
                    expert Marjorie Hernandez who has multi-faced expertise in
                    brand innovation, brand strategy and architecture.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    As part of this partnership Gateway.fm provided LUKSO dApp
                    developers with robust RPC infrastructure, offering both
                    testnet and mainnet to shared and dedicated nodes. Our
                    infrastructure, hosted in independent data centers, ensured
                    maximum security and decentralization for the LUKSO network
                    maintaining reliability and performance. Providing them with
                    the reliable and high-performing infrastructure needed to
                    build and deploy applications effectively.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    By working together, Gateway.fm and LUKSO contributed to
                    creating a more robust ecosystem, empowering developers to
                    fully leverage blockchain technology. We&apos;re pleased to
                    have played a role in supporting LUKSO&apos;s mission and
                    facilitating the growth of its developer community. As
                    Gateway.fm continues to prioritize excellence in service
                    delivery, we remain committed to upholding the highest
                    standards of security and decentralization for all networks
                    we support.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text27">
                    Fabian Vogelsteller
                  </span>
                  <span>, Founder of LUKSO:</span>
                  <br></br>
                  <span className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text30">
                    “The interests between LUKSO and Gateway are aligned, and we
                    are both on a mission to make the Web3 ecosystem as
                    accessible as Web2. Their team&apos;s extensive
                    infrastructure knowledge and core development experience
                    will be invaluable in providing public RPC endpoints for our
                    Testnet and Mainnet, and we are very excited to work with
                    Temoc, Igor and the stellar Gateway team in the months
                    ahead.”
                  </span>
                  <br></br>
                  <br></br>
                  <span className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text33">
                    Temoc Weber
                  </span>
                  <span>, CEO of Gateway.fm:</span>
                  <br></br>
                  <span className="blog-gatewayfm-announces-role-in-building-public-soroban-rpc-infrastructure-for-stellar-text36">
                    “Collaborating with LUKSO is a signature partnership for
                    Gateway, and represents a strong vote of confidence from one
                    of the core shapers of the Ethereum ecosystem Fabian
                    Vogelsteller. We continue to set a new standard for building
                    bespoke infrastructure solutions for our growing roster of
                    partners, and we are always on the lookout for ambitious
                    Web3 projects who share our vision for true
                    decentralization.”
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
        <Footer rootClassName="footerroot-class-name25"></Footer>
      </div>
    )
  }

export default BlogGatewayfmAnnouncesRoleInBuildingPublicSorobanRPCInfrastructureForStellar
