import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import HeroCareerDescription from '../../components/hero-career-description'
import FormUpload from '../../components/form-upload'
import Footer from '../../components/footer'
import './career-business-development-marketing-manager.css'

const CareerBusinessDevelopmentMarketingManager = (props) => {
  return (
    <div className="career-business-development-marketing-manager-container1">
      <Helmet>
        <title>Gateway.fm | Explore Career Opportunities</title>
        <meta
          name="description"
          content="Discover exciting career opportunities at Gateway.fm. Join us in innovating the future of blockchain technology and making an impact in the industry."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Explore Career Opportunities"
        />
        <meta
          property="og:description"
          content="Discover exciting career opportunities at Gateway.fm. Join us in innovating the future of blockchain technology and making an impact in the industry."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/74a82d50-4655-4866-8511-f0f3e8128f59?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name40"></Navigation>
      <div className="career-business-development-marketing-manager-container2">
        <div className="career-business-development-marketing-manager-career-hero">
          <HeroCareerDescription
            header="Business development marketing manager"
            rootClassName="hero-career-descriptionroot-class-name3"
          ></HeroCareerDescription>
          <FormUpload rootClassName="form-uploadroot-class-name3"></FormUpload>
        </div>
        <div className="career-business-development-marketing-manager-post">
          <div className="career-business-development-marketing-manager-text10">
            <div className="career-business-development-marketing-manager-container3">
              <span className="career-business-development-marketing-manager-text11">
                About Gateway.FM
              </span>
              <span className="career-business-development-marketing-manager-text12">
                <span>
                  Gateway.fm is a forward-thinking and dynamic team comprised of
                  seasoned professionals committed to pioneering the development
                  of the next generation of web3 infrastructure and tooling
                  products. With a strategic blend of expertise in blockchain,
                  fintech, information security, and telecommunications,
                  Gateway.fm stands at the forefront of innovation within the
                  rapidly evolving web3 space. The company&apos;s dedication to
                  excellence is evident in its relentless pursuit of
                  groundbreaking solutions. By leveraging their in-depth
                  knowledge across multiple domains, Gateway.fm aims to create
                  and deliver innovative products that not only meet the demands
                  of the current digital landscape but also position the company
                  as a leader within the dynamic and transformative web3
                  ecosystem.
                </span>
                <br></br>
                <br></br>
                <span>
                  At the core of Gateway.fm mission is a commitment to staying
                  ahead of industry trends and pushing the boundaries of
                  what&apos;s possible in web3 technology. This vision is
                  underpinned by a collaborative and driven team, whose
                  collective skills and passion drive the company towards
                  shaping the future of web3 infrastructure and tooling. In a
                  landscape characterized by rapid change, Gateway.fm remains
                  agile, adaptive, and poised to make significant contributions
                  to the ongoing evolution of the digital landscape.
                </span>
                <br></br>
              </span>
              <span className="career-business-development-marketing-manager-text18">
                Announcement
              </span>
              <span className="career-business-development-marketing-manager-text19">
                <span>
                  We are excited to announce an opportunity for a Business
                  Development Marketing Manager. We&apos;re the team behind
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <Link
                  to="/presto"
                  className="career-business-development-marketing-manager-navlink"
                >
                  Presto
                </Link>
                <span>
                  , a robust platform that empowers users to deploy private
                  zkEVM roll-ups in just a few minutes. If you&apos;re
                  passionate about blockchain technology and eager to contribute
                  to a dynamic, innovative team, we&apos;d love to hear from
                  you.
                </span>
                <br></br>
                <br></br>
                <span>
                  We’re looking for a battle-tested, data-driven Marketing
                  Manager to help us engagingly communicate our vision and grow
                  its reach. In this role, you will have the autonomy to
                  implement practices from scratch, closely collaborating both
                  with Gateway.fm&apos;s internal teams and the vibrant
                  ecosystem of our partners (Gnosis, Polygon, Arbitrum,
                  Optimism, NEAR, and many more).
                </span>
                <br></br>
                <br></br>
                <span>
                  You will also devise and implement strategies to boost brand
                  awareness, increase social media engagement, and facilitate
                  community growth, not only for the Gateway brand but for our
                  sub-brands as well (Presto Platform, Stakeway).
                </span>
                <br></br>
                <span>
                  We don&apos;t expect you to be an established crypto
                  superhero, however - a deep passion for learning and
                  meaningfully interacting with Web3 communities is required.
                </span>
                <br></br>
              </span>
              <span className="career-business-development-marketing-manager-text31">
                Key responsibilities
              </span>
              <span className="career-business-development-marketing-manager-text32">
                <span>
                  •​​ Social Media and SEO: execute cohesive content and SEO
                  campaigns, create and publish engaging content, curate
                  educational blog posts and PR materials, organize AMA and
                  podcast sessions;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Brand Awareness: live and breathe crypto communities to
                  develop resonating narratives, effectively capturing and
                  growing various aspects of the Gateway brand;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Partnership Campaigns: proactively communicate with our
                  partners and take ownership of co-joint, long-term marketing
                  campaigns and ephemeral activities across different channels
                  (social media, events, technical articles, etc.);
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Performance Analytics and Optimization: continuously monitor
                  data insights to analyze, report on, and propose performance
                  optimization of all marketing verticals;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Cross-sector Collaboration: work closely with the Design team
                  on producing materials (swag, sponsorship booth banners,
                  etc.), cater to the marketing needs of other internal teams
                  (Engineering, Business Development, Talent), manage end-to-end
                  collaboration with external marketing agencies (paid, organic
                  traffic);
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Event Management Support: provide strategic input and
                  logistical support to ensure world-class company presence and
                  visibility at various events: conferences, side events,
                  hackathons, virtual summits, and more.
                </span>
                <br></br>
              </span>
              <span className="career-business-development-marketing-manager-text50">
                Qualifications
              </span>
              <span className="career-business-development-marketing-manager-text51">
                <span>
                  •​​ 1-3 years of experience utilizing marketing tools to build
                  technical communities at scale;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Proven track record in leading and managing marketing
                  initiatives from start to finish;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Excellent command of social media platforms (X, Discord,
                  LinkedIn) and complementary tools (HubSpot, Notion, Figma,
                  Google Analytics);
                </span>
                <br></br>
                <span>
                  •​​ Good understanding of SEO and digital B2B marketing;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Exceptional written and oral communication skills, with a
                  sensibility for interpersonal dynamics, different cultures,
                  and diverse customer motivations;
                </span>
                <br></br>
                <span>•​​ S</span>
                <span>
                  elf-motivated and well-organized with a growth-hacking
                  mindset, always emphasizing autonomy and accountability;
                </span>
                <br></br>
                <span>
                  •​​ Passion for learning peculiarities of the broader Web3
                  ecosystem and narratives around it;
                </span>
                <br></br>
                <span>
                  •​​
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Experience working for a Web3 company or being crypto-native
                  would be a significant plus, but is not a requirement.
                </span>
                <br></br>
              </span>
              <span className="career-business-development-marketing-manager-text73">
                <span>
                  Gateway.fm is committed to fostering a diverse and inclusive
                  environment. We encourage applicants of all backgrounds,
                  cultures, genders, experiences, abilities, and perspectives to
                  apply.
                </span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name39"></Footer>
    </div>
  )
}

export default CareerBusinessDevelopmentMarketingManager
