import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ButtonSecondary from '../components/button-secondary'
import ContentNumber from '../components/content-number'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import SubtextProductPromo from '../components/subtext-product-promo'
import CardBlueprint from '../components/card-blueprint'
import ButtonSmallMain from '../components/button-small-main'
import AnnouncementSmall from '../components/announcement-small'
import Testimonial from '../components/testimonial'
import Footer from '../components/footer'
import './presto.css'

const Presto = (props) => {
  return (
    <div className="presto-container10">
      <Helmet>
        <title>Gateway.fm | Presto Blockchain Solutions</title>
        <meta
          name="description"
          content="Discover Presto by Gateway.fm, offering innovative blockchain solutions tailored to enhance efficiency and scalability in decentralized applications (DApps)."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Presto Blockchain Solutions"
        />
        <meta
          property="og:description"
          content="Discover Presto by Gateway.fm, offering innovative blockchain solutions tailored to enhance efficiency and scalability in decentralized applications (DApps)."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/8c1945b7-f994-4470-b120-23f05ed47d10?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name"></Navigation>
      <div className="presto-content1">
        <div className="presto-hero">
          <div className="presto-description">
            <div className="presto-title">
              <TitleProductHero text="DEPLOY YOUR CHAIN. PRESTO"></TitleProductHero>
              <SubtitleProductHero text="Layer 2 rollups in under 10 minutes"></SubtitleProductHero>
            </div>
            <div className="presto-us-ps">
              <USPCheckmark text="35+ customisations"></USPCheckmark>
              <USPCheckmark text="L2s &amp; L3s: Rollups and validiums"></USPCheckmark>
              <USPCheckmark text="zk proofs for ultimate security"></USPCheckmark>
            </div>
            <div className="presto-buttons">
              <a
                href="https://presto.gateway.fm"
                target="_blank"
                rel="noreferrer noopener"
                className="presto-link1"
              >
                <ButtonPrimarySubtitle
                  main="Deploy blockchain"
                  supportive="Free trial, no credit card required"
                  className="presto-component16"
                ></ButtonPrimarySubtitle>
              </a>
              <a href="#blueprints" className="presto-link2">
                <ButtonSecondary
                  subtext="DEX, NFT platform, Digital bank &amp; more"
                  className="presto-component17"
                ></ButtonSecondary>
              </a>
            </div>
          </div>
        </div>
        <div className="presto-container11">
          <div className="presto-container12">
            <div className="presto-content2">
              <ContentNumber></ContentNumber>
              <ContentNumber
                text="Service Level Agreement"
                number="99.9%"
              ></ContentNumber>
              <ContentNumber text="Customisations" number="35+"></ContentNumber>
            </div>
          </div>
        </div>
        <div className="presto-video1">
          <div className="presto-play1">
            <img
              alt="play7309"
              src="/external/play7309-gpk.svg"
              className="presto-play2"
            />
            <span className="presto-text10">
              <span>0:59</span>
            </span>
          </div>
          <div className="presto-video-container">
            <video
              src="/external/presto-walkthrough-preview1.mp4"
              loop="true"
              muted="true"
              poster="/external/poster-frame1-200h.png"
              preload="auto"
              autoPlay="true"
              playsInline="true"
              className="presto-video2"
            ></video>
          </div>
        </div>
        <SubtitleProductPromo text="INTEGRATIONS"></SubtitleProductPromo>
        <div className="presto-content3">
          <div className="presto-frame481909">
            <span className="presto-text12">Software stack</span>
            <div className="presto-frame48190210">
              <div className="presto-frame48190010">
                <img
                  alt="logopolygon7301"
                  src="/external/logos/logo-polygon1-200h.png"
                  className="presto-logopolygon"
                />
                <span className="presto-text13">Polygon</span>
              </div>
              <div className="presto-frame48190110">
                <img
                  alt="logooptimism7301"
                  src="/external/logos/logo-optimism1-200h.png"
                  className="presto-logooptimism1"
                />
                <span className="presto-text14">Optimism</span>
              </div>
              <div className="presto-frame48190111">
                <img
                  alt="logooptimism7301"
                  src="/external/logos/logo-zk-sync-200h.png"
                  className="presto-logooptimism2"
                />
                <span className="presto-text15">zkSync</span>
              </div>
              <div className="presto-frame48190112">
                <img
                  alt="logooptimism7301"
                  src="/external/logos/logo-arbitrum-200h.png"
                  className="presto-logooptimism3"
                />
                <span className="presto-text16">Arbitrum</span>
              </div>
              <div className="presto-frame48190113">
                <img
                  alt="logooptimism7301"
                  src="/external/logos/logo-immutable-200h.png"
                  className="presto-logooptimism4"
                />
                <span className="presto-text17">Immutable</span>
              </div>
            </div>
          </div>
          <div className="presto-frame481906">
            <span className="presto-text18">Data availability</span>
            <div className="presto-frame48190211">
              <div className="presto-frame48190011">
                <img
                  alt="logonear7301"
                  src="/external/logos/logo-near1-200h.png"
                  className="presto-logonear"
                />
                <span className="presto-text19">NEAR</span>
              </div>
              <div className="presto-frame48190114">
                <img
                  alt="logocelestia7301"
                  src="/external/logos/logo-celestia1-200h.png"
                  className="presto-logocelestia"
                />
                <span className="presto-text20">Celestia</span>
              </div>
              <div className="presto-frame4819031">
                <div className="presto-logodomicon">
                  <img
                    alt="domicon17301"
                    src="/external/domicon17301-cqfq-200h.png"
                    className="presto-domicon1"
                  />
                </div>
                <span className="presto-text21">Domicon</span>
              </div>
            </div>
          </div>
          <div className="presto-frame481911">
            <span className="presto-text22">Account abstraction</span>
            <div className="presto-frame48190212">
              <div className="presto-frame48190012">
                <img
                  alt="logosafe7301"
                  src="/external/logos/logo-safe1-200h.png"
                  className="presto-logosafe"
                />
                <span className="presto-text23">Safe</span>
              </div>
              <div className="presto-frame48190115">
                <img
                  alt="logobiconomy7301"
                  src="/external/logos/logo-biconomy-200h.png"
                  className="presto-logobiconomy"
                />
                <span className="presto-text24">Biconomy</span>
              </div>
              <div className="presto-frame48190213">
                <img
                  alt="logoden7301"
                  src="/external/logos/logo-den-200h.png"
                  className="presto-logoden"
                />
                <span className="presto-text25">Den</span>
              </div>
            </div>
          </div>
          <div className="presto-frame48190013">
            <span className="presto-text26">Block explorers</span>
            <div className="presto-frame48190214">
              <div className="presto-frame48190014">
                <img
                  alt="logoblockscout7301"
                  src="/external/logos/logo-blockscout-200h.png"
                  className="presto-logoblockscout"
                />
                <span className="presto-text27">Blockscout</span>
              </div>
              <div className="presto-frame48190116">
                <img
                  alt="logodora7301"
                  src="/external/logos/logo-dora1-200h.png"
                  className="presto-logodora"
                />
                <span className="presto-text28">Dora</span>
              </div>
            </div>
          </div>
          <div className="presto-frame481905">
            <span className="presto-text29">Infrastructure</span>
            <div className="presto-frame48190215">
              <div className="presto-frame48190117">
                <img
                  alt="logogevulot7301"
                  src="/external/logos/logo-btq-zk-proofs-200h.png"
                  className="presto-logogevulot1"
                />
                <span className="presto-text30">BTQ</span>
              </div>
              <div className="presto-frame48190118">
                <img
                  alt="logogevulot7301"
                  src="/external/logos/logo-gevulot-200h.png"
                  className="presto-logogevulot2"
                />
                <span className="presto-text31">Gevulot</span>
              </div>
            </div>
          </div>
          <div className="presto-frame48190119">
            <span className="presto-text32">Oracles</span>
            <div className="presto-frame48190216">
              <div className="presto-frame48190015">
                <img
                  alt="logoredstone7309"
                  src="/external/logos/logo-redstone-200h.png"
                  className="presto-logoredstone1"
                />
                <span className="presto-text33">RedStone</span>
              </div>
              <div className="presto-frame48190016">
                <img
                  alt="logoredstone7309"
                  src="/external/logos/logo-api3-200h.png"
                  className="presto-logoredstone2"
                />
                <span className="presto-text34">API3</span>
              </div>
            </div>
          </div>
          <div className="presto-frame48190217">
            <span className="presto-text35">Bridges</span>
            <div className="presto-frame48190218">
              <div className="presto-frame48190017">
                <img
                  alt="logoaxelar7301"
                  src="/external/logos/logo-axelar-200h.png"
                  className="presto-logoaxelar"
                />
                <span className="presto-text36">Axelar</span>
              </div>
              <div className="presto-frame48190120">
                <img
                  alt="logozkevm7301"
                  src="/external/logos/logo-zkevm1-200h.png"
                  className="presto-logozkevm"
                />
                <span className="presto-text37">Native zkEVM</span>
              </div>
            </div>
          </div>
          <div className="presto-frame481912">
            <span className="presto-text38">Security</span>
            <div className="presto-frame48190219">
              <div className="presto-frame48190018">
                <div className="presto-logosub7">
                  <div className="presto-maskgroup">
                    <img
                      alt="QIxcOqYERmwC85RhvBu4yYHYOU17301"
                      src="/external/logos/logo-sub71-200h.png"
                      className="presto-q-ixc-oq-ye-rmw-c85-rhv-bu4y-yhyou1"
                    />
                  </div>
                </div>
                <span className="presto-text39">Sub7</span>
              </div>
              <div className="presto-frame48190121">
                <img
                  alt="logoquantstamp7301"
                  src="/external/logos/logo-quantstamp-200h.png"
                  className="presto-logoquantstamp"
                />
                <span className="presto-text40">Quantstamp</span>
              </div>
              <div className="presto-frame48190220">
                <img
                  alt="logooxorio7301"
                  src="/external/logos/logo-oxorio-200h.png"
                  className="presto-logooxorio"
                />
                <span className="presto-text41">Oxorio</span>
              </div>
              <div className="presto-frame4819032">
                <img
                  alt="logoshieldifysecurity7301"
                  src="/external/logos/logo-shieldify-security-200h.png"
                  className="presto-logoshieldifysecurity"
                />
                <span className="presto-text42">Shieldify</span>
              </div>
            </div>
          </div>
          <div className="presto-frame4819033">
            <span className="presto-text43">Data centers</span>
            <div className="presto-frame48190221">
              <div className="presto-frame48190019">
                <img
                  alt="logoaws7301"
                  src="/external/logos/logo-aws-200h.png"
                  className="presto-logoaws"
                />
                <span className="presto-text44">AWS</span>
              </div>
              <div className="presto-frame48190122">
                <img
                  alt="logogooglecloudgcp7301"
                  src="/external/logos/logo-google-cloud-gcp-200h.png"
                  className="presto-logogooglecloudgcp"
                />
                <span className="presto-text45">GCP</span>
              </div>
              <div className="presto-frame48190222">
                <img
                  alt="logomicrosoftazure7301"
                  src="/external/logos/logo-microsoft-azure-200h.png"
                  className="presto-logomicrosoftazure"
                />
                <span className="presto-text46">Azure</span>
              </div>
              <div className="presto-frame4819034">
                <img
                  alt="logobaremetal7301"
                  src="/external/logos/logo-bare-metal-200h.png"
                  className="presto-logobaremetal"
                />
                <span className="presto-text47">Bare Metal</span>
              </div>
            </div>
          </div>
          <div className="presto-frame481899">
            <span className="presto-text48">Indexers</span>
            <div className="presto-frame48190223">
              <div className="presto-frame48190123">
                <img
                  alt="logogoldsky7301"
                  src="/external/logos/logo-goldsky1-200h.png"
                  className="presto-logogoldsky1"
                />
                <span className="presto-text49">Goldsky</span>
              </div>
              <div className="presto-frame48190124">
                <img
                  alt="logogoldsky7301"
                  src="/external/logos/logo-the-graph-200h.png"
                  className="presto-logogoldsky2"
                />
                <span className="presto-text50">The Graph</span>
              </div>
            </div>
          </div>
          <div className="presto-frame481907">
            <span className="presto-text51">Wallets</span>
            <div className="presto-frame48190224">
              <div className="presto-frame48190020">
                <img
                  alt="logomagiclink7301"
                  src="/external/logos/logo-magic-link-200h.png"
                  className="presto-logomagiclink"
                />
                <span className="presto-text52">Magic link</span>
              </div>
              <div className="presto-frame48190225">
                <img
                  alt="logometamask7301"
                  src="/external/logos/logo-metamask-200h.png"
                  className="presto-logometamask"
                />
                <span className="presto-text53">MetaMask</span>
              </div>
            </div>
          </div>
          <div className="presto-frame481908">
            <span className="presto-text54">KYC/KYB</span>
            <div className="presto-frame48190226">
              <div className="presto-frame48190021">
                <img
                  alt="logoamlbot7301"
                  src="/external/logos/logo-aml-bot1-200h.png"
                  className="presto-logoamlbot"
                />
                <span className="presto-text55">AMLBot</span>
              </div>
              <div className="presto-frame48190125">
                <div className="presto-logogatenox">
                  <img
                    alt="z50vLHlz400x400217301"
                    src="/external/z50vlhlz400x400217301-nwvr-200h.png"
                    className="presto-z50v-l-hlz400x40021"
                  />
                </div>
                <span className="presto-text56">Gatenox</span>
              </div>
            </div>
          </div>
        </div>
        <div id="blueprints" className="presto-container13">
          <SubtitleProductPromo text="BLUEPRINTS"></SubtitleProductPromo>
          <SubtextProductPromo></SubtextProductPromo>
          <div className="presto-container14">
            <Link
              to="/web3-infrastructure-blueprint-digital-banking"
              className="presto-navlink10"
            >
              <CardBlueprint
                image="/external/digital-banking-200h.png"
                className="presto-component24"
              ></CardBlueprint>
            </Link>
            <Link
              to="/web3-infrastructure-blueprint-supply-chain"
              className="presto-navlink11"
            >
              <CardBlueprint
                text="Enhance supply chain transparency, traceability, and efficiency"
                usp1="End-to-end product traceability"
                usp2="Facilitate trusted data sharing"
                usp3="No code. Zero knowledge"
                image="/external/blueprints/cargo-200h.png"
                subtext="Supply chain"
                className="presto-component25"
              ></CardBlueprint>
            </Link>
            <Link
              to="/web3-infrastructure-blueprint-carbon-credits"
              className="presto-navlink12"
            >
              <CardBlueprint
                text="Tokenize and trade carbon credits on a transparent and immutable ledger"
                usp1="Enable trusted carbon accounting"
                usp2="Facilitate liquid carbon markets"
                usp3="No code. Zero knowledge"
                image="/external/blueprints/leaf-200h.png"
                subtext="Carbon credits"
                className="presto-component26"
              ></CardBlueprint>
            </Link>
          </div>
          <div id="hidden-blueprints" className="presto-container15">
            <div className="presto-container16">
              <Link
                to="/web3-infrastructure-blueprint-accounting-ledger"
                className="presto-navlink13"
              >
                <CardBlueprint
                  text="Distributed ledger for transparent and auditable financial records"
                  usp1="Ensure data integrity and auditability"
                  usp2="Streamline reconciliation processes"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/accountingledger-200h.png"
                  subtext="Accounting ledger"
                  className="presto-component27"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-decentralised-exchange"
                className="presto-navlink14"
              >
                <CardBlueprint
                  text="Facilitate peer-to-peer trading of digital assets without intermediaries"
                  usp1="Enable trustless trading"
                  usp2="Maintain user control and custody"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/dex-200h.png"
                  subtext="DEX"
                  className="presto-component28"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-digital-twins"
                className="presto-navlink15"
              >
                <CardBlueprint
                  text="Create virtual replicas of physical assets, processes, or systems"
                  usp1="Enhance operational efficiency"
                  usp2="Enable predictive maintenance"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/digitaltwin-200h.png"
                  subtext="Digital twins"
                  className="presto-component29"
                ></CardBlueprint>
              </Link>
            </div>
            <div className="presto-container17">
              <Link
                to="/web3-infrastructure-blueprint-loyalty-program"
                className="presto-navlink16"
              >
                <CardBlueprint
                  text="Reward customer loyalty with tokenized incentives and rewards"
                  usp1="True ownership and portability"
                  usp2="Composable, interoperable rewards"
                  image="/external/blueprints/loyaltyprogram-200h.png"
                  subtext="Loyalty program"
                  className="presto-component30"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-viral-digital-asset"
                className="presto-navlink17"
              >
                <CardBlueprint
                  text="Create and trade digital assets representing iconic moments"
                  usp1="Tokenize viral content"
                  usp2="Enable digital ownership and scarcity"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/memecoin-200h.png"
                  subtext="Viral digital asset"
                  className="presto-component31"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-nft-platform"
                className="presto-navlink18"
              >
                <CardBlueprint
                  text="Deploy your own NFT platform in 3 months"
                  usp1="Low-cost minting"
                  usp2="Seamless Web3 Integration"
                  usp3="White-Label NFT Marketplaces"
                  image="/external/artobject17309-zntb-200h.png"
                  subtext="NFT Platform"
                  className="presto-component32"
                ></CardBlueprint>
              </Link>
            </div>
            <div className="presto-container18">
              <Link
                to="/web3-infrastructure-blueprint-nft-gallery"
                className="presto-navlink19"
              >
                <CardBlueprint
                  text="Showcase and trade unique digital art and collectibles as non-fungible tokens"
                  usp1="Enable digital scarcity and provenance"
                  usp2="Foster new art ecosystems"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/nftgallery-200h.png"
                  subtext="NFT Gallery"
                  rootClassName="card-blueprintroot-class-name4"
                  className="presto-component33"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-dao"
                className="presto-navlink20"
              >
                <CardBlueprint
                  text="Manage organisations, grants, investments by your hive-mind"
                  usp1="Enable trustless trading"
                  usp2="Maintain user control and custody"
                  usp3="No code. Zero knowledge"
                  image="/external/hive26986-lpud-200h.png"
                  subtext="DAO"
                  rootClassName="card-blueprintroot-class-name5"
                  className="presto-component34"
                ></CardBlueprint>
              </Link>
            </div>
          </div>
          <ButtonSmallMain
            divID="toggleButton"
            label="Show more"
            rootClassName="button-small-mainroot-class-name2"
          ></ButtonSmallMain>
        </div>
        <div id="blueprints" className="presto-container19">
          <SubtitleProductPromo text="BLUEPRINTS"></SubtitleProductPromo>
          <SubtextProductPromo></SubtextProductPromo>
          <div className="presto-container20">
            <Link
              to="/web3-infrastructure-blueprint-digital-banking"
              className="presto-navlink21"
            >
              <CardBlueprint
                image="/external/digital-banking-200h.png"
                className="presto-component38"
              ></CardBlueprint>
            </Link>
            <Link
              to="/web3-infrastructure-blueprint-supply-chain"
              className="presto-navlink22"
            >
              <CardBlueprint
                text="Enhance supply chain transparency, traceability, and efficiency"
                usp1="End-to-end product traceability"
                usp2="Facilitate trusted data sharing"
                usp3="No code. Zero knowledge"
                image="/external/blueprints/cargo-200h.png"
                subtext="Supply chian"
                className="presto-component39"
              ></CardBlueprint>
            </Link>
            <Link
              to="/web3-infrastructure-blueprint-carbon-credits"
              className="presto-navlink23"
            >
              <CardBlueprint
                text="Tokenize and trade carbon credits on a transparent and immutable ledger"
                usp1="Enable trusted carbon accounting"
                usp2="Facilitate liquid carbon markets"
                usp3="No code. Zero knowledge"
                image="/external/blueprints/leaf-200h.png"
                subtext="Carbon credits"
                className="presto-component40"
              ></CardBlueprint>
            </Link>
          </div>
          <div id="hidden-blueprints" className="presto-container21">
            <div className="presto-container22">
              <Link
                to="/web3-infrastructure-blueprint-accounting-ledger"
                className="presto-navlink24"
              >
                <CardBlueprint
                  text="Distributed ledger for transparent and auditable financial records"
                  usp1="Ensure data integrity and auditability"
                  usp2="Streamline reconciliation processes"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/accountingledger-200h.png"
                  subtext="Accounting ledger"
                  className="presto-component41"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-decentralised-exchange"
                className="presto-navlink25"
              >
                <CardBlueprint
                  text="Facilitate peer-to-peer trading of digital assets without intermediaries"
                  usp1="Enable trustless trading"
                  usp2="Maintain user control and custody"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/dex-200h.png"
                  subtext="DEX"
                  className="presto-component42"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-digital-twins"
                className="presto-navlink26"
              >
                <CardBlueprint
                  text="Create virtual replicas of physical assets, processes, or systems"
                  usp1="Enhance operational efficiency"
                  usp2="Enable predictive maintenance"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/digitaltwin-200h.png"
                  subtext="Digital twins"
                  className="presto-component43"
                ></CardBlueprint>
              </Link>
            </div>
            <div className="presto-container23">
              <Link
                to="/web3-infrastructure-blueprint-loyalty-program"
                className="presto-navlink27"
              >
                <CardBlueprint
                  text="Reward customer loyalty with tokenized incentives and rewards"
                  usp1="True ownership and portability"
                  usp2="Composable, interoperable rewards"
                  image="/external/blueprints/loyaltyprogram-200h.png"
                  subtext="Loyalty program"
                  className="presto-component44"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-viral-digital-asset"
                className="presto-navlink28"
              >
                <CardBlueprint
                  text="Create and trade digital assets representing iconic moments"
                  usp1="Tokenize viral content"
                  usp2="Enable digital ownership and scarcity"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/memecoin-200h.png"
                  subtext="Viral digital asset"
                  className="presto-component45"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-nft-platform"
                className="presto-navlink29"
              >
                <CardBlueprint
                  text="Deploy your own NFT platform in 3 months"
                  usp1="Low-cost minting"
                  usp2="Seamless Web3 Integration"
                  usp3="White-Label NFT Marketplaces"
                  image="/external/artobject17309-zntb-200h.png"
                  subtext="NFT Platform"
                  className="presto-component46"
                ></CardBlueprint>
              </Link>
            </div>
            <div className="presto-container24">
              <Link
                to="/web3-infrastructure-blueprint-nft-gallery"
                className="presto-navlink30"
              >
                <CardBlueprint
                  text="Showcase and trade unique digital art and collectibles as non-fungible tokens"
                  usp1="Enable digital scarcity and provenance"
                  usp2="Foster new art ecosystems"
                  usp3="No code. Zero knowledge"
                  image="/external/blueprints/nftgallery-200h.png"
                  subtext="NFT Gallery"
                  rootClassName="card-blueprintroot-class-name6"
                  className="presto-component47"
                ></CardBlueprint>
              </Link>
              <Link
                to="/web3-infrastructure-blueprint-dao"
                className="presto-navlink31"
              >
                <CardBlueprint
                  text="Manage organisations, grants, investments by your hive-mind"
                  usp1="Enable trustless trading"
                  usp2="Maintain user control and custody"
                  usp3="No code. Zero knowledge"
                  image="/external/hive26986-lpud-200h.png"
                  subtext="DAO"
                  rootClassName="card-blueprintroot-class-name7"
                  className="presto-component48"
                ></CardBlueprint>
              </Link>
            </div>
          </div>
          <ButtonSmallMain
            divID="toggleButton"
            label="Show more"
            rootClassName="button-small-mainroot-class-name4"
          ></ButtonSmallMain>
        </div>
        <SubtitleProductPromo></SubtitleProductPromo>
        <Link to="/blog/gateway-wirex-case-study" className="presto-navlink32">
          <AnnouncementSmall
            text="We enabled Wirex to elevate its zk-powered network"
            rootClassName="announcement-smallroot-class-name8"
            className="presto-component51"
          ></AnnouncementSmall>
        </Link>
        <div className="presto-testimonials">
          <Testimonial
            name="Wirex"
            text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
            position="@wirexapp"
            rootClassName="testimonialroot-class-name24"
            logopolygonSrc="/external/vector1317-f6xm.svg"
          ></Testimonial>
          <Testimonial
            name="GPT Protocol"
            text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
            position="@gpt_protocol"
            rootClassName="testimonialroot-class-name25"
            logopolygonSrc="/external/logos/logo-gpt-200w.png"
          ></Testimonial>
          <Testimonial
            name="Dora"
            text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
            position="@SearchOnDora"
            rootClassName="testimonialroot-class-name26"
            logopolygonSrc="/external/logos/logo-dora1-200h.png"
          ></Testimonial>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name4"></Footer>
    </div>
  )
}

export default Presto
