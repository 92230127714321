import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import Tag from '../components/tag'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ImageProductHero from '../components/image-product-hero'
import ContentTitleProduct from '../components/content-title-product'
import CardProductHighlight from '../components/card-product-highlight'
import ContentParagraph from '../components/content-paragraph'
import ContentSmallTitle from '../components/content-small-title'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import AnnouncementSmall from '../components/announcement-small'
import Testimonial from '../components/testimonial'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './web3-infrastructure-blueprint-supply-chain.css'

const Web3InfrastructureBlueprintSupplyChain = (props) => {
  return (
    <div className="web3-infrastructure-blueprint-supply-chain-container1">
      <Helmet>
        <title>Gateway.fm | Web3 Infrastructure Blueprint: Supply Chain</title>
        <meta
          name="description"
          content="Explore Gateway.fm's blueprint for a Web3 supply chain infrastructure, leveraging blockchain technology to enhance transparency, traceability, and efficiency in logistics and inventory management."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Web3 Infrastructure Blueprint: Supply Chain"
        />
        <meta
          property="og:description"
          content="Explore Gateway.fm's blueprint for a Web3 supply chain infrastructure, leveraging blockchain technology to enhance transparency, traceability, and efficiency in logistics and inventory management."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/e3c7c633-fc7d-4955-bd49-f11bd5532537?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name17"></Navigation>
      <div className="web3-infrastructure-blueprint-supply-chain-content">
        <div className="web3-infrastructure-blueprint-supply-chain-hero">
          <div className="web3-infrastructure-blueprint-supply-chain-description">
            <TitleProductHero text="SUPPLY CHAIN"></TitleProductHero>
            <Tag rootClassName="tagroot-class-name4"></Tag>
            <SubtitleProductHero text="Enhance supply chain transparency, traceability, and efficiency"></SubtitleProductHero>
            <USPCheckmark text="Enable end-to-end product traceability"></USPCheckmark>
            <USPCheckmark text="Facilitate trusted data sharing"></USPCheckmark>
            <USPCheckmark text="No code. Zero knowledge"></USPCheckmark>
            <Link
              to="/contact-form"
              className="web3-infrastructure-blueprint-supply-chain-navlink1"
            >
              <ButtonPrimarySubtitle className="web3-infrastructure-blueprint-supply-chain-component17"></ButtonPrimarySubtitle>
            </Link>
          </div>
          <ImageProductHero image="/external/blueprints/cargo-500h.png"></ImageProductHero>
        </div>
        <div className="web3-infrastructure-blueprint-supply-chain-container2">
          <ContentTitleProduct></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-supply-chain-cards">
            <CardProductHighlight
              main="Solving Supply Chain Complexities"
              supportive="Harness the power of blockchain to record every step of the supply chain journey"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Enhancing Transparency"
              supportive="Eliminate the potential for data tampering or manipulation"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Streamlining Processes"
              supportive="Web3 solution streamlines processes by automating critical tasks through smart contracts"
            ></CardProductHighlight>
          </div>
          <ContentTitleProduct text="Summary"></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-supply-chain-container3">
            <span className="web3-infrastructure-blueprint-supply-chain-text">
              Our innovative Web3 supply chain solution utilizes blockchain
              technology to establish a transparent, secure, and decentralized
              ecosystem for tracking goods throughout the entire supply chain
              process. By leveraging smart contracts, cryptographic proofs, and
              a distributed ledger, we create an immutable record of all
              transactions, enabling unparalleled visibility, accountability,
              and trust among stakeholders.
            </span>
          </div>
          <ContentTitleProduct text="Solving Supply Chain Complexities"></ContentTitleProduct>
          <ContentParagraph text="Traditional supply chains often suffer from opaque processes, lack of traceability, and vulnerability to fraud or data manipulation. Our Web3 solution addresses these challenges by harnessing the power of blockchain to record every step of the supply chain journey, from sourcing raw materials to final delivery. Stakeholders can access real-time data, verifying the authenticity and provenance of goods, mitigating risks, and fostering trust within the ecosystem."></ContentParagraph>
          <ContentTitleProduct text="Enhancing Transparency"></ContentTitleProduct>
          <ContentParagraph text="Transparency is a cornerstone of our Web3 supply chain solution. By recording all supply chain events on an immutable, decentralized ledger, we eliminate the potential for data tampering or manipulation. Stakeholders can trace the entire lifecycle of goods, from origin to destination, accessing detailed information such as quality checks, transportation details, and custody transfers. This level of transparency empowers stakeholders to make informed decisions and ensures accountability throughout the supply chain."></ContentParagraph>
          <ContentTitleProduct text="Streamlining Processes"></ContentTitleProduct>
          <ContentParagraph text="Supply chain operations often involve complex workflows and numerous stakeholders, leading to inefficiencies and delays. Our Web3 solution streamlines processes by automating critical tasks through smart contracts. These self-executing contracts automatically trigger predetermined actions based on predefined conditions, reducing manual interventions and minimizing errors. This automation not only enhances operational efficiency but also facilitates faster settlement of payments and disputes, ultimately optimizing the entire supply chain lifecycle."></ContentParagraph>
          <ContentTitleProduct text="Ensuring Product Authenticity"></ContentTitleProduct>
          <ContentParagraph text="Counterfeit products pose significant risks to businesses and consumers alike. Our Web3 solution tackles this challenge by employing advanced cryptographic techniques to create digital twins of physical goods. These digital twins serve as immutable, tamper-proof records, enabling stakeholders to verify the authenticity of products at any point in the supply chain. This approach effectively mitigates the risks associated with counterfeiting, protecting brands and consumer interests."></ContentParagraph>
          <ContentTitleProduct text="Scalable and Interoperable"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 supply chain solution is designed to be highly scalable and interoperable, capable of integrating with existing supply chain management systems and third-party services. By leveraging modular architecture and open standards, our solution can seamlessly connect with various blockchain networks, IoT devices, and enterprise systems, ensuring seamless data exchange and compatibility across diverse technologies and platforms."></ContentParagraph>
          <ContentTitleProduct text="Components"></ContentTitleProduct>
          <ContentSmallTitle
            text="Blockchain Network"
            rootClassName="content-small-titleroot-class-name31"
          ></ContentSmallTitle>
          <ContentParagraph text="A secure, decentralized ledger for recording supply chain events and transactions, leveraging advanced consensus mechanisms and cryptographic techniques."></ContentParagraph>
          <ContentSmallTitle
            text="Smart Contracts"
            rootClassName="content-small-titleroot-class-name32"
          ></ContentSmallTitle>
          <ContentParagraph text="Self-executing contracts that automate workflows, enforce predefined rules, and facilitate trustless interactions among stakeholders."></ContentParagraph>
          <ContentSmallTitle
            text="Oracles"
            rootClassName="content-small-titleroot-class-name33"
          ></ContentSmallTitle>
          <ContentParagraph text="Secure gateways that facilitate the integration of off-chain data, such as IoT sensor readings, quality certifications, and regulatory compliance information, into the blockchain network."></ContentParagraph>
          <ContentSmallTitle
            text="Digital Twin"
            rootClassName="content-small-titleroot-class-name34"
          ></ContentSmallTitle>
          <ContentParagraph text="Tamper-proof digital representations of physical goods, created using advanced cryptographic techniques, enabling product authentication and traceability throughout the supply chain."></ContentParagraph>
          <ContentSmallTitle
            text="Identity Management"
            rootClassName="content-small-titleroot-class-name35"
          ></ContentSmallTitle>
          <ContentParagraph text="A robust identity management system for securely onboarding and managing stakeholder identities, ensuring proper access controls and compliance with regulatory requirements."></ContentParagraph>
          <ContentSmallTitle
            text="Analytics and Reporting"
            rootClassName="content-small-titleroot-class-name36"
          ></ContentSmallTitle>
          <ContentParagraph text="Powerful analytics and reporting tools that leverage the immutable data stored on the blockchain, providing stakeholders with valuable insights and enabling data-driven decision-making."></ContentParagraph>
          <ContentSmallTitle
            text="Integration Layer"
            rootClassName="content-small-titleroot-class-name37"
          ></ContentSmallTitle>
          <ContentParagraph text="A flexible integration layer that enables seamless connectivity with existing supply chain management systems, enterprise resource planning (ERP) systems, and other third-party services, ensuring interoperability and data exchange across diverse platforms."></ContentParagraph>
          <ContentParagraph text="By harnessing the power of Web3 technologies, our supply chain solution delivers unparalleled transparency, efficiency, and trust, empowering stakeholders to optimize their operations, mitigate risks, and foster a collaborative and accountable ecosystem."></ContentParagraph>
        </div>
        <div className="web3-infrastructure-blueprint-supply-chain-container4">
          <SubtitleProductPromo></SubtitleProductPromo>
          <AnnouncementSmall
            text="We enabled Wirex to elevate its zk-powered network"
            rootClassName="announcement-smallroot-class-name1"
            className="web3-infrastructure-blueprint-supply-chain-component51"
          ></AnnouncementSmall>
          <div className="web3-infrastructure-blueprint-supply-chain-testimonials">
            <Testimonial
              name="Wirex"
              text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
              position="@wirexapp"
              rootClassName="testimonialroot-class-name78"
              logopolygonSrc="/external/vector1317-f6xm.svg"
            ></Testimonial>
            <Testimonial
              name="GPT Protocol"
              text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
              position="@gpt_protocol"
              rootClassName="testimonialroot-class-name79"
              logopolygonSrc="/external/logos/logo-gpt-200w.png"
            ></Testimonial>
            <Testimonial
              name="Dora"
              text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
              position="@SearchOnDora"
              rootClassName="testimonialroot-class-name80"
              logopolygonSrc="/external/logos/logo-dora1-200h.png"
            ></Testimonial>
          </div>
        </div>
        <Link
          to="/contact-form"
          className="web3-infrastructure-blueprint-supply-chain-navlink2"
        >
          <Floating
            label="Supply Chain"
            buttonLabel="Get started"
            description="Enhance supply chain transparency, traceability, and efficiency"
            buttonSubtext="Initiate this blueprint"
            rootClassName="floatingroot-class-name3"
            className="web3-infrastructure-blueprint-supply-chain-component55"
          ></Floating>
        </Link>
      </div>
      <Footer rootClassName="footerroot-class-name16"></Footer>
    </div>
  )
}

export default Web3InfrastructureBlueprintSupplyChain
