import React from 'react'

import PropTypes from 'prop-types'

import './title-product-hero.css'

const TitleProductHero = (props) => {
  return (
    <div className="title-product-hero-container">
      <span className="title-product-hero-text">{props.text}</span>
    </div>
  )
}

TitleProductHero.defaultProps = {
  text: 'DAO',
}

TitleProductHero.propTypes = {
  text: PropTypes.string,
}

export default TitleProductHero
