import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import SubtitleProductHero from '../components/subtitle-product-hero'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import CardNumber from '../components/card-number'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import ContentTitleProduct from '../components/content-title-product'
import ContentParagraph from '../components/content-paragraph'
import Footer from '../components/footer'
import './bounties.css'

const Bounties = (props) => {
  return (
    <div className="bounties-container1">
      <Helmet>
        <title>Gateway.fm | Bounty Program: Find Bugs, Earn Rewards!</title>
        <meta
          name="description"
          content="Join Gateway.fm's bounty program to discover and report bugs in our codebase. Earn rewards for contributing to our platform's security and stability."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Bounty Program: Find Bugs, Earn Rewards!"
        />
        <meta
          property="og:description"
          content="Join Gateway.fm's bounty program to discover and report bugs in our codebase. Earn rewards for contributing to our platform's security and stability."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/ea21c2f3-2d68-4703-a0c4-515fda9f82f2?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name3"></Navigation>
      <div className="bounties-content">
        <div className="bounties-hero">
          <div className="bounties-description">
            <TitleProductHero text="BOUNTIES"></TitleProductHero>
            <SubtitleProductHero text="Isolate. Report. Reward!"></SubtitleProductHero>
            <div className="bounties-container2">
              <span className="bounties-text1">
                Find nasty beasties in the code. Isolate, report them. Get your
                bounty!
              </span>
            </div>
            <a
              href="mailto:bounties@gateway.fm?subject=Gateway.fm bounty"
              className="bounties-link1"
            >
              <ButtonPrimarySubtitle
                main="Report a bug"
                supportive="Send us an email"
                className="bounties-component13"
              ></ButtonPrimarySubtitle>
            </a>
          </div>
          <img
            alt="image"
            src="/external/bug-1200w.png"
            className="bounties-image"
          />
        </div>
        <div className="bounties-container3">
          <CardNumber
            text="1"
            text1="Get to the bottom of an issue you found in the code. Locate the root cause and record the way to replicate the issue. Find an example of the bug documentation here."
          ></CardNumber>
          <CardNumber
            text="2"
            text1="Once documented, send us an email to bounties@gateway.fm with a short description of the issue located and a link to the document."
          ></CardNumber>
          <CardNumber
            text="3"
            text1="Once reviewed and confirmed, the issue will be passed on to our development team. And you will receive a bounty according to the severity of the issue."
          ></CardNumber>
        </div>
        <SubtitleProductPromo text="FAQs"></SubtitleProductPromo>
        <ContentTitleProduct
          text="What happens after I submit a report?"
          rootClassName="content-title-productroot-class-name"
        ></ContentTitleProduct>
        <ContentParagraph
          text="Once reviewed and confirmed, the issue will be passed on to our development team. And you will receive a bounty according to the severity of the issue."
          rootClassName="content-paragraphroot-class-name"
        ></ContentParagraph>
        <ContentTitleProduct
          text="How do you evaluate the reward for the bugs?"
          rootClassName="content-title-productroot-class-name2"
        ></ContentTitleProduct>
        <div className="bounties-container4">
          <span className="bounties-text2">
            <span>
              In our bounty management, we follow Google guidelines.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <a
              href="https://bughunters.google.com"
              target="_blank"
              rel="noreferrer noopener"
              className="bounties-link2"
            >
              https://bughunters.google.com
            </a>
          </span>
        </div>
        <ContentTitleProduct
          text="What happens if the same bug is submitted twice?"
          rootClassName="content-title-productroot-class-name3"
        ></ContentTitleProduct>
        <ContentParagraph
          text="The reward is paid to the person who first discovers the issue."
          rootClassName="content-paragraphroot-class-name3"
        ></ContentParagraph>
        <ContentTitleProduct
          text="What is the expected time frame for receiving the reward?"
          rootClassName="content-title-productroot-class-name1"
        ></ContentTitleProduct>
        <ContentParagraph
          text="The timeframe varies based on our team’s workload and the bug’s severity."
          rootClassName="content-paragraphroot-class-name1"
        ></ContentParagraph>
      </div>
      <Footer rootClassName="footerroot-class-name7"></Footer>
    </div>
  )
}

export default Bounties
