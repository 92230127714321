import React from 'react'

import PropTypes from 'prop-types'

import './card-scheme.css'

const CardScheme = (props) => {
  return (
    <div className="card-scheme-container1">
      <div className="card-scheme-container2">
        <div className="card-scheme-texts">
          <span className="card-scheme-text1">{props.label}</span>
          <div className="card-scheme-tags">
            <div className="card-scheme-tag1">
              <span className="card-scheme-text2">{props.tag1}</span>
            </div>
            <div className="card-scheme-tag2">
              <span className="card-scheme-text3">{props.tag2}</span>
            </div>
          </div>
        </div>
        <span className="card-scheme-text4">{props.text}</span>
      </div>
    </div>
  )
}

CardScheme.defaultProps = {
  tag1: 'Rust',
  tag2: 'WebSocket API',
  text: 'Crafted in Rust, known for its performance and safety, ensuring efficient and secure data handling. Features a super-fast data-collection system with a robust websocket (WS) API, optimized for real-time data acquisition. Calibrated for price data feeds but adaptable to various data types, catering to different blockchain requirements.',
  label: 'Data collection daemon',
}

CardScheme.propTypes = {
  tag1: PropTypes.string,
  tag2: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string,
}

export default CardScheme
