import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import NavigationLink from './navigation-link'
import ButtonSmallSecondary from './button-small-secondary'
import ButtonSmallMain from './button-small-main'
import './navigation.css'

const Navigation = (props) => {
  return (
    <div className={`navigation-container1 ${props.rootClassName} `}>
      <div className="navigation-container2">
        <Link to="/" className="navigation-navlink1">
          <div id="flip-card" className="navigation-flip-card">
            <div id="flip-card-inner" className="navigation-flip-card-inner">
              <div id="flip-card-front" className="navigation-flip-card-front">
                <img
                  alt="Frame4814901318"
                  src="/external/frame4814901318-cl4h.svg"
                  className="navigation-frame4814901"
                />
              </div>
              <div id="flip-card-back" className="navigation-flip-card-back">
                <img
                  src="/external/brand-mascot-200h.png"
                  className="navigation-frame4814902"
                />
              </div>
            </div>
          </div>
        </Link>
        <div id="links-block" className="navigation-links-block">
          <div className="navigation-content">
            <div className="navigation-links">
              <Link to="/presto" className="navigation-navlink2">
                <NavigationLink
                  label="Presto"
                  className="navigation-component1"
                ></NavigationLink>
              </Link>
              <a
                href="https://stakeway.com"
                target="_blank"
                rel="noreferrer noopener"
                className="navigation-link1"
              >
                <NavigationLink
                  label="Stakeway"
                  className="navigation-component2"
                ></NavigationLink>
              </a>
              <Link to="/rpc" className="navigation-navlink3">
                <NavigationLink
                  label="RPC"
                  className="navigation-component3"
                ></NavigationLink>
              </Link>
              <Link to="/blog" className="navigation-navlink4">
                <NavigationLink
                  label="Blog"
                  className="navigation-component4"
                ></NavigationLink>
              </Link>
              <Link to="/about" className="navigation-navlink5">
                <NavigationLink
                  label="About"
                  className="navigation-component5"
                ></NavigationLink>
              </Link>
              <a
                href="https://boards.eu.greenhouse.io/gatewayfm"
                target="_blank"
                rel="noreferrer noopener"
                className="navigation-link2"
              >
                <NavigationLink
                  label="Careers"
                  rootClassName="navigation-linkroot-class-name"
                  className="navigation-component6"
                ></NavigationLink>
              </a>
            </div>
            <div className="navigation-sign-in">
              <a
                href="https://admin.gateway.fm"
                target="_blank"
                rel="noreferrer noopener"
                className="navigation-link3"
              >
                <ButtonSmallSecondary
                  label="RPC Login"
                  className="navigation-component7"
                ></ButtonSmallSecondary>
              </a>
              <a
                href="https://presto.gateway.fm"
                target="_blank"
                rel="noreferrer noopener"
                className="navigation-link4"
              >
                <ButtonSmallMain
                  label="Presto Login"
                  className="navigation-component8"
                ></ButtonSmallMain>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Navigation.defaultProps = {
  rootClassName: '',
}

Navigation.propTypes = {
  rootClassName: PropTypes.string,
}

export default Navigation
