import React from 'react'

import PropTypes from 'prop-types'

import './footer-link.css'

const FooterLink = (props) => {
  return (
    <div className={`footer-link-container ${props.rootClassName} `}>
      <span className="footer-link-text">{props.label}</span>
    </div>
  )
}

FooterLink.defaultProps = {
  label: 'Presto',
  rootClassName: '',
}

FooterLink.propTypes = {
  label: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default FooterLink
