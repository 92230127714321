import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import Tag from '../components/tag'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ImageProductHero from '../components/image-product-hero'
import ContentTitleProduct from '../components/content-title-product'
import CardProductHighlight from '../components/card-product-highlight'
import ContentParagraph from '../components/content-paragraph'
import ContentSmallTitle from '../components/content-small-title'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import AnnouncementSmall from '../components/announcement-small'
import Testimonial from '../components/testimonial'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './web3-infrastructure-blueprint-accounting-ledger.css'

const Web3InfrastructureBlueprintAccountingLedger = (props) => {
  return (
    <div className="web3-infrastructure-blueprint-accounting-ledger-container1">
      <Helmet>
        <title>
          Gateway.fm | Web3 Infrastructure Blueprint: Accounting Ledger
        </title>
        <meta
          name="description"
          content="Discover Gateway.fm's blueprint for a Web3 accounting ledger, leveraging blockchain for enhanced financial transaction transparency and efficiency."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Web3 Infrastructure Blueprint: Accounting Ledger"
        />
        <meta
          property="og:description"
          content="Discover Gateway.fm's blueprint for a Web3 accounting ledger, leveraging blockchain for enhanced financial transaction transparency and efficiency."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/f437bc94-18e9-46e3-9808-74c1281bcb85?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name20"></Navigation>
      <div className="web3-infrastructure-blueprint-accounting-ledger-content">
        <div className="web3-infrastructure-blueprint-accounting-ledger-hero">
          <div className="web3-infrastructure-blueprint-accounting-ledger-description">
            <TitleProductHero text="ACCOUNTING LEDGER"></TitleProductHero>
            <Tag rootClassName="tagroot-class-name7"></Tag>
            <SubtitleProductHero text="Distributed ledger for transparent, immutable and auditable financial records"></SubtitleProductHero>
            <USPCheckmark text="Ensure data integrity and auditability"></USPCheckmark>
            <USPCheckmark text="Streamline reconciliation processes"></USPCheckmark>
            <USPCheckmark text="No code. Zero knowledge"></USPCheckmark>
            <Link
              to="/contact-form"
              className="web3-infrastructure-blueprint-accounting-ledger-navlink1"
            >
              <ButtonPrimarySubtitle className="web3-infrastructure-blueprint-accounting-ledger-component17"></ButtonPrimarySubtitle>
            </Link>
          </div>
          <ImageProductHero image="/external/blueprints/accountingledger-500h.png"></ImageProductHero>
        </div>
        <div className="web3-infrastructure-blueprint-accounting-ledger-container2">
          <ContentTitleProduct></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-accounting-ledger-cards">
            <CardProductHighlight
              main="Addressing Traditional Accounting Challenges"
              supportive="We eliminate the need for reconciliation between multiple ledgers"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Ensuring Data Integrity and Immutability"
              supportive="Stakeholders can trace the entire lifecycle of transactions and entries, accessing detailed information"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Streamlining Accounting Processes"
              supportive="Automation of critical tasks through smart contracts"
            ></CardProductHighlight>
          </div>
          <ContentTitleProduct text="Summary"></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-accounting-ledger-container3">
            <span className="web3-infrastructure-blueprint-accounting-ledger-text">
              Our innovative Web3 accounting ledger solution harnesses the power
              of blockchain technology to establish a secure, transparent, and
              immutable record-keeping system for financial transactions and
              accounting data. By leveraging smart contracts, cryptographic
              proofs, and a decentralized distributed ledger, we create a
              trustworthy and auditable ecosystem for accounting and financial
              reporting, enabling organizations to maintain accurate and
              tamper-proof records while enhancing collaboration and
              accountability.
            </span>
          </div>
          <ContentTitleProduct text="Addressing Traditional Accounting Challenges"></ContentTitleProduct>
          <ContentParagraph text="Traditional accounting systems often suffer from inefficiencies, data silos, and vulnerability to errors or fraud. Our Web3 accounting ledger solution addresses these challenges by harnessing the power of blockchain to create an auditable, tamper-proof record of financial transactions and accounting data. By eliminating the need for reconciliation between multiple ledgers, our solution promotes data integrity, reduces the risk of errors, and fosters trust among stakeholders."></ContentParagraph>
          <ContentTitleProduct text="Ensuring Data Integrity and Immutability"></ContentTitleProduct>
          <ContentParagraph text="Data integrity and immutability are the cornerstones of our Web3 accounting ledger solution. By recording all financial transactions and accounting entries on an immutable, decentralized ledger, we eliminate the potential for data manipulation or unauthorized modifications. Stakeholders can trace the entire lifecycle of transactions and entries, accessing detailed information such as source documents, approvals, and audit trails. This level of transparency and immutability ensures the credibility and reliability of financial records."></ContentParagraph>
          <ContentTitleProduct text="Streamlining Accounting Processes"></ContentTitleProduct>
          <ContentParagraph text="Accounting processes often involve complex workflows, multiple stakeholders, and time-consuming reconciliations. Our Web3 solution streamlines these processes by automating critical tasks through smart contracts. These self-executing contracts automatically trigger predetermined actions based on predefined conditions, such as recording transactions, generating financial statements, or enforcing compliance rules. This automation enhances operational efficiency, reduces manual intervention, and minimizes the risk of errors."></ContentParagraph>
          <ContentTitleProduct text="Enabling Collaboration and Auditability"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 accounting ledger solution is designed to facilitate collaboration and auditability among stakeholders. By leveraging a shared, decentralized ledger, all authorized parties can access and verify the same set of financial data, eliminating the need for data reconciliation and ensuring consistent reporting. Additionally, the immutable nature of the blockchain enables efficient and transparent auditing processes, as auditors can independently verify the integrity of financial records without relying on intermediaries."></ContentParagraph>
          <ContentTitleProduct text="Scalable and Interoperable"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 solution is built with scalability and interoperability in mind, capable of integrating with existing accounting systems, enterprise resource planning (ERP) systems, and third-party services. By leveraging modular architecture and open standards, our solution can seamlessly connect with various blockchain networks, ensuring seamless data exchange and compatibility across diverse technologies and platforms."></ContentParagraph>
          <ContentTitleProduct text="Components"></ContentTitleProduct>
          <ContentSmallTitle
            text="Blockchain Network"
            rootClassName="content-small-titleroot-class-name49"
          ></ContentSmallTitle>
          <ContentParagraph text="A secure, decentralized ledger for recording financial transactions and accounting data, leveraging advanced consensus mechanisms and cryptographic techniques."></ContentParagraph>
          <ContentSmallTitle
            text="Smart Contracts"
            rootClassName="content-small-titleroot-class-name50"
          ></ContentSmallTitle>
          <ContentParagraph text=" Self-executing contracts that automate accounting processes, such as recording transactions, generating financial statements, and enforcing compliance rules, ensuring accurate and tamper-proof record-keeping."></ContentParagraph>
          <ContentSmallTitle
            text="Oracles"
            rootClassName="content-small-titleroot-class-name51"
          ></ContentSmallTitle>
          <ContentParagraph text="Secure gateways that facilitate the integration of off-chain data, such as bank statements, invoices, and third-party financial data, into the blockchain network."></ContentParagraph>
          <ContentSmallTitle
            text="Digital Tokens"
            rootClassName="content-small-titleroot-class-name52"
          ></ContentSmallTitle>
          <ContentParagraph text="Unique digital representations of financial assets, issued and tracked on the blockchain, enabling efficient and secure asset management and reporting."></ContentParagraph>
          <ContentSmallTitle
            text="Identity Management"
            rootClassName="content-small-titleroot-class-name53"
          ></ContentSmallTitle>
          <ContentParagraph text="A robust identity management system for securely onboarding and managing stakeholder identities, ensuring proper access controls, and compliance with regulatory requirements."></ContentParagraph>
          <ContentSmallTitle
            text="Analytics and Reporting"
            rootClassName="content-small-titleroot-class-name54"
          ></ContentSmallTitle>
          <ContentParagraph text="Powerful analytics and reporting tools that leverage the immutable data stored on the blockchain, providing stakeholders with valuable insights, financial statements, and audit trails."></ContentParagraph>
          <ContentSmallTitle
            text="Integration Layer"
            rootClassName="content-small-titleroot-class-name55"
          ></ContentSmallTitle>
          <ContentParagraph text="A flexible integration layer that enables seamless connectivity with existing accounting systems, ERP systems, and other third-party services, ensuring interoperability and data exchange across diverse platforms."></ContentParagraph>
          <ContentParagraph text="By harnessing the power of Web3 technologies, our accounting ledger solution delivers unparalleled data integrity, transparency, and collaboration, enabling organizations to maintain accurate and auditable financial records while streamlining accounting processes and fostering trust among stakeholders."></ContentParagraph>
        </div>
        <div className="web3-infrastructure-blueprint-accounting-ledger-container4">
          <SubtitleProductPromo></SubtitleProductPromo>
          <Link
            to="/blog/gateway-wirex-case-study"
            className="web3-infrastructure-blueprint-accounting-ledger-navlink2"
          >
            <AnnouncementSmall
              text="We enabled Wirex to elevate its zk-powered network"
              rootClassName="announcement-smallroot-class-name12"
              className="web3-infrastructure-blueprint-accounting-ledger-component51"
            ></AnnouncementSmall>
          </Link>
          <div className="web3-infrastructure-blueprint-accounting-ledger-testimonials">
            <Testimonial
              name="Wirex"
              text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
              position="@wirexapp"
              rootClassName="testimonialroot-class-name51"
              logopolygonSrc="/external/vector1317-f6xm.svg"
            ></Testimonial>
            <Testimonial
              name="GPT Protocol"
              text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
              position="@gpt_protocol"
              rootClassName="testimonialroot-class-name52"
              logopolygonSrc="/external/logos/logo-gpt-200w.png"
            ></Testimonial>
            <Testimonial
              name="Dora"
              text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
              position="@SearchOnDora"
              rootClassName="testimonialroot-class-name53"
              logopolygonSrc="/external/logos/logo-dora1-200h.png"
            ></Testimonial>
          </div>
        </div>
        <Link
          to="/contact-form"
          className="web3-infrastructure-blueprint-accounting-ledger-navlink3"
        >
          <Floating
            label="Accounting ledger"
            buttonLabel="Get started"
            description="Distributed ledger for transparent, immutable and auditable financial records"
            buttonSubtext="Initiate this blueprint"
            rootClassName="floatingroot-class-name6"
            className="web3-infrastructure-blueprint-accounting-ledger-component55"
          ></Floating>
        </Link>
      </div>
      <Footer rootClassName="footerroot-class-name19"></Footer>
    </div>
  )
}

export default Web3InfrastructureBlueprintAccountingLedger
