import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import Tag from '../components/tag'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ImageProductHero from '../components/image-product-hero'
import ContentTitleProduct from '../components/content-title-product'
import CardProductHighlight from '../components/card-product-highlight'
import ContentParagraph from '../components/content-paragraph'
import ContentSmallTitle from '../components/content-small-title'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import AnnouncementSmall from '../components/announcement-small'
import Testimonial from '../components/testimonial'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './web3-infrastructure-blueprint-carbon-credits.css'

const Web3InfrastructureBlueprintCarbonCredits = (props) => {
  return (
    <div className="web3-infrastructure-blueprint-carbon-credits-container1">
      <Helmet>
        <title>
          Gateway.fm | Web3 Infrastructure Blueprint: Carbon Credits
        </title>
        <meta
          name="description"
          content="Explore Gateway.fm's blueprint for Web3 infrastructure focusing on carbon credits. Learn how blockchain enhances transparency and efficiency in carbon credit transactions."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Web3 Infrastructure Blueprint: Carbon Credits"
        />
        <meta
          property="og:description"
          content="Explore Gateway.fm's blueprint for Web3 infrastructure focusing on carbon credits. Learn how blockchain enhances transparency and efficiency in carbon credit transactions."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/e993c37a-172d-495e-9b28-1c9f637489fb?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name18"></Navigation>
      <div className="web3-infrastructure-blueprint-carbon-credits-content">
        <div className="web3-infrastructure-blueprint-carbon-credits-hero">
          <div className="web3-infrastructure-blueprint-carbon-credits-description">
            <TitleProductHero text="CARBON CREDITS"></TitleProductHero>
            <Tag rootClassName="tagroot-class-name5"></Tag>
            <SubtitleProductHero text="Tokenize and trade carbon credits on a transparent and immutable ledger"></SubtitleProductHero>
            <USPCheckmark text="Enable trusted carbon accounting"></USPCheckmark>
            <USPCheckmark text="Facilitate liquid carbon markets"></USPCheckmark>
            <USPCheckmark text="No code. Zero knowledge"></USPCheckmark>
            <Link
              to="/contact-form"
              className="web3-infrastructure-blueprint-carbon-credits-navlink1"
            >
              <ButtonPrimarySubtitle className="web3-infrastructure-blueprint-carbon-credits-component17"></ButtonPrimarySubtitle>
            </Link>
          </div>
          <ImageProductHero image="/external/blueprints/leaf-500h.png"></ImageProductHero>
        </div>
        <div className="web3-infrastructure-blueprint-carbon-credits-container2">
          <ContentTitleProduct></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-carbon-credits-cards">
            <CardProductHighlight
              main="Addressing Climate Change Challenges"
              supportive="Create an auditable, tamper-proof record of carbon credit generation, issuance, and retirement"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Ensuring Transparency and Integrity"
              supportive="Stakeholders can trace the entire lifecycle of carbon credits, from generation to retirement"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Enabling Global Participation"
              supportive="Our solution eliminates geographical barriers and fosters a truly global marketplace for carbon credits"
            ></CardProductHighlight>
          </div>
          <ContentTitleProduct text="Summary"></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-carbon-credits-container3">
            <span className="web3-infrastructure-blueprint-carbon-credits-text">
              Our innovative Web3 carbon credits solution leverages blockchain
              technology to establish a transparent, secure, and decentralized
              ecosystem for tracking and trading carbon credits. By utilizing
              smart contracts, cryptographic proofs, and an immutable
              distributed ledger, we create a trustworthy and efficient
              marketplace for carbon credit transactions, enabling organizations
              to accurately measure, report, and offset their carbon footprint.
            </span>
          </div>
          <ContentTitleProduct text="Addressing Climate Change Challenges"></ContentTitleProduct>
          <ContentParagraph text="Climate change is one of the most pressing global issues, and addressing it requires a concerted effort from all stakeholders. Traditional carbon credit markets often suffer from lack of transparency, inefficient processes, and vulnerability to double counting or fraud. Our Web3 solution addresses these challenges by harnessing the power of blockchain to create an auditable, tamper-proof record of carbon credit generation, issuance, and retirement."></ContentParagraph>
          <ContentTitleProduct text="Ensuring Transparency and Integrity"></ContentTitleProduct>
          <ContentParagraph text="Transparency and integrity are the cornerstones of our Web3 carbon credits solution. By recording all carbon credit-related transactions on an immutable, decentralized ledger, we eliminate the potential for data manipulation or double counting. Stakeholders can trace the entire lifecycle of carbon credits, from generation to retirement, accessing detailed information such as project details, verification reports, and ownership transfers. This level of transparency fosters trust among participants and ensures the credibility of the carbon credit market."></ContentParagraph>
          <ContentTitleProduct text="Streamlining Carbon Credit Issuance and Trading"></ContentTitleProduct>
          <ContentParagraph text="The issuance and trading of carbon credits often involve complex processes and multiple intermediaries, leading to inefficiencies and delays. Our Web3 solution streamlines these processes by automating critical tasks through smart contracts. These self-executing contracts automatically verify project data, issue carbon credits, and facilitate trades based on predefined rules and conditions. This automation not only enhances operational efficiency but also reduces the risk of errors and disputes, ultimately optimizing the entire carbon credit lifecycle."></ContentParagraph>
          <ContentTitleProduct text="Enabling Global Participation"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 carbon credits solution is designed to be globally accessible, enabling organizations and individuals from around the world to participate in the carbon credit market. By leveraging a decentralized architecture and open standards, our solution eliminates geographical barriers and fosters a truly global marketplace for carbon credits, promoting global collaboration in addressing climate change."></ContentParagraph>
          <ContentTitleProduct text="Scalable and Interoperable"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 solution is built with scalability and interoperability in mind, capable of integrating with existing carbon accounting systems, environmental monitoring devices, and third-party services. By leveraging modular architecture and open standards, our solution can seamlessly connect with various blockchain networks, IoT devices, and enterprise systems, ensuring seamless data exchange and compatibility across diverse technologies and platforms."></ContentParagraph>
          <ContentTitleProduct text="Components"></ContentTitleProduct>
          <ContentSmallTitle
            text="Blockchain Network"
            rootClassName="content-small-titleroot-class-name22"
          ></ContentSmallTitle>
          <ContentParagraph text="A secure, decentralized ledger for recording supply chain events and transactions, leveraging advanced consensus mechanisms and cryptographic techniques."></ContentParagraph>
          <ContentSmallTitle
            text="Smart Contracts"
            rootClassName="content-small-titleroot-class-name23"
          ></ContentSmallTitle>
          <ContentParagraph text="Self-executing contracts that automate workflows, enforce predefined rules, and facilitate trustless interactions among stakeholders."></ContentParagraph>
          <ContentSmallTitle
            text="Oracles"
            rootClassName="content-small-titleroot-class-name24"
          ></ContentSmallTitle>
          <ContentParagraph text="Secure gateways that facilitate the integration of off-chain data, such as IoT sensor readings, quality certifications, and regulatory compliance information, into the blockchain network."></ContentParagraph>
          <ContentSmallTitle
            text="Digital Tokens"
            rootClassName="content-small-titleroot-class-name38"
          ></ContentSmallTitle>
          <ContentParagraph text="Unique digital representations of carbon credits, issued and traded on the blockchain, ensuring transparency and preventing double counting."></ContentParagraph>
          <ContentSmallTitle
            text="Identity Management"
            rootClassName="content-small-titleroot-class-name39"
          ></ContentSmallTitle>
          <ContentParagraph text="A robust identity management system for securely onboarding and managing stakeholder identities, ensuring proper access controls and compliance with regulatory requirements."></ContentParagraph>
          <ContentSmallTitle
            text="Analytics and Reporting"
            rootClassName="content-small-titleroot-class-name40"
          ></ContentSmallTitle>
          <ContentParagraph text="Powerful analytics and reporting tools that leverage the immutable data stored on the blockchain, providing stakeholders with valuable insights and enabling data-driven decision-making."></ContentParagraph>
          <ContentSmallTitle
            text="Integration Layer"
            rootClassName="content-small-titleroot-class-name41"
          ></ContentSmallTitle>
          <ContentParagraph text="A flexible integration layer that enables seamless connectivity with existing supply chain management systems, enterprise resource planning (ERP) systems, and other third-party services, ensuring interoperability and data exchange across diverse platforms."></ContentParagraph>
          <ContentParagraph text="By harnessing the power of Web3 technologies, our carbon credits solution delivers unparalleled transparency, efficiency, and trust, enabling organizations to accurately measure and offset their carbon footprint while fostering a collaborative and accountable global marketplace for carbon credits."></ContentParagraph>
        </div>
        <div className="web3-infrastructure-blueprint-carbon-credits-container4">
          <SubtitleProductPromo></SubtitleProductPromo>
          <AnnouncementSmall
            text="We enabled Wirex to elevate its zk-powered network"
            rootClassName="announcement-smallroot-class-name13"
            className="web3-infrastructure-blueprint-carbon-credits-component51"
          ></AnnouncementSmall>
          <div className="web3-infrastructure-blueprint-carbon-credits-testimonials">
            <Testimonial
              name="Wirex"
              text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
              position="@wirexapp"
              rootClassName="testimonialroot-class-name60"
              logopolygonSrc="/external/vector1317-f6xm.svg"
            ></Testimonial>
            <Testimonial
              name="GPT Protocol"
              text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
              position="@gpt_protocol"
              rootClassName="testimonialroot-class-name61"
              logopolygonSrc="/external/logos/logo-gpt-200w.png"
            ></Testimonial>
            <Testimonial
              name="Dora"
              text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
              position="@SearchOnDora"
              rootClassName="testimonialroot-class-name62"
              logopolygonSrc="/external/logos/logo-dora1-200h.png"
            ></Testimonial>
          </div>
        </div>
        <Link
          to="/contact-form"
          className="web3-infrastructure-blueprint-carbon-credits-navlink2"
        >
          <Floating
            label="Carbon Credits"
            buttonLabel="Get started"
            description="Tokenize and trade carbon credits on a transparent and immutable ledger"
            buttonSubtext="Initiate this blueprint"
            rootClassName="floatingroot-class-name4"
            className="web3-infrastructure-blueprint-carbon-credits-component55"
          ></Floating>
        </Link>
      </div>
      <Footer rootClassName="footerroot-class-name17"></Footer>
    </div>
  )
}

export default Web3InfrastructureBlueprintCarbonCredits
