import React from 'react'

import PropTypes from 'prop-types'

import ButtonPrimarySubtitle from './button-primary-subtitle'
import './floating.css'

const Floating = (props) => {
  return (
    <div
      id="floating-CTA"
      className={`floating-container1 ${props.rootClassName} `}
    >
      <div className="floating-container2">
        <div className="floating-texts">
          <span className="floating-text1">{props.label}</span>
          <span className="floating-text2">{props.description}</span>
        </div>
        <a
          href={props.buttonLink}
          target="_blank"
          rel="noreferrer noopener"
          className="floating-link"
        >
          <ButtonPrimarySubtitle
            main={props.buttonLabel}
            supportive={props.buttonSubtext}
            rootClassName="button-primary-subtitleroot-class-name"
            className="floating-component"
          ></ButtonPrimarySubtitle>
        </a>
      </div>
    </div>
  )
}

Floating.defaultProps = {
  buttonSubtext: '',
  description: 'Manage organisations, grants, investments by your hive-mind',
  rootClassName: '',
  buttonLink: '/link',
  label: 'DAO',
  buttonLabel: '',
}

Floating.propTypes = {
  buttonSubtext: PropTypes.string,
  description: PropTypes.string,
  rootClassName: PropTypes.string,
  buttonLink: PropTypes.string,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
}

export default Floating
