import React from 'react'

import PropTypes from 'prop-types'

import './card-blueprint.css'

const CardBlueprint = (props) => {
  return (
    <div className={`card-blueprint-container1 ${props.rootClassName} `}>
      <div className="card-blueprint-container2">
        <img
          alt="coin217309"
          src={props.image}
          className="card-blueprint-coin21"
        />
        <div className="card-blueprint-content">
          <div className="card-blueprint-interaction">
            <div className="card-blueprint-title">
              <span className="card-blueprint-text1">{props.subtext}</span>
              <span className="card-blueprint-text2">{props.text}</span>
            </div>
            <img
              alt="arrowright7309"
              src="/external/arrowright7309-tpac.svg"
              className="card-blueprint-arrowright"
            />
          </div>
          <div className="card-blueprint-usps">
            <div className="card-blueprint-container3">
              <div className="card-blueprint-usp1">
                <img
                  alt="check7309"
                  src="/external/check7309-0nno.svg"
                  className="card-blueprint-check1"
                />
                <span className="card-blueprint-text3">{props.usp1}</span>
              </div>
              <div className="card-blueprint-usp2">
                <img
                  alt="check7309"
                  src="/external/check7309-6w7e.svg"
                  className="card-blueprint-check2"
                />
                <span className="card-blueprint-text4">{props.usp2}</span>
              </div>
              <div className="card-blueprint-usp3">
                <img
                  alt="check7309"
                  src="/external/check7309-eln.svg"
                  className="card-blueprint-check3"
                />
                <span className="card-blueprint-text5">{props.usp3}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CardBlueprint.defaultProps = {
  usp2: 'Integration with Visa & MasterCard',
  usp1: 'Lightning fast, cheap transactions',
  usp3: 'No code. Zero knowledge',
  rootClassName: '',
  subtext: 'Digital bank',
  image: '/external/coin217309-85c-200h.png',
  text: 'Launch your own payments system in 3 months',
}

CardBlueprint.propTypes = {
  usp2: PropTypes.string,
  usp1: PropTypes.string,
  usp3: PropTypes.string,
  rootClassName: PropTypes.string,
  subtext: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
}

export default CardBlueprint
