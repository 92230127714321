import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import USPCheckmark from '../components/usp-checkmark'
import SubtitleProductHero from '../components/subtitle-product-hero'
import Footer from '../components/footer'
import './book-a-call.css'

const BookACall = (props) => {
  return (
    <div className="book-a-call-container1">
      <Helmet>
        <title>Book a Call with Gateway.fm</title>
        <meta
          name="description"
          content="Schedule a consultation or discussion with Gateway.fm to explore how we can collaborate on blockchain solutions or partnerships."
        />
        <meta property="og:title" content="Book a Call with Gateway.fm" />
        <meta
          property="og:description"
          content="Schedule a consultation or discussion with Gateway.fm to explore how we can collaborate on blockchain solutions or partnerships."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/671a1c08-9a3a-451c-adc2-a0c82439c229?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name15"></Navigation>
      <div className="book-a-call-content">
        <div className="book-a-call-hero">
          <div className="book-a-call-description">
            <div className="book-a-call-container2">
              <span className="book-a-call-text">
                YOUR PERSONAL PROJECT MANAGER
              </span>
            </div>
            <div className="book-a-call-container3">
              <USPCheckmark
                text="No obligations"
                rootClassName="usp-checkmarkroot-class-name"
              ></USPCheckmark>
              <USPCheckmark text="Time that suits you"></USPCheckmark>
              <USPCheckmark text="Casual conversation"></USPCheckmark>
            </div>
            <SubtitleProductHero text="Zero-knowledge setup with highly competent personal project manager. Choose a suitable time, and we'll help with your project"></SubtitleProductHero>
          </div>
          <iframe
            src="https://app.hubspot.com/meetings/daniel-nasasira"
            className="book-a-call-iframe"
          ></iframe>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name14"></Footer>
    </div>
  )
}

export default BookACall
