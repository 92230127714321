import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Tag from '../../components/tag'
import Footer from '../../components/footer'
import './blog-lukso-gatewayfm-a-successful-partnership.css'

const BlogLUKSOGatewayfmASuccessfulPartnership = (props) => {
  return (
    <div className="blog-lukso-gatewayfm-a-successful-partnership-container1">
      <Helmet>
        <title>LUKSO & Gateway.fm: A Successful Partnership</title>
        <meta
          name="description"
          content="Explore the successful partnership between LUKSO and Gateway.fm, driving innovation in blockchain technology and fashion industry integration."
        />
        <meta
          property="og:title"
          content="LUKSO &amp; Gateway.fm: A Successful Partnership"
        />
        <meta
          property="og:description"
          content="Explore the successful partnership between LUKSO and Gateway.fm, driving innovation in blockchain technology and fashion industry integration."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/52ffa150-8552-45f6-bd2a-5b07318542cc?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name25"></Navigation>
      <div className="blog-lukso-gatewayfm-a-successful-partnership-container2">
        <div className="blog-lukso-gatewayfm-a-successful-partnership-post">
          <img
            alt="Luko and Gateway.fm."
            src="/external/blog-banners/gatway-lukso-partnership-1200w1.webp"
            className="blog-lukso-gatewayfm-a-successful-partnership-image"
          />
          <div className="blog-lukso-gatewayfm-a-successful-partnership-text10">
            <div className="blog-lukso-gatewayfm-a-successful-partnership-container3">
              <div className="blog-lukso-gatewayfm-a-successful-partnership-tags">
                <Tag text="latest"></Tag>
                <Tag text="guide"></Tag>
              </div>
              <span className="blog-lukso-gatewayfm-a-successful-partnership-text11">
                LUKSO &amp; Gateway.fm: A Successful Partnership
              </span>
              <span className="blog-lukso-gatewayfm-a-successful-partnership-text12">
                7 of May 2024
              </span>
              <span className="blog-lukso-gatewayfm-a-successful-partnership-text13">
                <span>
                  We&apos;re proud to highlight the successful partnership
                  between
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://lukso.network/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  LUKSO
                </a>
                <span>
                  {' '}
                  and Gateway.fm. LUKSO is a next generation EVM blockchain
                  company based on Casper PoS. They provide a platform catering
                  to simplify interactions in the New Creative Economies -
                  meaning economies that encompass fashion, art, music,
                  entertainment, social media and beyond.
                </span>
                <br></br>
                <br></br>
                <span>
                  LUKSO was founded by former Ethereum Developer Fabian
                  Vogelsteller known for his instrumental role in early Ethereum
                  development, ERC-20 among other projects. And brand expert
                  Marjorie Hernandez who has multi-faced expertise in brand
                  innovation, brand strategy and architecture.
                </span>
                <br></br>
                <br></br>
                <span>
                  As part of this partnership Gateway.fm provided LUKSO dApp
                  developers with robust RPC infrastructure, offering both
                  testnet and mainnet to shared and dedicated nodes. Our
                  infrastructure, hosted in independent data centers, ensured
                  maximum security and decentralization for the LUKSO network
                  maintaining reliability and performance. Providing them with
                  the reliable and high-performing infrastructure needed to
                  build and deploy applications effectively.
                </span>
                <br></br>
                <br></br>
                <span>
                  By working together, Gateway.fm and LUKSO contributed to
                  creating a more robust ecosystem, empowering developers to
                  fully leverage blockchain technology. We&apos;re pleased to
                  have played a role in supporting LUKSO&apos;s mission and
                  facilitating the growth of its developer community. As
                  Gateway.fm continues to prioritize excellence in service
                  delivery, we remain committed to upholding the highest
                  standards of security and decentralization for all networks we
                  support.
                </span>
                <br></br>
                <br></br>
                <span className="blog-lukso-gatewayfm-a-successful-partnership-text27">
                  Fabian Vogelsteller
                </span>
                <span>, Founder of LUKSO:</span>
                <br></br>
                <span className="blog-lukso-gatewayfm-a-successful-partnership-text30">
                  “The interests between LUKSO and Gateway are aligned, and we
                  are both on a mission to make the Web3 ecosystem as accessible
                  as Web2. Their team&apos;s extensive infrastructure knowledge
                  and core development experience will be invaluable in
                  providing public RPC endpoints for our Testnet and Mainnet,
                  and we are very excited to work with Temoc, Igor and the
                  stellar Gateway team in the months ahead.”
                </span>
                <br></br>
                <br></br>
                <span className="blog-lukso-gatewayfm-a-successful-partnership-text33">
                  Temoc Weber
                </span>
                <span>, CEO of Gateway.fm:</span>
                <br></br>
                <span className="blog-lukso-gatewayfm-a-successful-partnership-text36">
                  “Collaborating with LUKSO is a signature partnership for
                  Gateway, and represents a strong vote of confidence from one
                  of the core shapers of the Ethereum ecosystem Fabian
                  Vogelsteller. We continue to set a new standard for building
                  bespoke infrastructure solutions for our growing roster of
                  partners, and we are always on the lookout for ambitious Web3
                  projects who share our vision for true decentralization.”
                </span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name24"></Footer>
    </div>
  )
}

export default BlogLUKSOGatewayfmASuccessfulPartnership
