import React from 'react'

import PropTypes from 'prop-types'

import ButtonPrimarySubtitle from './button-primary-subtitle'
import './card-plan-rpc.css'

const CardPlanRPC = (props) => {
  return (
    <div className={`card-plan-rpc-container1 ${props.rootClassName} `}>
      <div id={props.cardID} className="card-plan-rpc-container2">
        <div className="card-plan-rpc-texts">
          <span className="card-plan-rpc-text1">{props.text}</span>
          <div className="card-plan-rpc-flags">
            <img
              alt={props.singaporeAlt}
              src={props.singaporeSrc}
              className="card-plan-rpc-singapore"
            />
            <img
              alt={props.switzerlandAlt}
              src={props.switzerlandSrc}
              className="card-plan-rpc-switzerland"
            />
            <img
              alt={props.swedenAlt}
              src={props.swedenSrc}
              className="card-plan-rpc-sweden"
            />
            <img
              alt={props.germanyAlt}
              src={props.germanySrc}
              className="card-plan-rpc-germany"
            />
          </div>
          <div className="card-plan-rpcus-ps">
            <div className="card-plan-rpc-container3">
              <div className="card-plan-rpc-content1">
                <img
                  alt="check6986"
                  src="/external/check6986-go3q.svg"
                  className="card-plan-rpc-check1"
                />
                <span id={props.requestsID} className="card-plan-rpc-text2">
                  {props.text3}
                </span>
              </div>
            </div>
            <div className="card-plan-rpc-container4">
              <div className="card-plan-rpc-content2">
                <img
                  alt="check6986"
                  src="/external/check6986-go3q.svg"
                  className="card-plan-rpc-check2"
                />
                <span className="card-plan-rpc-text3">{props.text2}</span>
              </div>
            </div>
            <div className="card-plan-rpc-container5">
              <div className="card-plan-rpc-content3">
                <img
                  alt="check6986"
                  src="/external/check6986-go3q.svg"
                  className="card-plan-rpc-check3"
                />
                <span className="card-plan-rpc-text4">{props.text1}</span>
              </div>
            </div>
          </div>
        </div>
        <a href={props.buttonLink} className="card-plan-rpc-link">
          <ButtonPrimarySubtitle
            main={props.buttonLabel}
            divID={props.buttonID}
            supportive={props.buttonSubtext}
            className="card-plan-rpc-component"
          ></ButtonPrimarySubtitle>
        </a>
      </div>
    </div>
  )
}

CardPlanRPC.defaultProps = {
  swedenAlt: 'Sweden7238',
  germanySrc: '/external/germany7238-juwt.svg',
  singaporeAlt: 'Singapore7238',
  requestsID: '',
  buttonLink: '',
  switzerlandSrc: '/external/switzerland7238-2rie.svg',
  rootClassName2: '',
  buttonID: '',
  swedenSrc: '/external/sweden7238-tk56.svg',
  germanyAlt: 'Germany7238',
  buttonLabel: 'Get started',
  text: 'FREE ACCESS',
  buttonSubtext: '1 click setup',
  switzerlandAlt: 'Switzerland7238',
  text2: 'Discord community',
  text3: '60 req/s (155M req/mon)',
  rootClassName: '',
  singaporeSrc: '/external/singapore7238-qi-200h.png',
  text1: 'WebSockets support',
  rootClassName1: '',
  cardID: '',
}

CardPlanRPC.propTypes = {
  swedenAlt: PropTypes.string,
  germanySrc: PropTypes.string,
  singaporeAlt: PropTypes.string,
  requestsID: PropTypes.string,
  buttonLink: PropTypes.string,
  switzerlandSrc: PropTypes.string,
  rootClassName2: PropTypes.string,
  buttonID: PropTypes.string,
  swedenSrc: PropTypes.string,
  germanyAlt: PropTypes.string,
  buttonLabel: PropTypes.string,
  text: PropTypes.string,
  buttonSubtext: PropTypes.string,
  switzerlandAlt: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  rootClassName: PropTypes.string,
  singaporeSrc: PropTypes.string,
  text1: PropTypes.string,
  rootClassName1: PropTypes.string,
  cardID: PropTypes.string,
}

export default CardPlanRPC
