import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import Tag from '../components/tag'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import ImageProductHero from '../components/image-product-hero'
import ContentTitleProduct from '../components/content-title-product'
import CardProductHighlight from '../components/card-product-highlight'
import ContentParagraph from '../components/content-paragraph'
import ContentSmallTitle from '../components/content-small-title'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import AnnouncementSmall from '../components/announcement-small'
import Testimonial from '../components/testimonial'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './web3-infrastructure-blueprint-loyalty-program.css'

const Web3InfrastructureBlueprintLoyaltyProgram = (props) => {
  return (
    <div className="web3-infrastructure-blueprint-loyalty-program-container1">
      <Helmet>
        <title>
          Gateway.fm | Web3 Infrastructure Blueprint: Loyalty Program
        </title>
        <meta
          name="description"
          content="Discover Gateway.fm's blueprint for a Web3 loyalty program, leveraging blockchain to enhance transparency, security, and efficiency in customer rewards systems."
        />
        <meta
          property="og:title"
          content="Gateway.fm | Web3 Infrastructure Blueprint: Loyalty Program"
        />
        <meta
          property="og:description"
          content="Discover Gateway.fm's blueprint for a Web3 loyalty program, leveraging blockchain to enhance transparency, security, and efficiency in customer rewards systems."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/9712dded-1bcb-4676-b08b-f5a2e5bf41dd?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name24"></Navigation>
      <div className="web3-infrastructure-blueprint-loyalty-program-content">
        <div className="web3-infrastructure-blueprint-loyalty-program-hero">
          <div className="web3-infrastructure-blueprint-loyalty-program-description">
            <TitleProductHero text="LOYALTY PROGRAM"></TitleProductHero>
            <Tag rootClassName="tagroot-class-name11"></Tag>
            <SubtitleProductHero text="Reward customer loyalty with tokenized incentives and rewards"></SubtitleProductHero>
            <USPCheckmark text="True ownership and portability"></USPCheckmark>
            <USPCheckmark text="Composable and interoperable rewards"></USPCheckmark>
            <USPCheckmark text="No code. Zero knowledge"></USPCheckmark>
            <Link
              to="/contact-form"
              className="web3-infrastructure-blueprint-loyalty-program-navlink1"
            >
              <ButtonPrimarySubtitle className="web3-infrastructure-blueprint-loyalty-program-component17"></ButtonPrimarySubtitle>
            </Link>
          </div>
          <ImageProductHero image="/external/blueprints/loyaltyprogram-500h.png"></ImageProductHero>
        </div>
        <div className="web3-infrastructure-blueprint-loyalty-program-container2">
          <ContentTitleProduct></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-loyalty-program-cards">
            <CardProductHighlight
              main="Solving Traditional Loyalty Program Challenges"
              supportive="Create a decentralized and immutable record of customer interactions"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Unlocking the Benefits of L2 Scaling"
              supportive="Seamless and cost-effective processing of loyalty program transactions"
            ></CardProductHighlight>
            <CardProductHighlight
              main="Enhancing Transparency and Trust"
              supportive="Eliminate the potential for data manipulation or unfair practices"
            ></CardProductHighlight>
          </div>
          <ContentTitleProduct text="Summary"></ContentTitleProduct>
          <div className="web3-infrastructure-blueprint-loyalty-program-container3">
            <span className="web3-infrastructure-blueprint-loyalty-program-text">
              Our cutting-edge Web3 Loyalty Program solution harnesses the power
              of blockchain technology to revolutionize the way businesses
              engage with their customers. By leveraging the scalability and
              cost-efficiency of Layer 2 (L2) networks, we enable the creation
              of a secure, transparent, and decentralized ecosystem for customer
              loyalty programs, redefining the customer experience and fostering
              long-lasting relationships.
            </span>
          </div>
          <ContentTitleProduct text="Solving Traditional Loyalty Program Challenges"></ContentTitleProduct>
          <ContentParagraph text="Traditional loyalty programs often suffer from inefficiencies, lack of transparency, and centralized control, leading to customer disengagement and distrust. Our Web3 solution addresses these challenges by harnessing the power of blockchain and L2 scaling solutions, creating a decentralized and immutable record of customer interactions, rewards, and loyalty program rules."></ContentParagraph>
          <ContentTitleProduct text="Unlocking the Benefits of L2 Scaling"></ContentTitleProduct>
          <ContentParagraph text="By building our Loyalty Program solution on L2 networks, we harness the advantages of scalability, low transaction costs, and high throughput. These benefits enable seamless and cost-effective processing of loyalty program transactions, even during periods of high demand, ensuring a smooth and responsive user experience."></ContentParagraph>
          <ContentTitleProduct text="Enhancing Transparency and Trust"></ContentTitleProduct>
          <ContentParagraph text="Transparency and trust are at the core of our Web3 Loyalty Program solution. By recording all customer interactions, rewards, and program rules on a decentralized blockchain ledger, we eliminate the potential for data manipulation or unfair practices. Customers can independently verify the integrity of the loyalty program, fostering trust and credibility in the ecosystem."></ContentParagraph>
          <ContentTitleProduct text="Enabling Frictionless Loyalty Integration"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 solution is designed to seamlessly integrate with existing business systems and platforms, enabling frictionless loyalty program implementation. By leveraging open standards and interoperability protocols, we ensure that our solution can connect with various e-commerce platforms, point-of-sale systems, and customer relationship management (CRM) tools, streamlining the integration process and minimizing disruption to existing operations."></ContentParagraph>
          <ContentTitleProduct text="Scalable and Secure"></ContentTitleProduct>
          <ContentParagraph text="Our Web3 Loyalty Program solution is built with scalability and security in mind, capable of handling large volumes of customer interactions and transactions. By leveraging L2 scaling solutions and advanced blockchain technologies, we ensure efficient and cost-effective processing while maintaining robust security measures, such as advanced encryption and access control mechanisms, to protect customer data and loyalty program integrity."></ContentParagraph>
          <ContentTitleProduct text="Components"></ContentTitleProduct>
          <ContentSmallTitle
            text="L2 Blockchain Network"
            rootClassName="content-small-titleroot-class-name77"
          ></ContentSmallTitle>
          <ContentParagraph text="A secure, decentralized ledger built on top of Layer 2 networks, enabling scalable and cost-effective processing of loyalty program transactions and data."></ContentParagraph>
          <ContentSmallTitle
            text="Smart Contracts"
            rootClassName="content-small-titleroot-class-name78"
          ></ContentSmallTitle>
          <ContentParagraph text="Self-executing contracts that govern the rules, rewards, and redemption processes of the loyalty program, ensuring transparency and immutability."></ContentParagraph>
          <ContentSmallTitle
            text="Loyalty Tokens"
            rootClassName="content-small-titleroot-class-name79"
          ></ContentSmallTitle>
          <ContentParagraph text="Unique digital tokens representing customer loyalty points or rewards, enabling seamless integration with existing business systems and platforms."></ContentParagraph>
          <ContentSmallTitle
            text="Identity Management"
            rootClassName="content-small-titleroot-class-name80"
          ></ContentSmallTitle>
          <ContentParagraph text="A robust identity management system for securely onboarding and managing customer identities, ensuring proper access controls and compliance with regulatory requirements."></ContentParagraph>
          <ContentSmallTitle
            text="Analytics and Reporting"
            rootClassName="content-small-titleroot-class-name81"
          ></ContentSmallTitle>
          <ContentParagraph text="Powerful analytics and reporting tools that leverage the transparent data stored on the blockchain, providing businesses with valuable insights into customer behavior, loyalty program performance, and optimization opportunities."></ContentParagraph>
          <ContentSmallTitle
            text="Integration Layer"
            rootClassName="content-small-titleroot-class-name82"
          ></ContentSmallTitle>
          <ContentParagraph text="A flexible integration layer that enables seamless connectivity with existing e-commerce platforms, point-of-sale systems, CRM tools, and other third-party services, ensuring frictionless loyalty program integration."></ContentParagraph>
          <ContentParagraph text="By harnessing the power of Web3 technologies and the scalability of L2 networks, our Loyalty Program solution delivers unparalleled transparency, trust, and seamless integration, enabling businesses to foster long-lasting customer relationships, drive engagement, and unlock new levels of customer loyalty in the digital age."></ContentParagraph>
        </div>
        <div className="web3-infrastructure-blueprint-loyalty-program-container4">
          <SubtitleProductPromo></SubtitleProductPromo>
          <AnnouncementSmall
            text="We enabled Wirex to elevate its zk-powered network"
            rootClassName="announcement-smallroot-class-name3"
            className="web3-infrastructure-blueprint-loyalty-program-component49"
          ></AnnouncementSmall>
          <div className="web3-infrastructure-blueprint-loyalty-program-testimonials">
            <Testimonial
              name="Wirex"
              text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
              position="@wirexapp"
              rootClassName="testimonialroot-class-name69"
              logopolygonSrc="/external/vector1317-f6xm.svg"
            ></Testimonial>
            <Testimonial
              name="GPT Protocol"
              text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
              position="@gpt_protocol"
              rootClassName="testimonialroot-class-name70"
              logopolygonSrc="/external/logos/logo-gpt-200w.png"
            ></Testimonial>
            <Testimonial
              name="Dora"
              text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
              position="@SearchOnDora"
              rootClassName="testimonialroot-class-name71"
              logopolygonSrc="/external/logos/logo-dora1-200h.png"
            ></Testimonial>
          </div>
        </div>
        <Link
          to="/contact-form"
          className="web3-infrastructure-blueprint-loyalty-program-navlink2"
        >
          <Floating
            label="Loyalty program"
            buttonLabel="Get started"
            description="Reward customer loyalty with tokenized incentives and rewards"
            buttonSubtext="Initiate this blueprint"
            rootClassName="floatingroot-class-name10"
            className="web3-infrastructure-blueprint-loyalty-program-component53"
          ></Floating>
        </Link>
      </div>
      <Footer rootClassName="footerroot-class-name23"></Footer>
    </div>
  )
}

export default Web3InfrastructureBlueprintLoyaltyProgram
