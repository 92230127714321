import React from 'react'

import PropTypes from 'prop-types'

import './announcement-small.css'

const AnnouncementSmall = (props) => {
  return (
    <div className={`announcement-small-container ${props.rootClassName} `}>
      <div className="announcement-small-content">
        <div className="announcement-small-message">
          <img
            alt="LightningBolt6986"
            src="/external/lightningbolt6986-y17g.svg"
            className="announcement-small-lightning-bolt"
          />
          <span className="announcement-small-text">{props.text}</span>
        </div>
        <img
          alt="CollapseArrow6986"
          src="/external/collapsearrow6986-11ie.svg"
          className="announcement-small-collapse-arrow"
        />
      </div>
    </div>
  )
}

AnnouncementSmall.defaultProps = {
  text: 'We’re powering Gnosis Pay infrastructure',
  rootClassName: '',
}

AnnouncementSmall.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default AnnouncementSmall
