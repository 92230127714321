import React from 'react'

import PropTypes from 'prop-types'

import ButtonPrimarySubtitle from './button-primary-subtitle'
import './form-upload.css'

const FormUpload = (props) => {
  return (
    <div className={`form-upload-upload ${props.rootClassName} `}>
      <div className="form-upload-container1">
        <span className="form-upload-text1">{props.label}</span>
        <form id="uploadCV" className="form-upload-form">
          <div id="cvForm" className="form-upload-container2">
            <div id="file-drop-area" className="form-upload-upload-area">
              <img
                alt="fileaccountoutline8283"
                src="/external/fileaccountoutline8283-8wgb.svg"
                className="form-upload-fileaccountoutline"
              />
              <ul id="file-list" className="form-upload-ul file-list list"></ul>
              <span id="cvFormPlaceholder" className="form-upload-text2">
                {props.placeholder}
              </span>
            </div>
            <ButtonPrimarySubtitle
              main="Apply"
              divID="uploadcvButton"
              supportive="Send in your CV"
            ></ButtonPrimarySubtitle>
          </div>
          <div id="cvFormSuccess" className="form-upload-success">
            <img
              alt={props.imageAlt}
              src={props.imageSrc}
              className="form-upload-image"
            />
            <span className="form-upload-text3">
              <span>Thank you!</span>
              <br></br>
              <span>We&apos;ve received your CV</span>
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}

FormUpload.defaultProps = {
  placeholder: 'Drag & drop your CV here',
  imageAlt: 'image',
  uploadareaId: '',
  label: 'Upload your CV',
  imageSrc: '/external/done-200h.png',
  rootClassName: '',
}

FormUpload.propTypes = {
  placeholder: PropTypes.string,
  imageAlt: PropTypes.string,
  uploadareaId: PropTypes.string,
  label: PropTypes.string,
  imageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default FormUpload
