import React from 'react'

import './progress-bar.css'

const ProgressBar = (props) => {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar-progress">
        <div id="progress-bar-section" className="progress-bar-complete"></div>
      </div>
    </div>
  )
}

export default ProgressBar
