import React from 'react'

import PropTypes from 'prop-types'

import './subtitle-product-promo.css'

const SubtitleProductPromo = (props) => {
  return (
    <div className="subtitle-product-promo-container">
      <span className="subtitle-product-promo-text">{props.text}</span>
    </div>
  )
}

SubtitleProductPromo.defaultProps = {
  text: 'TESTIMONIALS',
}

SubtitleProductPromo.propTypes = {
  text: PropTypes.string,
}

export default SubtitleProductPromo
