import React from 'react'

import PropTypes from 'prop-types'

import './card-rule.css'

const CardRule = (props) => {
  return (
    <div className="card-rule-container">
      <div className="card-rule-rule">
        <div className="card-rule-title">
          <span className="card-rule-text1">{props.header}</span>
          <img alt="check7391" src={props.icon} className="card-rule-check" />
        </div>
        <span className="card-rule-text2">{props.text}</span>
      </div>
    </div>
  )
}

CardRule.defaultProps = {
  text: 'Use provided logotype on Gateway.fm Press Kit',
  header: 'USAGE',
  icon: '/external/check7391-s80f.svg',
}

CardRule.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
  icon: PropTypes.string,
}

export default CardRule
