import React from 'react'

import PropTypes from 'prop-types'

import './tag.css'

const Tag = (props) => {
  return (
    <div className={`tag-container1 ${props.rootClassName} `}>
      <div className="tag-container2">
        <span className="tag-text">{props.text}</span>
      </div>
    </div>
  )
}

Tag.defaultProps = {
  text: 'blueprint',
  rootClassName: '',
}

Tag.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Tag
