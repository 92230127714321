import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Tag from '../../components/tag'
import Footer from '../../components/footer'
import './blog-gatewayfm-and-near-foundation-team-up.css'

const BlogGatewayfmAndNEARFoundationTeamUp = (props) => {
  return (
    <div className="blog-gatewayfm-and-near-foundation-team-up-container1">
      <Helmet>
        <title>Gateway.fm and NEAR Foundation Team Up</title>
        <meta
          name="description"
          content="Read how Gateway.fm and NEAR Foundation are teaming up to drive innovation and enhance the blockchain ecosystem. Discover the impact of this powerful collaboration."
        />
        <meta
          property="og:title"
          content="Gateway.fm and NEAR Foundation Team Up"
        />
        <meta
          property="og:description"
          content="Read how Gateway.fm and NEAR Foundation are teaming up to drive innovation and enhance the blockchain ecosystem. Discover the impact of this powerful collaboration."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c38fce76-dcb8-4735-bb0a-e2812bd3a0c5/69450e58-787e-438a-beaa-96afdbb55e37?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name33"></Navigation>
      <div className="blog-gatewayfm-and-near-foundation-team-up-container2">
        <div className="blog-gatewayfm-and-near-foundation-team-up-post">
          <img
            alt="Gateway.fm and NEAR."
            src="/external/blog-banners/gateway-near-partnership-1200w.webp"
            className="blog-gatewayfm-and-near-foundation-team-up-image"
          />
          <div className="blog-gatewayfm-and-near-foundation-team-up-text10">
            <div className="blog-gatewayfm-and-near-foundation-team-up-container3">
              <div className="blog-gatewayfm-and-near-foundation-team-up-tags">
                <Tag text="latest"></Tag>
                <Tag text="guide"></Tag>
              </div>
              <span className="blog-gatewayfm-and-near-foundation-team-up-text11">
                Gateway and NEAR Foundation Team Up for Next-Gen Data
                Availability
              </span>
              <span className="blog-gatewayfm-and-near-foundation-team-up-text12">
                7 of March 2024
              </span>
              <span className="blog-gatewayfm-and-near-foundation-team-up-text13">
                <span>
                  Released at NEARCON ‘23, NEAR Data Availability
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://near.org/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blog-gatewayfm-and-near-foundation-team-up-link1"
                >
                  (NEAR DA)
                </a>
                <span>
                  {' '}
                  is an important advancement initiated by the NEAR Foundation.
                  That is strategically designed to address a critical challenge
                  within blockchain development. It’s specifically tailored for
                  Ethereum (ETH) rollups and developers within the Ethereum
                  ecosystem, NEAR DA emerges as a robust solution for ensuring
                  secure and cost-effective data availability.
                </span>
                <br></br>
                <br></br>
                <span>
                  Data costs on the Ethereum L1 have presented significant cost
                  barriers, impeding the scalability and accessibility of
                  blockchain applications. NEAR DA aims to dismantle these
                  obstacles by introducing a highly efficient and affordable
                  alternative for data storage and access.
                </span>
                <br></br>
                <br></br>
                <span>
                  NEAR DA leverages the robust infrastructure of the NEAR
                  blockchain to offer a solution by harnessing the Nightshade
                  consensus mechanism. In that way NEAR DA optimizes data
                  storage and retrieval, ensuring both integrity and
                  availability.
                </span>
                <br></br>
                <br></br>
                <span>
                  For us at Gateway.fm, NEAR DA presents a promising avenue for
                  enhancing Validium, offering an option that not only improves
                  the efficiency and affordability of Validium but also elevates
                  its security. By combining Validium with NEAR DA, we
                  anticipate significant cost reductions and performance
                  enhancements, surpassing the capabilities of rollup solutions
                  in isolation.
                </span>
                <br></br>
                <br></br>
                <span>
                  NEAR DA will be integrated with Presto
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://presto.gateway.fm/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blog-gatewayfm-and-near-foundation-team-up-link2"
                >
                  (presto.gateway.fm)
                </a>
                <span>
                  {' '}
                  and the integration empowers developers with a solution that
                  strikes a balance between cost-effectiveness, speed, and
                  security, making it an attractive choice for projects seeking
                  to optimize their blockchain infrastructure.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>Key Features and Benefits</span>
                <br></br>
                <br></br>
                <span>
                  Cost-efficiency: NEAR DA significantly reduces data
                  availability costs compared to Ethereum Layer 1. This
                  substantial cost reduction democratizes access to blockchain
                  data, making it accessible to developers of all sizes
                </span>
                <br></br>
                <br></br>
                <span>
                  Reliability: With NEAR DA, developers can place their trust in
                  a secure and dependable data storage infrastructure.
                  Leveraging the NEAR blockchain&apos;s consensus mechanisms,
                  NEAR DA guarantees the reliability of stored data, fostering
                  confidence among developers and users alike due to NEAR&apos;s
                  3+ years of 100% uptime.
                </span>
                <br></br>
                <br></br>
                <span>
                  Scalability: NEAR DA seamlessly integrates with Ethereum
                  rollups and Layer 2 solutions, offering a scalable
                  architecture that adapts to the evolving needs of blockchain
                  applications. This scalability ensures that developers can
                  expand their projects without compromising on data
                  availability or security.
                </span>
                <br></br>
                <br></br>
                <span>
                  Embracing Collaboration: NEAR DA is not just a standalone
                  solution but an integral part of the NEAR Open Web Stack. This
                  integration fosters collaboration and innovation, allowing
                  developers and founders to leverage NEAR&apos;s technology for
                  data availability while continuing to build on Ethereum.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <span>Getting Started with NEAR DA</span>
                <br></br>
                <br></br>
                <span>
                  Developers interested in exploring NEAR DA can begin their
                  journey by visiting nearmodular.com Here, they will find
                  comprehensive documentation and resources to facilitate
                  seamless integration into their projects
                </span>
                <br></br>
                <br></br>
                <span>
                  We at Gateway are glad to embark on this journey to reshape
                  the future of data availability in blockchain technology with
                  NEAR DA.
                </span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name32"></Footer>
    </div>
  )
}

export default BlogGatewayfmAndNEARFoundationTeamUp
