import React from 'react'

import PropTypes from 'prop-types'

import './content-highlight.css'

const ContentHighlight = (props) => {
  return (
    <div className="content-highlight-container">
      <div className="content-highlight-content">
        <span className="content-highlight-text1">{props.header}</span>
        <span className="content-highlight-text2">{props.text}</span>
      </div>
    </div>
  )
}

ContentHighlight.defaultProps = {
  header: 'ISSUE',
  text: 'Traditional governance structures often concentrate decision-making power, hindering wide community involvement and transparency.',
}

ContentHighlight.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
}

export default ContentHighlight
